import React, { useState } from "react";
import close from "../../../../src/static/ic-close.svg";
import { withTranslation, Trans } from "react-i18next";

import Slide from "@material-ui/core/Slide";
import { connect } from "react-redux";
import { get_LeftRight } from "../../../helper/UserData/userdate";
import { Api_Get_AcountInformation_AGW } from "../../../helper/api/ApiGateway";
import { ReactComponent as ChangePassword } from "../../../../src/static/Change-Password.svg";
import icongo from "../../../../src/static/icon-chevron-down.svg";
import { Bussiness_change_password } from "../../../helper/api";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      currentPassword: "",
      newpassword: "",
      Retypenewpassword: "",
    };
    this.saveChangePassword = this.saveChangePassword.bind(this)
  }

  handleShowForm() {
    this.setState({
      showForm: true,
    });
  }

  openOrderHistory = () => {
    this.setState({
      showForm: false,
    });
  };

  inputOnchange(key, val) {
    this.setState({
      [key]: val,
    });
  }

  saveChangePassword() {
    Bussiness_change_password(
      this.props.dispatch,
      {
        oldPassword: this.state.currentPassword,
        password: this.state.newpassword,
        confirmPassword: this.state.Retypenewpassword,
      },
      (call) => {

        if (call) {
          this.setState({
            showForm: false,
            currentPassword: "",
            newpassword: "",
            Retypenewpassword: "",
          });
        }
      }
    );
  }

  render() {
    let canSave =
      String(this.state.currentPassword).length > 2 &&
      String(this.state.newpassword) == String(this.state.Retypenewpassword) &&
      String(this.state.newpassword).length > 5;
    return (
      <>
        <div
          className={`PhoneNumberContainer ${this.state.numberNotRegistered && "PhoneNumberContainerError"
            }`}
          style={{ height: "32px", borderRadius: "8px", marginTop: "22px" }}
          onClick={() => this.handleShowForm()}
        >
          <span
            onClick={this.handleOpen}
            className={`langContainer`}
            style={{ paddingTop: "8px" }}
          >
            <div className="SignOutContainer">
              <ChangePassword className="SignOut ChangePasswordicon" />
              <span className="LogoutText">
                {" "}
                <Trans i18nKey={"changePassword"}> </Trans>
              </span>
              <img src={icongo} className="imggo" />
            </div>
          </span>
        </div>

        <Slide
          timeout={650}
          direction={get_LeftRight()}
          in={this.state.showForm}
          mountOnEnter
          unmountOnExit
        >
          <div className="OrderHistoryContainer">
            <div className="OrderListHeader">
              <img
                onClick={this.openOrderHistory}
                src={close}
                className="OrderListHeaderClose"
              />
              <span className="OrderListHeaderTitle">
                <Trans i18nKey={"changePassword"}></Trans>
              </span>
            </div>

            <div className="SettingItemsContainer">
              <p
                style={{
                  textAlign: "left",
                  margin: "0",
                  float: "left",
                  fontSize: "15px",
                  fontWeight: "200",
                }}
              >
                <Trans i18nKey={"changePasswordp1"}></Trans>
              </p>

              <p
                style={{
                  textAlign: "left",
                  margin: "0",
                  float: "left",
                  fontSize: "15px",
                  fontWeight: "200",
                  marginTop: "10px",
                }}
              >
                <Trans i18nKey={"changePasswordp2"}></Trans>
              </p>

              <div
                className="firstnamelastnameContainer emailcontainer"
                style={{ height: "60px", marginTop: "25px" }}
              >
                <p className="LP_PhoneNumberLabel">
                  <Trans i18nKey={"currentPassword"}> </Trans>{" "}
                </p>
                <div
                  className={`PhoneNumberContainer `}
                  style={{ height: "32px", borderRadius: "8px" }}
                >
                  <input
                    placeholder={""}
                    className="emailinputregister"
                    value={this.state.currentPassword}
                    onChange={(e) => {
                      this.inputOnchange("currentPassword", e.target.value);
                    }}
                    style={{
                      marginTop: "5px",
                      fontSize: "12px",
                      fontWeight: "300",
                    }}
                  />
                </div>
              </div>

              <div
                className="firstnamelastnameContainer emailcontainer"
                style={{ height: "60px", marginTop: "10px" }}
              >
                <p className="LP_PhoneNumberLabel">
                  <Trans i18nKey={"newpassword"}> </Trans>{" "}
                </p>
                <div
                  className={`PhoneNumberContainer `}
                  style={{ height: "32px", borderRadius: "8px" }}
                >
                  <input
                    type="password"
                    placeholder={""}
                    className="emailinputregister"
                    value={this.state.newpassword}
                    onChange={(e) => {
                      this.inputOnchange("newpassword", e.target.value);
                    }}
                    style={{
                      marginTop: "5px",
                      fontSize: "12px",
                      fontWeight: "300",
                    }}
                  />
                </div>
              </div>

              <div
                className="firstnamelastnameContainer emailcontainer"
                style={{ height: "60px", marginTop: "10px" }}
              >
                <p className="LP_PhoneNumberLabel">
                  <Trans i18nKey={"Retypenewpassword"}> </Trans>{" "}
                </p>
                <div
                  className={`PhoneNumberContainer `}
                  style={{ height: "32px", borderRadius: "8px" }}
                >
                  <input
                    placeholder={""}
                    className="emailinputregister"
                    value={this.state.Retypenewpassword}
                    onChange={(e) => {
                      this.inputOnchange("Retypenewpassword", e.target.value);
                    }}
                    type="password"
                    style={{
                      marginTop: "5px",
                      fontSize: "12px",
                      fontWeight: "300",
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="SettingFooter">
              <span
                style={{
                  backgroundImage: canSave && `linear-gradient(to top,${global.config.colors.secondaryColor}, ${global.config.colors.secondaryLightColor})`,
                  color: canSave && global.config.colors.secondaryTextColor
                }}
                className={`SettingFooterButton ${!canSave && "DeactiveSettingFooterButton"
                  }`}
                onClick={canSave && this.saveChangePassword}
              >
                <Trans i18nKey={"Save"}></Trans>
              </span>
            </div>
          </div>
        </Slide>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  open: state.Settings.SettingsOpenDialoge,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
