import React from "react";
import { connect } from "react-redux";
import originIcon from "../../../../static/pin-pickup.svg";
import {ReactComponent as Pin} from "../../../../static/pin-favorite.svg";
// import { Warning_NOi18 } from "../../../../helper/Notification/sendNotification";
import { withTranslation, Trans } from "react-i18next";
import {
  ChooseoOnMapimg,
  OnChangeNewAddressBookInNewOrderDetails,
  SetNewAddressBookInNewOrderDetails,
  Set_DropOff_Inputs,
  Set_Pickup_address,
} from "../../../../Redux/actions/actionNames";
// import { GetLocationPlacenameAction } from "../../../../Redux/actions/actions";
import {ReactComponent as Iconorigin} from "../../../../static/icon-ic-pickup.svg";
import { getCenterMap } from "../../../../helper/module";
import PinFavoriteSvg from "../../../../components/svg/pinFavoriteSvg";
import IconPickupSvg from "../../../../components/svg/iconPickupSvg";

class App extends React.Component {


  markerOnClick = () => {
    let latlng =this.props.mapRef.current ? getCenterMap(this.props.mapRef , this.props.center) : null;

    if (!this.props.geocodingReverseـloading) {

      this.props.dispatch(
        OnChangeNewAddressBookInNewOrderDetails({ key:"address", value:this.props.geocodingReverse_address })
      );
      this.props.dispatch(
        SetNewAddressBookInNewOrderDetails({
          adresss_loc: {
            lat :latlng.lat ,
            lng: latlng.lng
          },
          forceLoading:true
        })
      );
      this.props.dispatch(
        ChooseoOnMapimg({
          showMap: false,
          pickupDropOff: {
            type: null,
            id: null,
          },
        })
      );
      
    }
  };


  render() {
    let pinShow =
      (this.props.openMenu || this.props.openMenuOrder) && this.props.adresss_loc == null;

      if(!pinShow){
        return null
      }
    return (
      <div>
        <PinFavoriteSvg onClick={this.markerOnClick}
                    className="originShow addressbookSetPin" />

          <div className="bottomSheetSetPick_Drop">
          <IconPickupSvg className="img x896364d"/>

            {this.props.geocodingReverseـloading ? (
              <span className="bottomSheetSetPick_DropText">
                {" "}
                <Trans i18nKey="fetchingdata"> </Trans>{" "}
              </span>
            ) : (
              <span className="bottomSheetSetPick_DropText">
                {" "}
                {this.props.geocodingReverse_address}{" "}
              </span>
            )}
            <span
              className={`bottomSheetSetPick_DropButton ${this.props.geocodingReverseـloading && "grey"}`}
              onClick={this.markerOnClick}
              style={{
                backgroundImage: !this.props.geocodingReverseـloading && `linear-gradient(to top, ${global.config.colors.secondaryColor}, ${global.config.colors.secondaryLightColor})` ,
                color: !this.props.geocodingReverseـloading &&  global.config.colors.secondaryTextColor
              }}
            >
              {" "}
              <Trans i18nKey={`SetPickup`}>
                {" "}
              </Trans>{" "}
            </span>
          </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  openMenu: state.Addressbook.openAddAddressBookInMenu ,
  openMenuOrder: state.Addressbook.openNewAddressBookInNewOrder ,
  adresss_loc: state.Addressbook.NewAddressBookInNewOrderDetails.adresss_loc ,
  mapRef: state.Map.mapRef,
  geocodingReverseـloading: state.Map.geocodingReverse.loading,
  geocodingReverse_placeId: state.Map.geocodingReverse.placeId,
  geocodingReverse_address: state.Map.geocodingReverse.address,
  geocodingReverse_coordinates: state.Map.geocodingReverse.coordinates,
  center: state.Map.center,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
