


export const GetNextStatusListItem = (Status, type) => {
    if(status[type][Status]){
        return status[type][Status]
    }else{
        return null
    }
};

const status = {
    Ondemand: {
        "Pending": "AssignedAt",
        "Assigned": "StartedAt",
        "Started": "PickedUpAt",
        "Arrived": "PickedUpAt",
        "PickedUp": "DoneAt"
    },
    Pickup: {
        "Pending": "AssignedAt",
        "Assigned": "StartedAt",
        "Started": "PickedUpAt",
        "PickedUp": "DoneAt"
    },
    Delivery: {
        "Pending": "AssignedAt",
        "Assigned": "StartedAt",
        "Started": "DoneAt"
    },

}
