import * as React from "react"
const LanguageSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <title>{"6A9BCE8D-BD60-4AC3-B144-3D01846BEF14"}</title>
    <defs>
    <linearGradient id="a" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconLightEnd} />
        <stop offset="100%" stopColor={global.config.colors.iconLightStart} />
      </linearGradient>
      <linearGradient id="b" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconDarkEnd} />
        <stop offset="100%" stopColor={global.config.colors.iconDarkStart}/>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="nonzero">
      <path
        fill="url(#a)"
        d="M17.845 8.159h-7.531a2.157 2.157 0 0 0-2.155 2.155v4.092l-1.951 1.393a.586.586 0 0 0 0 .954l1.975 1.41A2.158 2.158 0 0 0 10.313 20h7.532A2.157 2.157 0 0 0 20 17.845v-7.531a2.157 2.157 0 0 0-2.155-2.155Zm-2.532 8.233c-.138 0-.245-.044-.276-.163l-.239-.835h-1.437l-.239.835c-.031.12-.138.163-.276.163-.22 0-.514-.138-.514-.339 0-.012.006-.037.012-.062l1.211-3.948c.057-.188.29-.276.521-.276.239 0 .471.088.528.276l1.211 3.948c.006.025.012.044.012.062 0 .195-.294.34-.514.34Z"
      />
      <path fill="#148BE9" d="M13.53 14.754h1.092l-.546-1.927z" opacity={0.3} />
      <path
        fill="url(#b)"
        d="M6.987 10.314c0-.787.275-1.51.733-2.08-.668 0-1.288-.21-1.8-.565a3.143 3.143 0 0 1-1.8.564.33.33 0 0 1 0-.66c.468 0 .907-.13 1.282-.355a3.152 3.152 0 0 1-.826-1.817H4.12a.33.33 0 1 1 0-.66h1.47v-.803a.33.33 0 1 1 .66 0v.802h1.47a.33.33 0 0 1 0 .661h-.455a3.152 3.152 0 0 1-.826 1.817c.375.225.814.355 1.282.355a.33.33 0 0 1 .33.306 3.314 3.314 0 0 1 2.263-.892h1.527V5.594l1.951-1.393a.586.586 0 0 0 0-.954l-1.974-1.41A2.159 2.159 0 0 0 9.686 0H2.155A2.157 2.157 0 0 0 0 2.155v7.531c0 1.188.967 2.155 2.155 2.155h4.832v-1.527Z"
      />
      <path
        fill="#148BE9"
        d="M5.92 6.805c.362-.375.608-.862.68-1.404H5.241c.071.542.317 1.03.678 1.404Z"
      />
    </g>
  </svg>
)
export default LanguageSvg
