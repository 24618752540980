import React from "react";
import { Map, Marker, TileLayer, Popup, Polyline } from "react-leaflet";
import L from "leaflet";
import { connect } from "react-redux";
import originIcon from "../../../../static/pin_pickup.svg";
import { ReactComponent as OriginIconSvg } from "../../../../static/pin_pickup.svg";
import destinationIcon from "../../../../static/pin_dropoff.svg";
import { ReactComponent as DestinationIconSvg } from "../../../../static/pin_dropoff.svg";
import { renderToStaticMarkup } from 'react-dom/server'
import PinPickup from "../../../../components/svg/pin_pickup";
import PinDropOff from "../../../../components/svg/pin_dropoff";
import ReactDOMServer from "react-dom/server";
import PickupPin from "../../../../components/shared/map-pickup-pin/Pin";
import DropOffPin from "../../../../components/shared/map-dropoff-pin/Pin";
import DropOffPinWithNumber from "../../../../components/shared/map-dropoff-pin-with-number/Pin";

const createtIconPickup = (color) => {
  return new L.divIcon({
    className: "",
    html: ReactDOMServer.renderToString(<PickupPin color={"#" + color} bigSize={true}  style={{marginLeft:"-31px",marginTop:"-13px"}}/>),
    iconSize: [54, 57],
    iconAnchor: [10, 35],
  });
};

const createtIconDropOff = (color) => {
  return new L.divIcon({
    className: "",
    html: ReactDOMServer.renderToString(<DropOffPin color={"#" + color} bigSize={true} style={{marginLeft:"-32px",marginTop:"-15px"}}/>),
    iconSize: [54, 57],
    iconAnchor: [10, 35],
  });
};


const createtIconNumberDropoff = (number, numberStyle, color) => {
  return new L.divIcon({
    className: "",
    html: ReactDOMServer.renderToString(CreateIconSelectedOsm( number, numberStyle, color)),
    iconSize: [54, 57],
    iconAnchor: [10, 35],
  });
};


const CreateIconSelectedOsm = ( number, numberStyle, color) => {
  return <DropOffPinWithNumber color={color} bigSize={true}
  style={{marginLeft:"-5px",marginTop:"-16px"}} number={number} numberStyle={numberStyle} />

};


const createtIcon = (icon) => {
  return new L.Icon({
    iconUrl: icon,
    iconAnchor: [20.5, 60],
    popupAnchor: [0, -35],
    iconSize: [65, 65],
    shadowSize: [29, 40],
    shadowAnchor: [7, 40],
  });
};


const createtOriginIconDiv = () => {
  return new L.divIcon({
    className: "markerIcon ",
    html: `${renderToStaticMarkup( <PinPickup />)}`,
    iconAnchor: [20.5, 60],
    popupAnchor: [0, -35],
    iconSize: [65, 65],
    shadowSize: [29, 40],
    shadowAnchor: [7, 40],
  });
};



const createtDestinationIconDiv = () => {
  return new L.divIcon({
    className: "markerIcon ",
    html: `${renderToStaticMarkup( <PinDropOff />)}`,
    iconAnchor: [20.5, 60],
    popupAnchor: [0, -35],
    iconSize: [65, 65],
    shadowSize: [29, 40],
    shadowAnchor: [7, 40],
  });
};



class App extends React.Component {

  render() {

    let originShow = this.props.OpenVRDialoge && this.props.VRpureData && ["Pickup", "PickupDelivery", "Ondemand"].indexOf(this.props.orderType) > -1;
    let destinationShow = this.props.OpenVRDialoge && this.props.VRpureData && this.props.VRpureData.dropoffs;
    let destination2Show = this.props.OpenVRDialoge && !this.props.OpenERDialoge && this.props.VRpureData && this.props.VRpureData.dropoff;

    return (
      <React.Fragment>
        {/* {originShow ? (
            <Marker
              animate={true}
              position={{lat : this.props.VRpureData.pickup.coordinates[1] , lng:this.props.VRpureData.pickup.coordinates[0]}}
              icon={createtIcon(originIcon)}
            ></Marker>
          ) : null} */}

        {originShow ? (
               <Marker
                animate={true}
                position={{ lat: this.props.VRpureData.pickup.coordinates[1], lng: this.props.VRpureData.pickup.coordinates[0] }}
                icon={createtIconPickup("1795ff")}
             ></Marker>
          // <Marker
          //   animate={true}
          //   position={{ lat: this.props.VRpureData.pickup.coordinates[1], lng: this.props.VRpureData.pickup.coordinates[0] }}
          //   icon={createtOriginIconDiv()}
          // ></Marker>
        ) : null}

        {/* {destination2Show &&
          <Marker
            animate={true}
            position={{ lat: this.props.VRpureData.dropoff.coordinates[1], lng: this.props.VRpureData.dropoff.coordinates[0] }}
            icon={createtIcon(destinationIcon)}
          ></Marker>
        } */}

        {destination2Show &&
               (this.props.VRpureData.dropoffs.length == 0 ?
               <Marker
                 animate={true}
                 position={{ lat: this.props.VRpureData.dropoff.coordinates[1], lng: this.props.VRpureData.dropoff.coordinates[0] }}
                 icon={createtIconDropOff("1795ff")}
               ></Marker>
               :
               <Marker
                 animate={true}
                 position={{ lat: this.props.VRpureData.dropoff.coordinates[1], lng: this.props.VRpureData.dropoff.coordinates[0] }}
                 icon={createtIconNumberDropoff(1, { position: "absolute", width: "20px", height: "20px", left: "5px", top: "5px", fontSize: "14px", fontWeight: "bold", fontFamily: 'sf_pro', textAlign: "center" } , "#1795ff")}
               >
   
               </Marker>)
          // <Marker
          //   animate={true}
          //   position={{ lat: this.props.VRpureData.dropoff.coordinates[1], lng: this.props.VRpureData.dropoff.coordinates[0] }}
          //   icon={createtDestinationIconDiv()}
          // ></Marker>
        }

        {/* {destinationShow
          ? this.props.VRpureData.dropoffs.map((dropoff, i) => {
            return (
              <Marker
                animate={true}
                position={{ lat: dropoff.coordinates[1], lng: dropoff.coordinates[0] }}
                icon={createtIcon(destinationIcon)}
              ></Marker>
            );
          })
          : null} */}

      {destinationShow
          ? this.props.VRpureData.dropoffs.map((dropoff, i) => {
            return (
              <Marker
              animate={true}
              position={{ lat: dropoff.coordinates[1], lng: dropoff.coordinates[0] }}
              icon={createtIconNumberDropoff(i + 1, { position: "absolute", width: "20px", height: "20px", left: "5px", top: "5px", fontSize: "14px", fontWeight: "bold", fontFamily: 'sf_pro', textAlign: "center" } , "#1795ff")}
            >
  
            </Marker>
              // <Marker
              //   animate={true}
              //   position={{ lat: dropoff.coordinates[1], lng: dropoff.coordinates[0] }}
              //   icon={createtDestinationIconDiv()}
              // ></Marker>
            );
          })
          : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  OpenVRDialoge: state.ViewTravel.OpenDialoge,
  VRpureData: state.ViewTravel.pureData,
  orderType: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.type
      ? state.ViewTravel.pureData.type
      : null
    : null,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
