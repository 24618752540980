import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";


class App extends Component {
    render() {
        return (
            <pickupropoffsContainer style={{minHeight :"auto"}}>
                <pinsContainer style={{ borderLeft: "0px",height:"auto" }}>
                    <circleBlue style={{
              border: `2px solid ${global.config.colors.iconLightEnd}` ,
              backgroundColor : global.config.colors.iconDarkEnd
          }}/>
                </pinsContainer>

                <addresss>
                    <pickupAddress>{this.props.pickup?.address}</pickupAddress>
                </addresss>
            </pickupropoffsContainer>
        );
    }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
    dispatch: dispatch,
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(App));
