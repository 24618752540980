import * as React from "react"
const WalletSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={24}
    height={24}
    {...props}
  >
    <defs>
      <linearGradient id="bWalletSvg" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconLightEnd} />
        <stop offset="100%" stopColor={global.config.colors.iconLightStart} />
      </linearGradient>
      <linearGradient id="fWalletSvg" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconDarkEnd} />
        <stop offset="100%" stopColor={global.config.colors.iconDarkStart} />
      </linearGradient>
      <circle id="aWalletSvg" cx={12} cy={12} r={12} />
      <path
        id="dWalletSvg"
        d="M8.708 0c2.068 0 3.29 1.187 3.29 3.203v.022h-2.54c-1.183.002-2.141.936-2.143 2.089-.002 1.155.957 2.093 2.142 2.095H12v.184c0 2.015-1.222 3.207-3.29 3.207H3.29C1.222 10.8 0 9.608 0 7.593v-4.39C0 1.187 1.222 0 3.29 0h5.418zm2.861 4.14c.212 0 .387.156.424.362L12 4.58V6.1a.438.438 0 0 1-.353.433l-.078.007H9.62a1.212 1.212 0 0 1-1.197-.962 1.284 1.284 0 0 1-.022-.232 1.195 1.195 0 0 1 1.053-1.2l.12-.006h1.996zm-1.969.6a.6.6 0 1 0 .001 1.201A.6.6 0 0 0 9.6 4.74zM6.23 2.325H2.844a.453.453 0 0 0-.457.446.454.454 0 0 0 .457.45h3.39a.455.455 0 0 0 .457-.45.454.454 0 0 0-.461-.446z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g style={{transform:"scale(.9)" , backgroundColor:"red"}}>
        <mask id="cWalletSvg" fill="#fff">
          <use xlinkHref="#aWalletSvg" />
        </mask>
        <g fill="url(#bWalletSvg)" mask="url(#cWalletSvg)">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
      <g transform="translate(6 6.857)">
        <mask id="eWalletSvg" fill="#fff">
          <use xlinkHref="#dWalletSvg" />
        </mask>
        <g mask="url(#eWalletSvg)">
          <path
            fill="url(#fWalletSvg)"
            d="M0 0h12.857v12H0z"
            transform="translate(-.204 -.262)"
          />
        </g>
      </g>
    </g>
  </svg>
)
export default WalletSvg
