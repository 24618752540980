import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import arrow from "../../../../static/arrow-triangle-down.svg";
import {ReactComponent as Balance} from "../../../../static/mutual-wallet-balance.svg";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { withTranslation, Trans } from "react-i18next";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { New_Travel_PaymentMethod } from "../../../../Redux/actions/actionNames";
import {ReactComponent as Plus} from "../../../../static/plus1.svg";
import {
  get_customerPannel_type,
  get_Id,
  get_lang,
  get_minimumBalance,
  Get_Permisssion,
} from "../../../../helper/UserData/userdate";
import {ReactComponent as SenderWalletic} from "../../../../static/customer-ic-wallet.svg";
import {ReactComponent as SenderCashic} from "../../../../static/ic-cash.svg";
import {ReactComponent as ReceiverCashic} from "../../../../static/customer-ic-by-receiver.svg";
import customericbalancecard from "../../../../static/customer-ic-balance-card.svg";
import Dialog from "@material-ui/core/Dialog";
import {
  Api_change_payment_method_AGW,
  Api_get_Order_Info_AGW,
  Api_Get_Payment_Methods_AGW,
  Api_New_Payment_Order_AGW,
} from "../../../../helper/api/ApiGateway";
import { isOrderInProgress } from "../../../../helper/module";
import Wallet from "../../../Main/Menu/wallet";
import Stripe_Add_Card from "./changeMethod/stripe";
import PaymentIcon from "react-payment-icons-inline";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cards: [],
      changeMethodLoading: false,

      showpoperMethod: false,
      showpoperSide: false,
      Inc_Balance: false,
      amountEntered: "",
      selectedGateWay: null,
      paymentSide: [
        {
          i18: "SenderWallet",
          data: {
            paymentSide: "Sender",
            paymentMethod: "Wallet",
            paymentProvider: null,
            cardInfo: null,
            paymentMethodId: null,
          },
        },
        {
          i18: "SenderCash",
          data: {
            paymentSide: "Sender",
            paymentMethod: "Cash",
            paymentProvider: null,
            cardInfo: null,
            paymentMethodId: null,
          },
        },
        {
          i18: "ReceiverCash",
          data: {
            paymentSide: "Receiver",
            paymentMethod: "Cash",
            paymentProvider: null,
            cardInfo: null,
            paymentMethodId: null,
          },
        },
      ],
    };
    this.paymentSide = React.createRef();
    this.getCards = this.getCards.bind(this)
    this.getSenderWalletText = this.getSenderWalletText.bind(this)

  }


  getSenderWalletText = (i18) => {
    if(get_customerPannel_type() == "business" && this.props.isInvoiceActive){
      return this.props.t("account")
    }else{
      return this.props.t(i18)
    }
  };



  bussinessisInvoiceActive = () => {
    if(get_customerPannel_type() == "business" && this.props.isInvoiceActive){
      return true
    }else{
      return false
    }
  };




  getImageOfPaymentMeyhod = (name) => {
    if (name == "SenderWallet") {
      return SenderWalletic
    } else if (name == "SenderCash") {
      return SenderCashic
    } else if (name == "ReceiverCash") {
      return ReceiverCashic
    } else {
      return SenderWalletic
    }
  }


  handleshowpoperPaymentSide = (bool) => {
    this.setState({
      showpoperSide: bool,
    });
  };

  handleAmountEntered = (e, number) => {
    this.setState({
      amountEntered: number ? number : e.target.value,
    });
  };

  handleShowIncBalance = (bool) => {
    this.setState({
      Inc_Balance: bool,
      amountEntered: "",
    });
  };

  handleSelectedItem = (item) => {
    if(!this.state.changeMethodLoading){
      this.setState({changeMethodLoading : true}, ()=>{
               // Api_change_payment_method_AGW
    // this.props.dispatch(New_Travel_PaymentMethod(item.data));
    if (
      (["green", "grey"].indexOf(
        this.WalletCheck(item.data.paymentSide, item.data.paymentMethod)
      ) > -1) &&
      ((this.props.paymentMethod != item.data.paymentMethod) ||
        (this.props.paymentSide != item.data.paymentSide))
    ) {

      Api_change_payment_method_AGW(
        this.props.dispatch,
        {
          orderId: this.props.ID,
          paymentMethod: item.data.paymentMethod,
          paymentSide: item.data.paymentSide,
          type: this.props.type,
          // "paymentMethodId": null,
          // "paymentProvider": null,
          // "cardInfo": null,
        },
        () => {
          Api_get_Order_Info_AGW(this.props.dispatch, this.props.ID)

          this.setState({changeMethodLoading :false})
        }
      );
    }
      })
  
    }
 
  };

  handleSelectedItemStripe = (item) => {
    if(!this.state.changeMethodLoading){
      this.setState({changeMethodLoading : true}, ()=>{ 
  Api_change_payment_method_AGW(
      this.props.dispatch,
      {
        orderId: this.props.ID,
        paymentSide: "Sender",
        paymentMethod: "Card",
        paymentProvider: "stripe",
        cardInfo: item,
        paymentMethodId: item.id,
        type: this.props.type,
        // "paymentMethodId": null,
        // "paymentProvider": null,
        // "cardInfo": null,
      },
      () => {
        Api_get_Order_Info_AGW(this.props.dispatch, this.props.ID)
        setTimeout(() => {
          this.setState({changeMethodLoading : false})
        }, 150);
      }
    );

      })
  }}



  getCards() {
    Api_Get_Payment_Methods_AGW(this.props.dispatch, this.setCards)
  }


  setCards = (cards) => {
    this.setState({
      cards
    })
  }


  WalletCheck = (paymentSide, paymentMethod) => {

    console.log("WalletCheck ",paymentSide, paymentMethod , this.props.price , this.WalletBalance(this.props),(paymentSide || this.props.paymentSide) +
    (this.props.paymentMethod || paymentMethod))
    if (
      (paymentSide ? paymentSide : this.props.paymentSide) +
      (paymentMethod ? paymentMethod :this.props.paymentMethod) ==
      "SenderWallet"
    ) {
      if (this.WalletBalance(this.props)) {
        return "green";
      } else {
        return "red";
      }
    } else {
      return "grey";
    }
  };


  WalletBalance = (props) => {

    if (props.paymentSide + props.paymentMethod == "SenderWallet") {
      if ((props.balance + props.price + (props.promoAmount ? props.promoAmount : 0)) >= get_minimumBalance()) {
        return true
      } else {
        return Number(props.price - (props.promoAmount ? props.promoAmount : 0)) <=
          Number(props.balance)
      }
    } else {
      return Number(props.price - (props.promoAmount ? props.promoAmount : 0)) <=
        Number(props.balance)
    }

  };

  neededBalance = () => {
    return (Number(
      this.props.price - (this.props.promoAmount ? this.props.promoAmount : 0)
    ) - Number(this.props.balance))
  }

  hanlePay = () => {
    if (this.state.amountEntered.length > 0) {
      Api_New_Payment_Order_AGW(
        this.props.dispatch,
        {
          customerId: get_Id(),
          amount: this.state.amountEntered,
          deviceType: "web",
          packageName: null,
          gateway: this.state.selectedGateWay || this.props.payment[0].gateway,
        }
      );
    }
  };

  removeEmpty = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v != null)
    );
  };


  componentDidMount() {
    if ((global.config.stripePublicApiKey && global.config.stripePublicApiKey.length > 0) && get_Id() && this.state.cards.length == 0) {
      Api_Get_Payment_Methods_AGW(this.props.dispatch, this.setCards)
    }

  }


  componentWillReceiveProps(nextProps) {
    if ((global.config.stripePublicApiKey && global.config.stripePublicApiKey.length > 0) && get_Id() && this.state.cards.length == 0) {
      Api_Get_Payment_Methods_AGW(this.props.dispatch, this.setCards)
    }


  }


  render() {
    const { t } = this.props;

    if (isOrderInProgress(this.props.OrderData.status)) {
      return (
        <React.Fragment>


          <paymentMethodContainerNewOrder
            ref={this.paymentSide}
            id={this.props.id}
            onClick={() => this.handleshowpoperPaymentSide(true)}
            style={{marginTop:"0px" ,backgroundColor:"#8080800A", border:"1px solid #80808033" ,borderRadius:"8px",display:"flex",flexDirection:"row",justifyContent :"center",alignItems:"center",padding:"0px",paddingInlineStart:"9px",paddingInlineEnd:"12px" ,marginInlineEnd:"auto"}}
          >

            <div
              className={
                this.props.OrderData && this.props.OrderData.paymentSide + this.props.OrderData.paymentMethod != "SenderCard" ? (this.props.OrderData.paymentSide === "Sender"
                  ? this.props.OrderData.paymentMethod === "Cash"
                    ? "Cash opdrtmff"
                    : "Balance opdrtmff"
                  : "Reciver opdrtmff") : 'opdrtmff'

              } style={{margin:"0px",display:"flex",flexDirection:"row",justifyContent :"center",alignItems:"center",gap:"8px"}}
            >
              {" "}
              {this.props.OrderData.paymentSide + this.props.OrderData.paymentMethod == "SenderCard" ? (

                <React.Fragment>
                  <PaymentIcon
                    icon={this.props.OrderData.cardInfo.brand}
                    className="dgieh"
                  />


                  <span className="pfkwuhh"> {`**** ${this.props.OrderData.cardInfo.lastNumbers}`} </span>
                  <img className={`fowiaaroow ${this.state.showpoperSide && "rotate180"}`} src={arrow} style={{margin:"0px"}} />

                </React.Fragment>


              ) :
                <React.Fragment>
                  <span className="pfkwuhh">  
                  
                  {this.props.OrderData.paymentSide === "Sender"
                    ? this.props.OrderData.paymentMethod === "Cash"
                      ?  <Trans i18nKey={"Cash"}> </Trans>
                      : this.getSenderWalletText("SenderWallet")
                    :<Trans i18nKey={"Receiver"}> </Trans>}
                  
                    
                    </span>
                  <img className={`fowiaaroow ${this.state.showpoperSide && "rotate180"}`} src={arrow}  style={{margin:"0px"}} />

                </React.Fragment>

              }
            </div>

            {/* <key>
              <Trans i18nKey={"paymentmethod"}> </Trans>
            </key> */}
          </paymentMethodContainerNewOrder>



          {/* <clickableservice
            className={`clickableChangeMethod marginR10  ${this.WalletCheck() == "green"
                ? "SenderWallet"
                : this.WalletCheck() == "red"
                  ? "SenderWalletred"
                  : ""
              }  ${this.WalletCheck() == "red" ? "clickableserviceRed" : ""}
            `}
            style={{
              marginRight: "14px",
            }}
            ref={this.paymentSide}
            id={this.props.id}
            onClick={() => this.handleshowpoperPaymentSide(true)}
          >
            {
              <img
                src={arrow}
                className={`${this.state.showpoperSide && "rotate180"} ${this.WalletCheck() == "red" ? "mrt10" : ""
                  }`}
              />
            }
            <span
              className={`paymentmethodspan ${this.props.paymentSide + this.props.paymentMethod !=
                  "SenderWallet"
                  ? "paymentmethodspanoedited"
                  : this.WalletCheck() != "red"
                    ? "paymentmethodspanoedited"
                    : ""
                }`}
            >
              {" "}
              <Trans
                i18nKey={this.props.paymentSide + this.props.paymentMethod}
              >
                {" "}
              </Trans>
            </span>
            {this.props.paymentSide + this.props.paymentMethod ==
              "SenderWallet" ? (
              this.WalletCheck() == "red" ? (
                <span className="lowBalance">
                  {" "}
                  <Trans i18nKey={"lowBalance"}></Trans>
                </span>
              ) : null
            ) : null}
          </clickableservice> */}

          <Popper
            open={this.state.showpoperSide}
            anchorEl={this.paymentSide.current}
            role={undefined}
            placement={"bottom"}
            style={{
              zIndex: 7,
              marginTop: "4px",
              border: "solid 1px rgba(128, 128, 128, 0.2)",
              borderRadius: "8px",
              boxShadow: "0 2px 8px 0 rgba(128, 128, 128, 0.24)",
            }}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                timeout={{ enter: 150, exit: 150 }}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper className="paymentMethodsPopper">
                  <ClickAwayListener
                    onClickAway={() => this.handleshowpoperPaymentSide(false)}
                  >
                    <div>
                    { !this.bussinessisInvoiceActive() && <div className="BalanceContainer">
                        <Balance  className="balanceicon" />
                        <Plus className="increaseBalance"
                        onClick={() => this.handleShowIncBalance(true)}/>
                        {/* <img
                          className="increaseBalance"
                          src={plus}
                          onClick={() => this.handleShowIncBalance(true)}
                        /> */}
                        <span className="BalanceText">
                          {" "}
                          <Trans i18nKey={"Balance"}> </Trans>
                        </span>
                        <span className="BalanceNumberText">
                          {" "}
                          {this.props.balancecurrencyCode
                            ? Number(this.props.balance).toLocaleString(
                              get_lang(),
                              {
                                style: "currency",
                                currency: this.props.balancecurrencyCode,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                            : this.props.balance}
                        </span>
                      </div>}

                      <span className="Methods" style={{marginTop:this.bussinessisInvoiceActive() && "0"}}>
                        {" "}
                        <Trans i18nKey={"Methods"}> </Trans>{" "}
                      </span>
                            {console.log("dwdwdw ",this.WalletCheck(this.state.paymentSide[0].data.paymentSide , this.state.paymentSide[0].data.paymentMethod) )}
                      <div
                        style={{ cursor: this.WalletCheck(this.state.paymentSide[0].data.paymentSide , this.state.paymentSide[0].data.paymentMethod) == "red" ? "not-allowed" : "pointer" }}
                        className={`MethodsItem ${this.state.paymentSide[0].i18 ==
                          this.props.paymentSide + this.props.paymentMethod
                          ? "MethodsItemActive"
                          : ""
                          } ${this.WalletCheck(this.state.paymentSide[0].data.paymentSide , this.state.paymentSide[0].data.paymentMethod) == "red" && "redborder"}`}
                        onClick={() =>
                          !this.state.changeMethodLoading && this.handleSelectedItem(this.state.paymentSide[0])
                        }
                      >
                        <SenderWalletic className="img c7921369" />
                        {/* <img src={SenderWalletic} /> */}
                        <span>
                          {" "}
                          {this.getSenderWalletText("SenderWallet")}
                        </span>
                      </div>

                      {this.WalletCheck(this.state.paymentSide[0].data.paymentSide , this.state.paymentSide[0].data.paymentMethod) == "red" ? (
                        <span className="balanceError">
                          {" "}
                          <Trans i18nKey={"balanceError"}> </Trans>{" "}
                        </span>
                      ) : null}

                      {this.props.serviceSettings.isCashBySenderActive && (
                        <div
                          className={`MethodsItem ${this.state.paymentSide[1].i18 ==
                            this.props.paymentSide + this.props.paymentMethod
                            ? "MethodsItemActive"
                            : ""
                            }`}
                          onClick={() =>
                            !this.state.changeMethodLoading &&  this.handleSelectedItem(this.state.paymentSide[1])
                          }

                          style={{cursor : this.state.changeMethodLoading ? "not-allowed" :"pointer"}}
                        >
                          <SenderCashic className="img w9647" />
                          {/* <img src={SenderCashic} /> */}
                          <span>
                            {" "}
                            <Trans i18nKey={"SenderCash"}> </Trans>{" "}
                          </span>
                        </div>
                      )}

                      {this.props.serviceSettings.enableReceiverCash && (
                        <div
                          className={`MethodsItem ${this.state.paymentSide[2].i18 ==
                            this.props.paymentSide + this.props.paymentMethod
                            ? "MethodsItemActive"
                            : ""
                            }`}
                          onClick={() =>
                            !this.state.changeMethodLoading &&  this.handleSelectedItem(this.state.paymentSide[2])
                          }
                          style={{cursor : this.state.changeMethodLoading ? "not-allowed" :"pointer"}}
                        >
                          <ReceiverCashic className="img wr98364" />
                          {/* <img src={ReceiverCashic} /> */}
                          <span>
                            {" "}
                            <Trans i18nKey={"ReceiverCash"}> </Trans>{" "}
                          </span>
                        </div>
                      )}

                      {this.state.cards.map((card) => {
                        return <div
                          className={`MethodsItem ${("SenderCard" ==
                            this.props.paymentSide + this.props.paymentMethod
                          ) && this.props.paymentMethodId == card.id ? "MethodsItemActive"
                            : ""
                            }`}
                            style={{display:"flex",flexDirection:"row" ,justifyContent:"start",alignItems:"center" , cursor : this.state.changeMethodLoading ? "not-allowed" :"pointer"}}
                          onClick={() =>
                            !this.state.changeMethodLoading && this.handleSelectedItemStripe(card)
                          }

                        >
                          <PaymentIcon
                            icon={card.brand}
                            style={{ height: '16px' }}
                          />
                          <span>
                            {`**** ${card.lastNumbers}`}
                          </span>
                        </div>
                      })}

                      {global.config.stripePublicApiKey && <Stripe_Add_Card onClose={this.getCards} />}

                      {/* <MenuList
                      autoFocusItem={this.state.activestop}
                      id="menu-list-grow"
                      onKeyDown={(e) => {
                        this.handleListKeyDownTime(e);
                      }}
                    >
                      {this.state.paymentSide.map((method) => {
                        return (
                          <MenuItem
                            className="serviceListOption"
                            onClick={() => this.handleSelectedItem(method)}
                          >
                            <Trans i18nKey={method.i18}> </Trans>
                          </MenuItem>
                        );
                      })}
                    </MenuList> */}
                    </div>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>



          <Wallet ShowComponent={false} OpenDialoge={this.state.Inc_Balance} prefered={this.WalletCheck() == "red" ? this.neededBalance() : 0} onClose={() => this.handleShowIncBalance(false)} />

        </React.Fragment>
      );
    } else {
      return (
        <paymentMethodContainerNewOrder 
        style={{border:"unset",alignItems:"start",marginInlineStart:"0px",paddingInlineStart:"0px",marginTop:'0px' ,marginInlineEnd:"auto"}}

      >

        <div
          className={
            this.props.OrderData && this.props.OrderData.paymentSide + this.props.OrderData.paymentMethod != "SenderCard" ? (this.props.OrderData.paymentSide === "Sender"
              ? this.props.OrderData.paymentMethod === "Cash"
                ? "Cash opdrtmff"
                : "Balance opdrtmff"
              : "Reciver opdrtmff") : 'opdrtmff'

            } style={{margin:"0px",display:"flex",flexDirection:"row",justifyContent :"center",alignItems:"center",gap:"8px"}}
            >
          {" "}
          {this.props.OrderData.paymentSide + this.props.OrderData.paymentMethod == "SenderCard" ? (

            <React.Fragment>
              <PaymentIcon
                icon={this.props.OrderData.cardInfo.brand}
                className="dgieh"
              />


              <span className="pfkwuhh"> {`**** ${this.props.OrderData.cardInfo.lastNumbers}`} </span>

            </React.Fragment>


          ) :
            <React.Fragment>
              <span className="pfkwuhh">  
                
                {this.props.OrderData.paymentSide === "Sender"
                    ? this.props.OrderData.paymentMethod === "Cash"
                      ?  <Trans i18nKey={"Cash"}> </Trans>
                      : this.getSenderWalletText("SenderWallet")
                    :<Trans i18nKey={"Receiver"}> </Trans>}
                </span>

            </React.Fragment>

          }
        </div>

        <key>
          <Trans i18nKey={"paymentmethod"}> </Trans>
        </key>
      </paymentMethodContainerNewOrder>

      );
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  price: ownProps.OrderData.price,
  promoAmount : ownProps.OrderData.promoAmount,
  paymentMethod: ownProps.OrderData.paymentMethod,
  paymentSide: ownProps.OrderData.paymentSide,
  balance: state.NewOrder.newOrder.balance.balance,
  balancecurrencyCode: state.NewOrder.newOrder.balance.currencyCode,
  serviceSettings: ownProps.OrderData.service.setting,
  defaultsAmount: state.SharedData.WebSettings
    ? state.SharedData.WebSettings.predefinedPaymentValues
    : null,
  paymentMethodId: ownProps.OrderData.paymentMethodId,
  cardInfo: ownProps.OrderData.cardInfo,
  currencyCode: state.SharedData.WebSettings
    ? state.SharedData.WebSettings.currencyCode
    : null,
  isInvoiceActive: state.SharedData.WebSettings ? state.SharedData.WebSettings.isInvoiceActive : null,
  payment: state.SharedData.WebSettings
    ? state.SharedData.WebSettings.payment || []
    : [],
  ID: state.ViewTravel.ID,
  type: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.type
    : null,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
