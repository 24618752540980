import * as React from "react"
const IconIcPinSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} {...props}>
    <defs>
      <linearGradient id="aIconIcPinSvg" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconLightEnd} />
        <stop offset="100%" stopColor={global.config.colors.iconLightStart} />
      </linearGradient>
      <linearGradient id="bIconIcPinSvg" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconDarkEnd} />
        <stop offset="100%" stopColor={global.config.colors.iconDarkStart} />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h14v14H0z" />
      <g transform="translate(.875)">
        <path
          fill="url(#aIconIcPinSvg)"
          d="M.517 5.874a5.376 5.376 0 0 1 10.75.036v.061c-.037 1.93-1.114 3.713-2.435 5.107a14.062 14.062 0 0 1-2.514 2.07.653.653 0 0 1-.852 0A13.876 13.876 0 0 1 1.93 9.837 6.885 6.885 0 0 1 .517 5.892v-.018z"
        />
        <circle cx={5.892} cy={5.977} r={1.723} fill="url(#bIconIcPinSvg)" />
      </g>
    </g>
  </svg>
)
export default IconIcPinSvg
