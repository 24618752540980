import * as React from "react"
const IcMap = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={16} {...props}>
    <defs>
      <linearGradient id="aIcMap" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconLightStart} />
        <stop offset="100%" stopColor={global.config.colors.iconLightEnd} />
      </linearGradient>
      <linearGradient id="bIcMap" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconDarkStart} />
        <stop offset="100%" stopColor={global.config.colors.iconDarkEnd} />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="nonzero">
      <path
        fill="url(#aIcMap)"
        d="M.61 6.544a.97.97 0 0 0-.61.9v7.586c0 .343.346.577.665.45l4.183-1.904V6.513a9.288 9.288 0 0 1-.643-1.407L.61 6.544zm8.117 4.355c-.426 0-.83-.187-1.106-.514A31.678 31.678 0 0 1 5.818 8.06v5.515l5.818 1.94V8.06a32.08 32.08 0 0 1-1.802 2.324c-.277.327-.68.514-1.107.514zm8.063-6.015-4.184 1.904v8.727l4.239-1.695a.97.97 0 0 0 .61-.9V5.333a.485.485 0 0 0-.665-.45z"
      />
      <path
        fill="url(#bIcMap)"
        d="M8.727 0A3.817 3.817 0 0 0 4.91 3.818c0 1.705 2.496 4.812 3.452 5.94.193.229.54.229.733 0 .956-1.128 3.451-4.235 3.451-5.94A3.82 3.82 0 0 0 8.727 0z"
      />
    </g>
  </svg>
)
export default IcMap
