import React from "react";
import clsx from "clsx";
import styles from './style.module.css';
import colors from '../colors/colors.module.css';
import { TagProps } from "./Tag.types";
import IconProvider from "../IconProvider";
import Typography from "../Typography";


const Tag: React.FC<TagProps> = ({weight, backgroundColor,color,size,icon,iconSize,onClick,text,className,style }: TagProps) => {

    return (
        <div onClick={onClick && onClick} className={clsx(styles[size] ,colors[`${backgroundColor}Bg`] , className)} 
        style={style ? style : {}} >

            {icon && <IconProvider Icon={icon} size={iconSize} />}
            {text && <Typography weight={weight} text={text} className={colors[`${color}Text`]} />}

        </div>
    );
};

export default Tag;

