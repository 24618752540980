import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import  { ReactComponent as EPath }  from "../../../../static/es_path.svg";
import ic_cod from "../../../../static/ic_cod.svg";
import { ReactComponent as Time } from "../../../../static/es_time.svg";

import { ReactComponent as Discount } from "../../../../static/customer-ic-discount.svg";
import { ReactComponent as Cod } from "../../../../static/ic-cod.svg";

import { withTranslation, Trans } from "react-i18next";
import { get_currencyCode, get_lang } from "../../../../helper/UserData/userdate";
import ETA from "./ETA";
import styles from './viewOrder.module.css';
import IconProviderSvgModifier from "../../../../design-system/lib/IconProviderSvgModifier";

class App extends React.Component {
  render() {
    return (
      <extraDetailsContainer>

        <ETA />
   { Math.round(this.props.estimatedDuration / 60) > 0 && <div className={styles.extraDetail} 
   style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",gap:"8px"}}>
          <Time/>
          <div className={styles.key} style={{height:"auto",margin:"0px"}}>
            <div className={styles.value} > {String(this.props.t("minxxx")).replace("xxx", Math.round(this.props.estimatedDuration / 60))} </div>
            <Trans i18nKey={"Duration"}> </Trans>
          </div>{" "}
        </div>}
        {Math.round(this.props.Espath / 1000) > 0 && <div className={styles.extraDetail} style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",gap:"8px"}}>
      <IconProviderSvgModifier Icon={EPath} size="16" />
          <div className={styles.key}  style={{height:"auto",margin:"0px"}}>
            <div className={styles.value} >
              {Math.round(this.props.Espath / 1000)} km
            </div>
            <Trans i18nKey={"Distance"}> </Trans>
          </div>
        </div>}

{/* 
        {this.props.Promocode && (
          <div className={styles.extraDetail}>
            <Discount className="img Promocode" />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className={styles.value}> {this.props.Promocode}</div>
              <key>
                <Trans i18nKey={"PromotionCode"}> </Trans>
              </key>{" "}
            </div>
          </div>
        )} */}

        {Number(this.props.Cod) > 0 && <div className={styles.extraDetail}>
          <img src={ic_cod} />
          <div style={{ display: "flex", flexDirection: "column" }}>


            <div className={styles.key}>
              <div className={styles.value}> {Number(this.props.Cod).toLocaleString(get_lang(), {
                style: "currency",
                currency: get_currencyCode(),
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}</div>
              <Trans i18nKey={"COD"}> </Trans>
            </div>
          </div>

        </div>}
      </extraDetailsContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  Promocode: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.promoCode
    : false,
  Espath: state.ViewTravel.pureData
    ? ((state.ViewTravel.pureData.estimatedDistanceInMeters || state.ViewTravel.pureData.estimatedDistance) || 0)
    : 0,
  Cod: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.codAmount
    : 0,
  estimatedDuration: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.estimatedDuration || 0
    : 0,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
