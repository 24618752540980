import * as React from "react"
const SignOut = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <defs>
      <linearGradient id="a" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconLightEnd} />
        <stop offset="100%" stopColor={global.config.colors.iconLightStart} />
      </linearGradient>
      <linearGradient id="b" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconDarkEnd} />
        <stop offset="100%" stopColor={global.config.colors.iconDarkStart}/>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="nonzero">
      <path
        fill="url(#a)"
        d="M0 5.336C0 2.396 2.436 0 5.43 0h5.953C14.37 0 16.8 2.388 16.8 5.324v13.34c0 2.942-2.436 5.336-5.43 5.336H5.417C2.43 24 0 21.612 0 18.676V5.336z"
      />
      <path
        fill="url(#b)"
        d="M23.735 11.346 20.32 7.855a.884.884 0 0 0-1.273.002.943.943 0 0 0 .002 1.307l1.871 1.912H9.058c-.497 0-.9.414-.9.924 0 .511.403.924.9.924H20.92l-1.87 1.911a.942.942 0 0 0-.003 1.307.89.89 0 0 0 .638.273.89.89 0 0 0 .635-.27l3.415-3.49a.938.938 0 0 0 0-1.309"
        transform="rotate(-180 16.08 12)"
      />
    </g>
  </svg>
)
export default SignOut
