import * as React from "react"
const AddressbookListSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="url(#aAddressbookListSvg)"
      fillRule="evenodd"
      d="M4.19 0c-.87 0-1.575.705-1.575 1.575v16.85c0 .87.705 1.575 1.575 1.575h12.235c.87 0 1.575-.705 1.575-1.575V5.712c0-.87-.705-1.575-1.575-1.575h-.348V1.575c0-.87-.705-1.575-1.575-1.575H4.19z"
      clipRule="evenodd"
    />
    <path
      fill="url(#bAddressbookListSvg)"
      d="M4.013 2.413c0-.57.464-1.034 1.035-1.034h7.386a2.07 2.07 0 0 1 2.07 2.069H5.047a1.035 1.035 0 0 1-1.035-1.035z"
    />
    <path
      fill="url(#cAddressbookListSvg)"
      fillRule="evenodd"
      d="M6.287 10.168c0-2.286 1.937-4.134 4.28-4.134 2.35 0 4.287 1.848 4.287 4.134 0 1.152-.425 2.221-1.124 3.128a11.06 11.06 0 0 1-2.793 2.555c-.245.158-.466.17-.734 0a10.847 10.847 0 0 1-2.792-2.555c-.7-.907-1.124-1.976-1.124-3.128zm2.87.128c0 .766.633 1.368 1.41 1.368.777 0 1.417-.602 1.417-1.368 0-.76-.64-1.392-1.417-1.392s-1.41.632-1.41 1.392z"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="aAddressbookListSvg"
        x1={2.615}
        x2={2.615}
        y1={0}
        y2={20}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={global.config.colors.iconLightEnd} />
        <stop offset={1} stopColor={global.config.colors.iconLightStart} />
      </linearGradient>
      <linearGradient
        id="bAddressbookListSvg"
        x1={4.013}
        x2={4.013}
        y1={1.379}
        y2={3.448}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={global.config.colors.iconDarkEnd}/>
        <stop offset={1} stopColor={global.config.colors.iconDarkStart} />
      </linearGradient>
      <linearGradient
        id="cAddressbookListSvg"
        x1={6.287}
        x2={6.287}
        y1={6.034}
        y2={15.974}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={global.config.colors.iconDarkEnd} />
        <stop offset={1} stopColor={global.config.colors.iconDarkStart} />
      </linearGradient>
    </defs>
  </svg>
)
export default AddressbookListSvg
