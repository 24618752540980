import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";

import styles from './style.module.css';
import { getCenterMap, setCenterMap } from "../../../../../helper/module";
import { Set_DropOff_Inputs, Set_latlng, Set_Pickup_Inputs, SetopenAddressBookInNewOrder } from "../../../../../Redux/actions/actionNames";
import IconProvider from "../../../../../design-system/lib/IconProvider";
import Typography from "../../../../../design-system/lib/Typography";
import Tag from "../../../../../design-system/lib/Tag";
import Button from "../../../../../design-system/lib/Button";
import { ARROW_DOWN_BOLD } from "../../../../../static";
import ic_pin from "../../../../../static/IconProvider/ic_pin.svg";
import ic_notes from "../../../../../static/IconProvider/ic_notes.svg";
import emails from "../../../../../static/IconProvider/email.svg";
import ic_notesx from "../../../../../static/IconProvider/ic_notesx.svg";
import { ReactComponent as IC_address_book } from '../../../../../static/IconProvider/IC_address_book.svg'
import IconProviderSvgModifier from "../../../../../design-system/lib/IconProviderSvgModifier";



var _ = require("lodash");

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false
    };
    this.anchorEl = React.createRef();
  }

  showPoper = () => {
    this.setState({ showpoper: true });
  };

  setAddressToPD = () => {

    setCenterMap(this.props.mapRef, { lng: this.props.AddressData.coordinates[0], lat: this.props.AddressData.coordinates[1] })

    try {
      getCenterMap(this.props.mapRef, { lng: this.props.AddressData.coordinates[0], lat: this.props.AddressData.coordinates[1] })
      this.props.dispatch(
        Set_latlng({
          lng: this.props.AddressData.coordinates[0],
          lat: this.props.AddressData.coordinates[1],
        })
      );
      // this.props.mapRef.current.leafletElement.setView({lng: this.props.AddressData.coordinates[0], lat: this.props.AddressData.coordinates[1]}, 17);
    } catch (error) {
      this.props.dispatch(
        Set_latlng({
          lng: this.props.AddressData.coordinates[0],
          lat: this.props.AddressData.coordinates[1],
        })
      );
    }

    if (this.props.sourceOfAddressBookInNewOrder.type == "dropoff") {
      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "adresss_loc",
          value: { lng: this.props.AddressData.coordinates[0], lat: this.props.AddressData.coordinates[1] },
          firstDropOff: this.props.sourceOfAddressBookInNewOrder.id == "d123",
          id: this.props.sourceOfAddressBookInNewOrder.id,
        })
      );


      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "address",
          value: this.props.AddressData.address,
          firstDropOff: this.props.sourceOfAddressBookInNewOrder.id == "d123",
          id: this.props.sourceOfAddressBookInNewOrder.id,
        })
      );


      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "block",
          value: this.props.AddressData.buildingBlock,
          firstDropOff: this.props.sourceOfAddressBookInNewOrder.id == "d123",
          id: this.props.sourceOfAddressBookInNewOrder.id,
        })
      );


      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "floor",
          value: this.props.AddressData.floor,
          firstDropOff: this.props.sourceOfAddressBookInNewOrder.id == "d123",
          id: this.props.sourceOfAddressBookInNewOrder.id,
        })
      );

      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "recieverFullName",
          value: this.props.AddressData.fullName,
          firstDropOff: this.props.sourceOfAddressBookInNewOrder.id == "d123",
          id: this.props.sourceOfAddressBookInNewOrder.id,
        })
      );


      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "unit",
          value: this.props.AddressData.room,
          firstDropOff: this.props.sourceOfAddressBookInNewOrder.id == "d123",
          id: this.props.sourceOfAddressBookInNewOrder.id,
        })
      );

      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "recieverPhoneNumber",
          value: (this.props.AddressData.phone && this.props.AddressData.phone.length > 0) ? (String(this.props.AddressData.phone).startsWith("00") ?
            "+" + String(this.props.AddressData.phone).slice(2, this.props.AddressData.phone.length) : String(this.props.AddressData.phone)) : "",
          firstDropOff: this.props.sourceOfAddressBookInNewOrder.id == "d123",
          id: this.props.sourceOfAddressBookInNewOrder.id,
        })
      );


      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "noteForDriver",
          value: this.props.AddressData.description,
          firstDropOff: this.props.sourceOfAddressBookInNewOrder.id == "d123",
          id: this.props.sourceOfAddressBookInNewOrder.id,
        })
      );



      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "email",
          value: this.props.AddressData.email,
          firstDropOff: this.props.sourceOfAddressBookInNewOrder.id == "d123",
          id: this.props.sourceOfAddressBookInNewOrder.id,
        })
      );





    } else {
      this.props.dispatch(
        Set_Pickup_Inputs({
          name: "adresss_loc",
          value: { lng: this.props.AddressData.coordinates[0], lat: this.props.AddressData.coordinates[1] },
        })
      );

      this.props.dispatch(
        Set_Pickup_Inputs({
          name: "address",
          value: this.props.AddressData.address,
        })
      );




      this.props.dispatch(
        Set_Pickup_Inputs({
          name: "block",
          value: this.props.AddressData.buildingBlock,
        })
      );

      this.props.dispatch(
        Set_Pickup_Inputs({
          name: "floor",
          value: this.props.AddressData.floor,
        })
      );

      this.props.dispatch(
        Set_Pickup_Inputs({
          name: "senderFullName",
          value: this.props.AddressData.fullName,
        })
      );


      this.props.dispatch(
        Set_Pickup_Inputs({
          name: "senderPhoneNumber",
          value: (this.props.AddressData.phone && this.props.AddressData.phone.length > 0) ? (String(this.props.AddressData.phone).startsWith("00") ?
            "+" + String(this.props.AddressData.phone).slice(2, this.props.AddressData.phone.length) : String(this.props.AddressData.phone)) : "",
        })
      );

      this.props.dispatch(
        Set_Pickup_Inputs({
          name: "unit",
          value: this.props.AddressData.room,
        })
      );

      this.props.dispatch(
        Set_Pickup_Inputs({
          name: "noteForDriver",
          value: this.props.AddressData.description,
        })
      );
      this.props.dispatch(
        Set_Pickup_Inputs({
          name: "email",
          value: this.props.AddressData.email,
        })
      );



    }
    this.props.dispatch(SetopenAddressBookInNewOrder(false, "", null, ""));

  };


  getBlockFloorRoom = () => {
    if (this.props.AddressData.buildingBlock.length > 0 || this.props.AddressData.room.length > 0 || this.props.AddressData.floor.length > 0) {
      let arr = [];
      this.props.AddressData.buildingBlock.length > 0 && arr.push(`${this.props.t("block")} ${this.props.AddressData.buildingBlock}`)
      this.props.AddressData.room.length > 0 && arr.push(`${this.props.t("Unit")} ${this.props.AddressData.room}`)
      this.props.AddressData.floor.length > 0 && arr.push(`${this.props.t("Floor")} ${this.props.AddressData.floor}`)


      return arr.join(" , ")
    } else {
      return false
    }
  }

  getNamePhone = () => {
    if (this.props.AddressData.phone.length > 0 || this.props.AddressData.fullName.length > 0) {

      let arr = [];

      this.props.AddressData.phone.length > 0 && arr.push(this.props.AddressData.phone)
      this.props.AddressData.fullName.length > 0 && arr.push(this.props.AddressData.fullName)

      return arr.join(" , ")
    } else {
      return false
    }
  }


  getNote = () => {
    if (
      this.props.AddressData.description.length > 0
    ) {
      return this.props.AddressData.description
    } else {
      return false;
    }
  };


  getEmail = () => {
    if (
      this.props.AddressData.email.length > 0
    ) {
      return this.props.AddressData.email
    } else {
      return false;
    }
  };



  render() {
    return (
      <div className={styles.addressItemMenuContainer} >

        <div className={styles.frow}>
          <div className={styles.innerFrow} onClick={this.setAddressToPD}>

            <IconProviderSvgModifier size="12" Icon={IC_address_book} />
            <Typography text={this.props.AddressData.title} weight="Body_Middle_Bold" />
            {(this.props.AddressData.isDefaultPickupAddress) && <Tag text="Default pickup" size="Medium" backgroundColor="GrayUltraLight" weight="Body_Small_Regular" style={{ marginInlineStart: "4px" }} />
            }
          </div>

          {(this.getNamePhone() && this.getEmail() && this.getNote()) && <Button size="Medium" type="Secondary" icon={ARROW_DOWN_BOLD} style={{ transform: "scale(.7)", marginInlineStart: "auto" }}  onClick={()=>{this.setState({collapse:!this.state.collapse})}}/>
          }        </div>

        <div className={styles.row} onClick={this.setAddressToPD}>
          <IconProvider Icon={ic_pin} />
          <div className={styles.rowTexts}>
            <Typography text={this.props.AddressData.address} weight="Body_Tiny_Regular" style={{ color: "#484848" }} />
            {this.getBlockFloorRoom() && <Typography text={this.getBlockFloorRoom()} weight="Body_Tiny_Regular" style={{ color: "#808080" }} />}
          </div>

        </div>

        {(this.getNamePhone() && this.state.collapse) && <div className={styles.row} onClick={this.setAddressToPD}>
          <IconProvider Icon={ic_notes} />
          <div className={styles.rowTexts}>
            {this.props.AddressData.fullName.length > 0 && <Typography text={this.props.AddressData.fullName} weight="Body_Tiny_Regular" style={{ color: "#484848" }} />}
            {this.props.AddressData.phone.length > 0 && <Typography text={this.props.AddressData.phone} weight="Body_Tiny_Regular" style={{ color: "#808080" }} />}
          </div>

        </div>}

        {(this.getEmail() && this.state.collapse) && <div className={styles.row} onClick={this.setAddressToPD}>
          <IconProvider Icon={emails} />
          <div className={styles.rowTexts}>
            <Typography text={this.getEmail()} weight="Body_Tiny_Regular" style={{ color: "#484848" }} />
          </div>

        </div>}


        {(this.getNote() && this.state.collapse) && <div className={styles.row} onClick={this.setAddressToPD}>
          <IconProvider Icon={ic_notesx} />
          <div className={styles.rowTexts}>
            <Typography text={this.getNote()} weight="Body_Tiny_Regular" style={{ color: "#484848" }} />
          </div>

        </div>}

        {/* <span className="admcT"> {this.props.AddressData.title}</span>
       {(this.props.AddressData.isDefaultPickupAddress) && <span className="admcDFP"> Default pickup</span>}
        <div className="admcI">
          <img src={add1} />
          <span> {this.props.AddressData.address}</span>
        </div>

        {this.getBlockFloorRoom() && <div className="admcI">
          <img src={add2} />
          <span> {this.getBlockFloorRoom()}</span>
        </div>}

       {this.getNamePhone() && <div className="admcI">
          <img src={add3} />
          <span> {this.getNamePhone()}</span>
        </div>}

        {this.getNote() && (
          <div className="admcI">
            <img src={note} />
            <span> {this.getNote()}</span>
          </div>
        )}

      {this.getEmail() && (
          <div className="admcI">
            <img src={email} />
            <span> {this.getEmail()}</span>
          </div>
        )} */}

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mapRef: state.Map.mapRef,
  center: state.Map.center,
  sourceOfAddressBookInNewOrder:
    state.Addressbook.sourceOfAddressBookInNewOrder,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
