import * as React from "react"
const IconPickupSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={8} height={10} {...props}>
    <title>{"B4C73848-B3E0-4D46-B0DB-09D77820A4B2"}</title>
    <defs>
      <linearGradient id="aIconPickupSvg" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconDarkEnd} />
        <stop offset="100%" stopColor={global.config.colors.iconDarkStart} />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(0 .893)">
      <ellipse cx={4} cy={4.107} fill="url(#aIconPickupSvg)" rx={4} ry={4.107} />
      <ellipse cx={4} cy={4.107} fill="#FFF" rx={1.6} ry={1.643} />
    </g>
  </svg>
)
export default IconPickupSvg
