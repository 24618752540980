import styles from './style.module.css';
import clsx from 'clsx';
import icNewOrder from '../../../../static/ic_plus.svg'
import ic_arrow_triangle_down_white from '../../../../static/ic_arrow_triangle_down_white.svg'
import ic_arrow_triangle_up_white from '../../../../static/ic_arrow_triangle_up_white.svg'
import ic_import from '../../../../static/ic_import.svg'
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux';
import { useRef, useState } from 'react';
import { ClickAwayListener, Grow, Paper, Popper } from '@material-ui/core';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Api_GetVehicleTypeByService_AGW, Api_Get_Services_AGW, Api_get_balance_AGW } from '../../../../helper/api/ApiGateway';
import { getCenterMap } from '../../../../helper/module';
import { ChangeNewTravelDialoge, SetBulkImportOpen } from '../../../../Redux/actions/actionNames';

import ic_add from "../../../../static/plus_white.svg";
import import2 from "../../../../static/import2.svg";
import Typography from '../../../../design-system/lib/Typography';


export const NewOrderButton = ({ }) => {
  const dispatch = useDispatch();
  const ref = useRef(null); // ref => { current: null }
  const [openDropDown, setOpenDropDown] = useState(false);
  const { t, i18n } = useTranslation();
  const center = useSelector((state) => state.Map.center)
  const activeVehicleType = useSelector((state) => state.NewOrder.newOrder.service.activeVehicleType)
  const mapRef = useSelector((state) => state.Map.mapRef)



  function NewTravelHandler() {
    dispatch(ChangeNewTravelDialoge());

    Api_Get_Services_AGW(dispatch,mapRef.current ? getCenterMap(mapRef , center) : center);

    try {
      let center = getCenterMap(mapRef , center) ;


      Api_GetVehicleTypeByService_AGW(dispatch, {
        latitude:mapRef.current ? center.lat : center.lat,
        longitude:mapRef.current ? center.lng : center.lng,
        serviceId: activeVehicleType.id,
      });
    } catch (error) {}
    Api_get_balance_AGW(dispatch, {}, (call) => {});
  };


  return <>
    {/* <div className={styles.container} style={{background: !global.config.enabledCustomColor ? "" : `var(--Linear, linear-gradient(180deg, ${global.config.icon_light_end} 0%, ${global.config.icon_light_start} 100%))`}}>
      <img className={styles.icNewOrder} onClick={NewTravelHandler} src={icNewOrder}/>
      <img  ref={el => {ref.current = el; }} className={styles.icMenu} src={openDropDown ? ic_arrow_triangle_up_white : ic_arrow_triangle_down_white} onClick={()=>{setOpenDropDown(true)}} />

    </div>

   {ref && <Popper
      open={openDropDown}
      anchorEl={ref.current}
      role={undefined}
      style={{
        zIndex: 6,
        marginTop: "10px",
        borderRadius: "12px",
        boxShadow:"none"
      }}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          timeout={{ enter: 150, exit: 150 }}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom",
          }}
        >
          <Paper  style={{ boxShadow:"none"  }}>
            <ClickAwayListener onClickAway={()=>{setOpenDropDown(false)}}>
            <div className={styles.MenuRowContainer} onClick={()=>{dispatch(SetBulkImportOpen(true))}}>
                <img className={styles.MenuRowIcon}  src={ic_import}/>
                <span className={styles.MenuRowTitle} >{t("Bulkimport")}</span>
              </div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>} */}


<Link to={`/neworder`} ><div className={styles.buttonx} onClick={NewTravelHandler}>
        <img src={ic_add} />
        <Typography weight={"Body_Middle_Medium"} text={"New"} style={{color:"white"}} textColor={"White"} className={styles.buttonText} onClick={() => { }} />
      </div>   
      </Link>
       <div className={styles.button} onClick={()=>{dispatch(SetBulkImportOpen(true))}}>
        <img src={import2} />
        <Typography weight={"Body_Middle_Medium"} text={"Import"} textColor={"TextDark"} className={styles.buttonText} onClick={() => { }} />
      </div>


  </>
};




