import React, { useCallback, useEffect, useRef } from 'react'
import styles from './style.module.css';
import ic_box from '../../../../static/ic_box.svg';
import IconProvider from '../../../../design-system/lib/IconProvider';
import Typography from '../../../../design-system/lib/Typography';
// import ic_add from '../../../../static/ic_add.svg';
import ic_add from "../../../../static/plus_white.svg";
import import2 from "../../../../static/import2.svg";
import { ChangeNewTravelDialoge, SetBulkImportOpen } from '../../../../Redux/actions/actionNames';
import { useDispatch, useSelector } from 'react-redux';
import { Api_GetVehicleTypeByService_AGW, Api_Get_Services_AGW, Api_get_balance_AGW } from '../../../../helper/api/ApiGateway';
import { getCenterMap } from '../../../../helper/module';
import { isEmpty } from 'lodash';
import { Link } from "react-router-dom";

function ListNoOrder() {
  const dispatch = useDispatch()
  const center = useSelector((state) => state.Map.center)
  const activeVehicleType = useSelector((state) => state.NewOrder.newOrder.service.activeVehicleType)
  const mapRef = useSelector((state) => state.Map.mapRef)
  const OrderList = useSelector((state) => state.OrderList.OrderList)
  const OrderListLoading = useSelector((state) => state.OrderList.OrderListLoading)
  const OrderListError = useSelector((state) => state.OrderList.OrderListError)


  function NewTravelHandler() {
    dispatch(ChangeNewTravelDialoge());

    Api_Get_Services_AGW(dispatch,mapRef.current ? getCenterMap(mapRef , center) : center);

    try {
      let center = getCenterMap(mapRef , center) ;


      Api_GetVehicleTypeByService_AGW(dispatch, {
        latitude:mapRef.current ? center.lat : center.lat,
        longitude:mapRef.current ? center.lng : center.lng,
        serviceId: activeVehicleType.id,
      });
    } catch (error) {}
    Api_get_balance_AGW(dispatch, {}, (call) => {});
  };

  if (
    !OrderListError &&
    !OrderListLoading &&
    isEmpty(OrderList) 
  ) {
    return (
    <div className='jojojoj'
      style={{ display: 'flex', position: 'relative', height: `calc(100vh - 104px)`, width: "100%", flexDirection: 'column', transition: "all .3s ease-in-out", justifyContent: "center", alignItems: "center" }}>
      <div className={styles.dialog}>
        <IconProvider Icon={ic_box} style={{ marginBottom: "16px" }} />
        <Typography weight='Body_Middle_Bold' text='Create your order' style={{ textAlign: "center", marginBottom: "8px" }} />
        <Typography weight='Body_Tiny_Regular' text='Your orders will be shown here' style={{ textAlign: "center", marginBottom: "16px", color: "rgba(128, 128, 128, 1)" }} />
        <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",gap:"10px"}}>
        <Link to={`/neworder`} ><div className={styles.buttonx} onClick={NewTravelHandler}>
            <img src={ic_add} />
            <Typography weight={"Body_Middle_Medium"} text={"New"} style={{color:"white"}} textColor={"White"} className={styles.buttonText} onClick={() => { }} />
          </div>
          </Link>
          <div className={styles.button} onClick={() => { dispatch(SetBulkImportOpen(true)) }}>
            <img src={import2} />
            <Typography weight={"Body_Middle_Medium"} text={"Import"} textColor={"TextDark"} className={styles.buttonText} onClick={() => { }} />
          </div>

        </div>
      </div>
    </div>
  )
  }else{
    return null;

  }

  
}
export default ListNoOrder