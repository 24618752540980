import React from "react";

function InvoiceSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill="url(#p3xp09g2na)"
        d="M1.667 5v2.017c0 1.316.833 2.15 2.15 2.15h2.85V3.342c0-.925-.758-1.684-1.683-1.675a3.35 3.35 0 00-2.342.975A3.354 3.354 0 001.667 5z"
      ></path>
      <path
        fill="url(#4kkriv5xub)"
        d="M18.334 5.833V17.5a.83.83 0 01-1.333.666L15.576 17.1a.84.84 0 00-1.1.083l-1.384 1.392a.84.84 0 01-1.183 0l-1.4-1.4a.826.826 0 00-1.083-.075L8 18.166a.835.835 0 01-1.334-.666V3.333c0-.917-.75-1.667-1.666-1.667h10c2.5 0 3.333 1.492 3.333 3.334v.833z"
      ></path>
      <path
        fill="url(#aoyc2kefmc)"
        d="M10.001 10.216h2.5a.63.63 0 01.625.625.63.63 0 01-.625.625h-2.5a.63.63 0 01-.625-.625.63.63 0 01.625-.625z"
      ></path>
      <path
        fill="url(#8olqel3g8d)"
        d="M12.501 8.133h-2.5a.63.63 0 01-.625-.625.63.63 0 01.625-.625h2.5a.63.63 0 01.625.625.63.63 0 01-.625.625z"
      ></path>
      <path
        fill="url(#0hqclwn6ye)"
        d="M15.026 6.675a.83.83 0 01.833.833.836.836 0 01-.833.833.836.836 0 01-.833-.833c0-.458.375-.833.833-.833z"
      ></path>
      <path
        fill="url(#x030tlhu7f)"
        d="M15.026 10.008a.83.83 0 01.833.833.836.836 0 01-.833.834.836.836 0 01-.833-.834c0-.458.375-.833.833-.833z"
      ></path>
      <defs>
        <linearGradient
          id="p3xp09g2na"
          x1="6.667"
          x2="6.667"
          y1="1.667"
          y2="9.167"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={global.config.colors.iconDarkEnd}></stop>
          <stop offset="1" stopColor={global.config.colors.iconDarkStart}></stop>
        </linearGradient>
        <linearGradient
          id="4kkriv5xub"
          x1="18.334"
          x2="18.334"
          y1="1.667"
          y2="18.819"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={global.config.colors.iconLightEnd}></stop>
          <stop offset="1" stopColor={global.config.colors.iconLightStart}></stop>
        </linearGradient>
        <linearGradient
          id="aoyc2kefmc"
          x1="13.126"
          x2="13.126"
          y1="10.216"
          y2="11.466"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={global.config.colors.iconDarkEnd}></stop>
          <stop offset="1" stopColor={global.config.colors.iconDarkStart}></stop>
        </linearGradient>
        <linearGradient
          id="8olqel3g8d"
          x1="13.126"
          x2="13.126"
          y1="6.883"
          y2="8.133"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={global.config.colors.iconDarkEnd}></stop>
          <stop offset="1" stopColor={global.config.colors.iconDarkStart}></stop>
        </linearGradient>
        <linearGradient
          id="0hqclwn6ye"
          x1="15.859"
          x2="15.859"
          y1="6.675"
          y2="8.341"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={global.config.colors.iconDarkEnd}></stop>
          <stop offset="1" stopColor={global.config.colors.iconDarkStart}></stop>
        </linearGradient>
        <linearGradient
          id="x030tlhu7f"
          x1="15.859"
          x2="15.859"
          y1="10.008"
          y2="11.675"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={global.config.colors.iconDarkEnd}></stop>
          <stop offset="1" stopColor={global.config.colors.iconDarkStart}></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default InvoiceSvg;
