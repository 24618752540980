import * as React from "react"
const PinPickup = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} {...props}>
    <defs>
      <linearGradient id="aPinPickup" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.secondaryLightColor}/>
        <stop offset="100%" stopColor={global.config.colors.secondaryColor} />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(6.06)">
      <path
        fill="url(#aPinPickup)"
        d="M19.027 40.878c3.227 0 6.115-1.351 6.455-.675.227.45-1.925 3.716-6.455 9.797-4.53-6.081-6.682-9.347-6.456-9.797.34-.676 3.228.675 6.456.675ZM18.687 0c10.32 0 18.687 8.319 18.687 18.581 0 10.262-8.367 18.581-18.687 18.581S0 28.843 0 18.582C0 8.318 8.366 0 18.687 0Z"
      />
      <circle cx={19.192} cy={18.687} r={7.071} fill="#FFF" />
    </g>
  </svg>
)
export default PinPickup
