import * as React from "react"
const PinFavoriteSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    fill="none"
    {...props}
  >
    <path
      fill="url(#aPinFavoriteSvg)"
      fillRule="evenodd"
      d="M24.465 38.71c10.751 0 19.466-8.665 19.466-19.355C43.931 8.665 35.216 0 24.466 0 13.714 0 5 8.666 5 19.355c0 10.69 8.715 19.356 19.465 19.356zm4.257 3.27c1.25-.272 2.116-.46 2.296-.102.354.704-5.774 8.122-6.725 8.122-.95 0-7.078-7.418-6.724-8.122.18-.358 1.046-.17 2.296.101 1.208.263 2.776.603 4.428.603 1.653 0 3.22-.34 4.429-.603z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M19.456 25.408c.288.197.654.13 1.09-.147l3.722-2.387 3.73 2.387c.435.276.794.344 1.09.147.288-.19.352-.503.176-.945l-1.47-3.822 3.756-2.35c.437-.27.612-.558.5-.858-.113-.301-.443-.448-.985-.442l-4.609.03-1.4-3.84c-.169-.448-.422-.681-.788-.681-.358 0-.612.233-.78.681l-1.4 3.84-4.61-.03c-.54-.006-.872.141-.984.442-.12.3.063.589.5.858l3.756 2.35-1.47 3.822c-.176.442-.113.755.176.945z"
    />
    <defs>
      <linearGradient
        id="aPinFavoriteSvg"
        x1={5}
        x2={5}
        y1={0}
        y2={50}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={global.config.colors.iconDarkEnd} />
        <stop offset={1} stopColor={global.config.colors.iconDarkStart}  />
      </linearGradient>
    </defs>
  </svg>
)
export default PinFavoriteSvg
