import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { Add_Driver_Option, Remove_Driver_Option } from "../../../../../Redux/actions/actionNames";
import { get_lang } from "../../../../../helper/UserData/userdate";
import Button from "../../../../../design-system/lib/Button";
import IconProvider from "../../../../../design-system/lib/IconProvider";
import { ic_circle_close } from "../../../../../static";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showpoper: false,
      active: false,
      selected: null
    };
    this.anchorEl = React.createRef();
  }

  handleactive = () => {
    if (this.optionisSaved()) {
      this.props.dispatch(Remove_Driver_Option(this.props.id))
    } else {
      this.props.dispatch(Add_Driver_Option({
        name: this.props.name,
        id: this.props.id,
        type: "boolean"
      }))
    }

  };


  optionisSaved() {
    let booll = false;
    booll = this.props.options.find((item) => {
      return item.id === this.props.id;
    });
    if (booll) {
      return true
    } else {
      return false
    }
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment >
        <Button style={{ maxWidth: "412px" }} size={'Small'} type='Secondary' isRequired={this.props.isRequired} requiredText={this.props.t("Required")} 
        text={<span style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '8px',
          marginInlineStart: '2px',
        }}>
          {this.optionisSaved() && <IconProvider Icon={ic_circle_close} size="14" onClick={(e) => {
            e.stopPropagation();
            this.handleactive()
          }} />}
          {this.props.title}</span>} isActive={this.optionisSaved()} onClick={this.handleactive} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.NewOrder.newOrder.driver.options,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
