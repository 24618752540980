import * as React from "react"
const IconBoxSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={42} height={42} {...props}>
    <defs>
      <linearGradient id="aIconBoxSvg" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconLightEnd} />
        <stop offset="100%" stopColor={global.config.colors.iconLightStart}  />
      </linearGradient>
      <linearGradient id="bIconBoxSvg" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconLightEnd}  />
        <stop offset="100%" stopColor={global.config.colors.iconLightStart}  />
      </linearGradient>
      <linearGradient id="cIconBoxSvg" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconDarkEnd}  />
        <stop offset="100%" stopColor={global.config.colors.iconDarkStart}  />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="nonzero">
      <path
        fill="url(#aIconBoxSvg)"
        d="m31.28 13.57 7.439-4.096c-.297-.288-.653-.517-1.088-.747L23.665 1.034C22.458.364 21.252 0 20.085 0c-1.187 0-2.394.364-3.6 1.034l-4.016 2.2L31.28 13.57z"
        transform="translate(.313)"
      />
      <path
        fill="url(#bIconBoxSvg)"
        d="m20.489 19.385 8.386-4.594L10.276 4.523 3.07 8.49c-.432.228-.786.455-1.1.74l18.52 10.155z"
        transform="translate(.313)"
      />
      <path
        fill="url(#cIconBoxSvg)"
        d="M21.656 42a1.71 1.71 0 0 0 .389-.175l15.209-8.671c1.806-1.03 2.777-2.08 2.777-4.9V13.108c0-.583-.038-1.05-.155-1.477l-18.22 10.421V42zm-3.281 0V22.052L.155 11.631C.04 12.058 0 12.525 0 13.108v15.146c0 2.82.99 3.87 2.778 4.9l15.228 8.671c.116.078.233.136.369.175z"
        transform="translate(.313)"
      />
    </g>
  </svg>
)
export default IconBoxSvg
