import React from "react";
import styles from './style.module.css';
import { ModalProps } from "./CustomModal.types";



const CustomModal: React.FC<ModalProps> = ({children , onclose }: ModalProps) => {


        return (
            <div className={styles.modal}  >

                <div className={styles.backDrop} onClick={onclose}  ></div>
                <div className={styles.back}>{children}</div>

                
            </div>
        )
    


};

export default CustomModal;

