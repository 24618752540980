import * as React from "react"
const TransactionSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <defs>
      <linearGradient id="aTransactionSvg" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconLightEnd} />
        <stop offset="100%" stopColor={global.config.colors.iconLightStart}  />
      </linearGradient>
      <linearGradient id="bTransactionSvg" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconDarkEnd}  />
        <stop offset="100%" stopColor={global.config.colors.iconDarkStart}  />
      </linearGradient>
      <linearGradient id="cTransactionSvg" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconDarkEnd}  />
        <stop offset="100%" stopColor={global.config.colors.iconDarkStart}  />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(0 2.5)">
      <path
        fill="url(#aTransactionSvg)"
        d="M4.167 0h11.666A4.166 4.166 0 0 1 20 4.167v6.666A4.166 4.166 0 0 1 15.833 15H4.167A4.166 4.166 0 0 1 0 10.833V4.167A4.166 4.166 0 0 1 4.167 0z"
      />
      <rect
        width={2.5}
        height={2.5}
        x={4.167}
        y={9.167}
        fill="url(#bTransactionSvg)"
        rx={1.25}
      />
      <rect
        width={16.667}
        height={1.667}
        x={1.667}
        y={4.167}
        fill="url(#cTransactionSvg)"
        rx={0.833}
      />
    </g>
  </svg>
)
export default TransactionSvg
