import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ReactComponent as Adddiscount } from "../../../../../static/IconProvider/add_discount.svg";
import icon_done from "../../../../../static/icon-ic-done.svg";
import { withTranslation, Trans } from "react-i18next";
import Slide from "@material-ui/core/Fade";
import { get_customerPannel_type, get_Id, get_LeftRight } from "../../../../../helper/UserData/userdate";
import { DetectOndemandOrReserve } from "../../../../../helper/worker/DetectOndemandOrReserve";
import { Api_ondemand_promotion_verify_AGW, Api_pickupDelivery_promotion_verify_AGW } from "../../../../../helper/api/ApiGateway";
import momentJalaali from "moment-jalaali";
import { Set_PromoCode } from "../../../../../Redux/actions/actionNames";
import { DetectOndemandOrPickupDelivery } from "../../../../../helper/worker/DetectOndemandOrReserve copy";
import debounce from "lodash/debounce";
import InputFilter from "../../../../../design-system/lib/InputFilter";
import IconProviderSvgModifier from "../../../../../design-system/lib/IconProviderSvgModifier";
import error from "../../../OrderHistory/error";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openPromo: false,
      PromoStatus: true,
      verifyLoading :false

    };

    this.debounceSave = debounce(this.Save.bind(this), 1000);

  }


  componentDidMount(){
    this.canShowPromoCode_withOrderType()
  }

  componentWillReceiveProps(){
    this.canShowPromoCode_withOrderType()
  }

  HandlePropmoOpen = () => {
    this.props.code.length == 0 &&
      this.setState({
        openPromo: !this.state.openPromo,
      });
  };


  promoCodeOnchange = (e) => {
    if(!e || String(e).trim().length == 0){
      this.promoCodeDelete()
      this.props.dispatch(Set_PromoCode({
        code: "" ,
        valid: false,
        data: null ,
        error : false
      }))
    }else{
       this.debounceSave(e ? e : "" ,()=>{
      // this.promoCodeDelete()
      // this.props.dispatch(Set_PromoCode({
      //   code: e ? e : "" ,
      //   valid: false,
      //   data: null
      // }))
    })
    }
    
   
  }


  promoCodeDelete = () => {
    if (this.props.code.length == 0) {
      this.setState({
        openPromo: !this.state.openPromo,
      });
    } else {
      this.props.dispatch(Set_PromoCode({
        code: "",
        valid: false,
        data: null ,
        error : false
      }))
    }

  }


  removeEmpty = (obj) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
  }


  Save = (code ,funcSave) => {
    this.setState({verifyLoading : true})
    DetectOndemandOrPickupDelivery(
      this.props.serviceactiveVehicleType ? this.props.serviceactiveVehicleType.orderTypes : [],
      (call) => {
        if (call === "ondeman") {
          Api_ondemand_promotion_verify_AGW(
            this.props.dispatch,
            this.removeEmpty({
              // "vehicletypeId": this.props.activeVehicleType.id,
              isScheduled: this.props.pickup.scheduleDateAfter ||
                this.props.pickup.scheduleDateBefore
                ? true
                : false,
              vehicleType: {
                id: this.props.activeVehicleType.id,
                options: this.props.VehicleType_options.map((item) => {
                  return { dataId: item.item ? item.item.id : "", id: item.id };
                }),
              },
              pickup: {
                coordinates: [
                  this.props.pickup_adresss_loc.lng,
                  this.props.pickup_adresss_loc.lat,
                ],
                scheduleDateAfter: momentJalaali(
                  this.props.pickup.scheduleDateAfter
                ).isValid()
                  ? momentJalaali(
                    Number(this.props.pickup.scheduleDateAfter + "000")
                  ).unix()
                  : 0,
                scheduleDateBefore: momentJalaali(
                  this.props.pickup.scheduleDateBefore
                ).isValid()
                  ? momentJalaali(
                    Number(this.props.pickup.scheduleDateBefore + "000")
                  ).unix()
                  : 0,
                schedulePickupNow: false,
              },
              dropoffs: [
                {
                  coordinates: [
                    this.props.firstDropOff.adresss_loc.lng,
                    this.props.firstDropOff.adresss_loc.lat,
                  ],
                  scheduleDateAfter: momentJalaali(
                    this.props.firstDropOff.scheduleDateAfter
                  ).isValid()
                    ? momentJalaali(
                      Number(
                        this.props.firstDropOff.scheduleDateAfter + "000"
                      )
                    ).unix()
                    : 0,
                  scheduleDateBefore: momentJalaali(
                    this.props.firstDropOff.scheduleDateBefore
                  ).isValid()
                    ? momentJalaali(
                      Number(
                        this.props.firstDropOff.scheduleDateBefore + "000"
                      )
                    ).unix()
                    : 0,
                },
                ...this.props.otherdropOffs.map((drop) => {
                  return {
                    coordinates: [drop.adresss_loc.lng, drop.adresss_loc.lat],
                    scheduleDateAfter: momentJalaali(
                      drop.scheduleDateAfter
                    ).isValid()
                      ? momentJalaali(
                        Number(drop.scheduleDateAfter + "000")
                      ).unix()
                      : 0,
                    scheduleDateBefore: momentJalaali(
                      drop.scheduleDateBefore
                    ).isValid()
                      ? momentJalaali(
                        Number(drop.scheduleDateBefore + "000")
                      ).unix()
                      : 0,
                  };
                }),
              ],
              service: {
                id: this.props.activeservice.id,
                options: this.props.service_options.map((item) => {
                  return { dataId: item.item ? item.item.id : "", id: item.id };
                }),
              },

              promoCode: code,
              customerId: get_Id(),
            })
            ,(status)=>{
              this.setState({verifyLoading : false})

               status && funcSave()
            }
          );
        } else {

          Api_pickupDelivery_promotion_verify_AGW(this.props.dispatch, 
            this.removeEmpty({
            isScheduled: this.props.pickup.scheduleDateAfter ||
              this.props.pickup.scheduleDateBefore
              ? true
              : false,
            vehicleType: {
              id: this.props.activeVehicleType.id,
              options: this.props.VehicleType_options.map((item) => {
                return { dataId: item.item ? item.item.id : "", id: item.id };
              }),
            },
            pickup: {
              coordinates: [
                this.props.pickup_adresss_loc.lng,
                this.props.pickup_adresss_loc.lat,
              ],
              scheduleDateAfter: momentJalaali(
                this.props.pickup.scheduleDateAfter
              ).isValid()
                ? momentJalaali(
                  Number(this.props.pickup.scheduleDateAfter + "000")
                ).unix()
                : 0,
              scheduleDateBefore: momentJalaali(
                this.props.pickup.scheduleDateBefore
              ).isValid()
                ? momentJalaali(
                  Number(this.props.pickup.scheduleDateBefore + "000")
                ).unix()
                : 0,
            },
            dropoff: {
              coordinates: [
                this.props.firstDropOff.adresss_loc.lng,
                this.props.firstDropOff.adresss_loc.lat,
              ],
              scheduleDateAfter: momentJalaali(
                this.props.firstDropOff.scheduleDateAfter
              ).isValid()
                ? momentJalaali(
                  Number(
                    this.props.firstDropOff.scheduleDateAfter + "000"
                  )
                ).unix()
                : 0,
              scheduleDateBefore: momentJalaali(
                this.props.firstDropOff.scheduleDateBefore
              ).isValid()
                ? momentJalaali(
                  Number(
                    this.props.firstDropOff.scheduleDateBefore + "000"
                  )
                ).unix()
                : 0,
            },
            "service": {
              "id": this.props.activeservice.id,
              "options": this.props.service_options.map((item) => {
                return {
                  dataId: item.item ? item.item.id : "",
                  id: item.id
                }
              })
            },
            "promoCode": this.props.code,
            customerId: get_Id(),

          }),(status)=>{
            this.setState({verifyLoading : false})

            status && funcSave()
          })

        }
      }
    );
  }

  canShowPromoCode_withOrderType = (nextProps) => {
    DetectOndemandOrPickupDelivery(
      nextProps ? nextProps.activeservice ? nextProps.activeservice.orderTypes : [] :  this.props.activeservice ? this.props.activeservice.orderTypes : [],
      (call) => {
        if (call == "ondeman") {
            this.setState({
              canShowPromoCode : true
            })
        }else{
          this.setState({
            canShowPromoCode : false
          })
        }
      }
      )

  }


  render() {
    const { t } = this.props;

    if (!this.props.canShowPromoCode || !this.state.canShowPromoCode) {
      return null
    }
    if(this.props.promoCodeValid){
      return <InputFilter applyLoading={this.state.verifyLoading}  inputError={this.props.error}  closeAfterApply={false} showIcon={false} CustomIcon={<IconProviderSvgModifier Icon={Adddiscount} size="14" />} type={"text"} states={['Contains']} title='Promo' placement='topStart' value={this.props.code} onChange={this.promoCodeOnchange} state={"Contains"} stateOnChange={()=>{}} /> 
    }


    if(!this.props.promoCodeValid){
      return <InputFilter applyLoading={this.state.verifyLoading}  inputError={this.props.error} closeAfterApply={false} showIcon={false} CustomIcon={<IconProviderSvgModifier Icon={Adddiscount} size="14" />} type={"text"} states={['Contains']} title='Promo' placement='topStart' value={this.props.code} onChange={this.promoCodeOnchange} state={"Contains"} stateOnChange={()=>{}} /> 
    }

 
  }
}

const mapStateToProps = (state) => ({
  code: state.NewOrder.newOrder.promotionCode.code,
  valid: state.NewOrder.newOrder.promotionCode.valid,
  error: state.NewOrder.newOrder.promotionCode.error,

  pickup: state.NewOrder.newOrder.pickup,
  otherdropOffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,
  firstDropOff: state.NewOrder.newOrder.dropOffs.firstDropOff,
  activeservice: state.NewOrder.newOrder.service.activeVehicleType,
  service_options: state.NewOrder.newOrder.service.options,
  activeVehicleType: state.NewOrder.newOrder.driver.auto_driver.auto_activeVehicleType,
  VehicleType_options: state.NewOrder.newOrder.driver.options,
  otherdropOffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,

  pickup_adresss_loc: state.NewOrder.newOrder.pickup.adresss_loc,
  dropOffs_adresss_loc: state.NewOrder.newOrder.dropOffs.firstDropOff.adresss_loc,
  canShowPromoCode:
    state.NewOrder.newOrder.service.activeVehicleType &&
    state.NewOrder.newOrder.driver.auto_driver.auto_activeVehicleType &&
    state.NewOrder.newOrder.pickup.adresss_loc.lng != "" &&
    state.NewOrder.newOrder.dropOffs.firstDropOff.adresss_loc.lng != "",
  serviceactiveVehicleType: state.NewOrder.newOrder.service.activeVehicleType,
  promoCodeValid: state.NewOrder.newOrder.promotionCode.valid,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
