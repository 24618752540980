import * as React from "react"
const SupportSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <mask
      id="aSupportSvg"
      width={16}
      height={19}
      x={2}
      y={1}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M15.677 11.342h1.992V9.43c0-4.207-3.055-7.649-7.436-7.649-4.382 0-7.967 3.442-7.967 7.649v1.912h1.991V9.43c0-3.155 2.69-5.736 5.976-5.736s5.444 2.58 5.444 5.736v1.912Zm-.033 7.94h-5.238a1.415 1.415 0 0 1-1.409-1.407c0-.777.638-1.407 1.424-1.407.62 0 1.148.392 1.344.938h3.88l.12-1.304c0-.32.075-.276.246-.18.13.075.313.18.56.18.245 0 .43-.105.559-.18.17-.096.246-.14.246.18l.167 1.304c0 1.032-.854 1.876-1.899 1.876Z"
        clipRule="evenodd"
      />
    </mask>
    <g mask="url(#aSupportSvg)">
      <path fill="url(#bSupportSvg)" d="M2.266 1.781H18.2v18.058H2.266z" />
    </g>
    <path
      fill="url(#cSupportSvg)"
      fillRule="evenodd"
      d="M15.643 10.095h2.125a2.13 2.13 0 0 1 2.125 2.124v2.125a2.13 2.13 0 0 1-2.125 2.124h-2.125v-6.373ZM4.25 16.468H2.125A2.13 2.13 0 0 1 0 14.344v-2.125a2.13 2.13 0 0 1 2.125-2.124h2.124v6.373Z"
      clipRule="evenodd"
    />
    <mask
      id="dSupportSvg"
      width={20}
      height={7}
      x={0}
      y={10}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M15.643 10.095h2.125a2.13 2.13 0 0 1 2.125 2.124v2.125a2.13 2.13 0 0 1-2.125 2.124h-2.125v-6.373ZM4.25 16.468H2.125A2.13 2.13 0 0 1 0 14.344v-2.125a2.13 2.13 0 0 1 2.125-2.124h2.124v6.373Z"
        clipRule="evenodd"
      />
    </mask>
    <g mask="url(#dSupportSvg)">
      <path fill="url(#eSupportSvg)" d="M-.921 9.217h21.245v8.498H-.921z" />
    </g>
    <defs>
      <linearGradient
        id="bSupportSvg"
        x1={2.266}
        x2={2.266}
        y1={1.781}
        y2={19.84}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={global.config.colors.iconLightEnd} />
        <stop offset={1} stopColor={global.config.colors.iconLightEnd} />
      </linearGradient>
      <linearGradient
        id="cSupportSvg"
        x1={0}
        x2={0}
        y1={10.095}
        y2={16.468}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={global.config.colors.iconLightEnd} />
        <stop offset={1} stopColor={global.config.colors.iconLightStart} />
      </linearGradient>
      <linearGradient
        id="eSupportSvg"
        x1={-0.921}
        x2={-0.921}
        y1={9.217}
        y2={17.715}
        gradientUnits="userSpaceOnUse"
      >
       <stop stopColor={global.config.colors.iconDarkStart} />
        <stop offset={1} stopColor={global.config.colors.iconDarkStart}  />
      </linearGradient>
    </defs>
  </svg>
)
export default SupportSvg
