import React from "react";
import clsx from "clsx";
import styles from './style.module.css';
import { ProgressIndicatorProps } from "./ProgressIndicator.types";



const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({ className,style,theme }: ProgressIndicatorProps) => {
    return (<progress className={clsx(styles["pure-material-progress-circular"] ,className && className , theme && styles[theme] )}  style={style ? style : {}}/>);
};

export default ProgressIndicator;

