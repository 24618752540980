import * as React from "react"
const ScheduledOrderTimeSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    fill="none"
    {...props}
    style={{transform:"scale(1.4)"}}
  >
    <path
      fill={global.config.colors.iconDarkEnd}
      fillRule="evenodd"
      d="M.75 8.957V4.488h10.565v4.505c0 1.842-1.16 2.975-3.015 2.975H3.76c-1.839 0-3.01-1.156-3.01-3.011zm2.91-1.444a.482.482 0 0 1-.498-.469c0-.27.209-.493.476-.505a.48.48 0 0 1 .481.47.482.482 0 0 1-.458.504zm2.384 0a.482.482 0 0 1-.499-.469c0-.27.21-.493.476-.505a.48.48 0 0 1 .481.47.482.482 0 0 1-.458.504zm2.366 2.16a.497.497 0 0 1-.481-.499.488.488 0 0 1 .475-.498h.006c.273 0 .493.223.493.498 0 .276-.22.5-.493.5zm-2.865-.499c.012.27.232.482.5.47a.482.482 0 0 0 .457-.505.48.48 0 0 0-.481-.47.502.502 0 0 0-.476.505zm-2.389 0c.012.27.232.482.499.47a.482.482 0 0 0 .458-.505.48.48 0 0 0-.481-.47.502.502 0 0 0-.476.505zm4.778-2.136c0-.27.21-.487.476-.493.26 0 .47.21.481.47a.478.478 0 1 1-.957.03v-.007z"
      clipRule="evenodd"
    />
    <path
      fill="url(#aScheduledOrderTimeSvg)"
      d="M.752 4.488C.76 4.143.79 3.46.843 3.24c.279-1.238 1.224-2.025 2.575-2.137h5.23c1.34.118 2.297.91 2.575 2.137.054.214.083.903.09 1.248H.753z"
    />
    <path
      fill={global.config.colors.iconDarkEnd}
      fillRule="evenodd"
      d="M4.31 2.47a.438.438 0 0 1-.446.453.438.438 0 0 1-.447-.452V.68c0-.259.192-.452.447-.452s.446.193.446.452v1.79zm4.338 0a.442.442 0 0 1-.447.453.438.438 0 0 1-.446-.452V.68c0-.259.191-.452.446-.452.25 0 .447.193.447.452v1.79z"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="aScheduledOrderTimeSvg"
        x1={0.752}
        x2={0.752}
        y1={1.103}
        y2={4.488}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={global.config.colors.iconLightEnd} />
        <stop offset={1} stopColor={global.config.colors.iconLightStart} />
      </linearGradient>
    </defs>
  </svg>
)
export default ScheduledOrderTimeSvg
