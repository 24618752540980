import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Header from "./components/header";
import OrderStatus from "./components/orderStatus";
// import CustomerInformation from "./components/customerInformation";
import Service from "./components/service";
import JustVehicleType from "./components/justVehicleType";
import DriverInformation from "./components/driverInformation";
import Addresses from "./components/addresses";
import Footer from "./components/footer";
import ExtraDetails from "./components/extraDetails";
import loading from "../../../../src/static/loading.gif";
import reload from "../../../../src/static/reload.png";
import PromotionCode from "./components//promotionCode";
import Feedback from "./components//Feedback";
import ChatWithDriver from "./components/chatWithDriver";

import { withTranslation, Trans } from "react-i18next";
import Slide from "@material-ui/core/Slide";
// import { OrderInfoAction } from "../../../Redux/actions/actions";
import { get_id, get_LeftRight } from "../../../../src/helper/UserData/userdate";
import { Api_get_Order_Info_AGW, Api_OrderGetEvents_AGW } from "../../../helper/api/ApiGateway";
import Event_TimeLine from "./components/event_TimeLine";
import { show_eventTimeLine } from "../../../Redux/actions/actionNames";
import styles2 from './components/viewOrder.module.css';
import { HASHTAG } from "../../../static";
import ic_note from "../../../static/ic_note.svg";

import Typography from "../../../design-system/lib/Typography";

class App extends React.Component {
  retryOrderInfo = () => {
    // this.props.dispatch(
    //   OrderInfoAction(this.props.dispatch, {
    //     orderId: this.props.id
    //   })
    // );

    Api_get_Order_Info_AGW(this.props.dispatch, this.props.id)

  };


  eventShowHandler = (bool) => {
    if (bool) {
      Api_OrderGetEvents_AGW(this.props.dispatch, this.props.orderId)
    }
    this.props.dispatch(show_eventTimeLine(bool))

    // if(this.props.showEventTimeLine){
    //   this.props.dispatch(Set_New_Event({canSelectLocation : false}))
    //   this.props.dispatch(Set_New_Event({show : false}));
    //   this.props.dispatch(Set_New_Event({mapLocation : null}))
    //   this.props.dispatch(Set_viewOrder_events({ activeEventCordinator: null }))


    // }
  }


  render() {
    return (

        <div className="NewOrder viewOrder">
          <Header />
          <div
            style={{
              width: "100%",
              height: "calc(100% - 126px)",
              float: "left",
              marginTop: "70px",
              overflowY: "none",
            }}
          >

            {!this.props.loading && !this.props.error &&
              <vRItemsContainer
                value={0}
                index={0}
                dir={String(get_LeftRight()).toLowerCase()}
              >
                <OrderStatus />
                <div className={`${styles2.eventDetailsContainer } eventDetailsContainer`}>
                  <span onClick={() => this.eventShowHandler(true)} className={`${this.props.showEventTimeLine && styles2.activeTab} ${this.props.showEventTimeLine && ' eventDetailsContaineractiveTab'}`}>Events</span>
                  <span onClick={() => this.eventShowHandler(false)} className={`${!this.props.showEventTimeLine && styles2.activeTab} ${!this.props.showEventTimeLine && ' eventDetailsContaineractiveTab'}`}>Details</span>
                </div>

                {this.props.showEventTimeLine ? <Event_TimeLine /> : <>
                  <Service />
                  <JustVehicleType />
                  <DriverInformation />
                  <Addresses />
                 {this.props.note && String(this.props.note).length > 0 &&  <div className={styles2.noteContainer}>
                      <img src={ic_note} />
                      <Typography weight="Body_Middle_Regular" text={this.props.note} />
                  </div>}
                  <ExtraDetails />
                  {/* <PromotionCode /> */}
                </>}
                {/* <orderStatus style={{ marginTop: "12px" }}>
                  <span className={`vrHeaderEventsTitle ${this.props.showEventTimeLine && "vrHeaderEventsTitleActive"}`} onClick={this.eventShowHandler}> <Trans i18nKey={"allEvents"}> </Trans> </span>
                </orderStatus> */}

              </vRItemsContainer>
            }


          </div>


          {!this.props.loading && !this.props.error && <Footer />}

          {this.props.loading && (
            <React.Fragment>
              <div className="NoOrderContainer">
                <div className="NoOrderInner mrb90">
                  <div className="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  <span className="NoOrderTitle">
                    {" "}
                    <Trans i18nKey={"fetchingdata"}> </Trans>
                  </span>
                </div>
              </div>


            </React.Fragment>
          )}

          {!this.props.loading && this.props.error && (
            <React.Fragment>
              <img
                onClick={this.retryOrderInfo}
                src={reload}
                className="errorrELOAD"
              />
              <span className="loading">
                <Trans i18nKey={"orderInfoError"}> </Trans>
              </span>
            </React.Fragment>
          )}

          <Feedback />
          <ChatWithDriver />
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
  customerId: state.ViewTravel.customerId,
  id: state.ViewTravel.ID,
  OpenDialoge: state.ViewTravel.OpenDialoge,
  loading: state.ViewTravel.loading,
  error: state.ViewTravel.error,
  history: state.ViewTravel.history,
  showEventTimeLine: state.ViewTravel.eventTimeLine.show,
  orderId: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.id
      ? state.ViewTravel.pureData.id
      : null
    : null,
    note: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.note
      ? state.ViewTravel.pureData.note
      : null
    : null,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
