import * as React from "react"
const PlusSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} {...props} 
  style={{
    ...props.style,
    backgroundImage: `linear-gradient(to top, ${global.config.colors.iconLightEnd}, ${global.config.colors.iconLightStart})` ,
    color: global.config.colors.secondaryTextColor
  }}
  >
    <path
      fill={global.config.colors.iconDarkStart}
      fillRule="evenodd"
      d="M10.968 22c-.905 0-1.422-.676-1.422-1.613v-7.784H1.638C.69 12.603 0 12.079 0 11.163c0-.937.646-1.504 1.638-1.504h7.908V1.613C9.546.676 10.063 0 10.968 0c.926 0 1.486.632 1.486 1.613v8.046h7.93c.97 0 1.616.567 1.616 1.505 0 .915-.69 1.439-1.616 1.439h-7.93v7.784c0 .959-.56 1.613-1.486 1.613z"
    />
  </svg>
)
export default PlusSvg
