import React from "react";
// import { Marker } from '@react-google-maps/api';
import { Marker, OverlayView } from '@react-google-maps/api';

import { connect } from "react-redux";
import PinPickup from "../../../../components/svg/pin_pickup";
import PinDropOff from "../../../../components/svg/pin_dropoff";
import ReactDOMServer from "react-dom/server";
import DropOffPin from "../../../../components/shared/map-dropoff-pin/Pin";
import DropOffPinWithNumber from "../../../../components/shared/map-dropoff-pin-with-number/Pin";


class App extends React.Component {

  render() {

    let originShow = this.props.OpenVRDialoge && this.props.VRpureData && ["Pickup", "PickupDelivery", "Ondemand"].indexOf(this.props.orderType) > -1;
    let destinationShow = this.props.OpenVRDialoge && this.props.VRpureData && this.props.VRpureData.dropoffs;
    let destination2Show = this.props.OpenVRDialoge && !this.props.OpenERDialoge && this.props.VRpureData && this.props.VRpureData.dropoff;

    return (
      <React.Fragment>
        {originShow ? (
          <OverlayView
          position={{ lat: this.props.VRpureData.pickup.coordinates[1], lng: this.props.VRpureData.pickup.coordinates[0] }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          
        >
          <div style={{width:'50px',height:"50px",marginLeft:"-25px",marginTop:"-50px"}}>
          <PinPickup  />
          </div>
        
        </OverlayView>
        ) : null}

        {destination2Show &&
          (this.props.VRpureData.dropoffs?.length == 0 ? <Marker
            position={{ lat: this.props.VRpureData.dropoff.coordinates[1], lng: this.props.VRpureData.dropoff.coordinates[0] }}
            icon={{
              anchor: { x: 15, y: 50 },
              url: 'data:image/svg+xml;charset=utf-8,' +
                encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<DropOffPin bigSize={true} color={"#0064d2"} />))
            }}
          ></Marker> : <OverlayView
            position={{ lat: this.props.VRpureData.dropoff.coordinates[1], lng: this.props.VRpureData.dropoff.coordinates[0] }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >

            <DropOffPinWithNumber color={"#0064d2"} bigSize={true}
              style={{ marginLeft: "-15px", marginTop: "-51px", marginBottom: "10px" }} number={1} numberStyle={{ position: "absolute", width: "20px", height: "20px", left: "5px", top: "7px", fontSize: "14px", fontWeight: "bold", fontFamily: 'sf_pro', textAlign: "center" }} />

          </OverlayView>)
          // <OverlayView
          //   position={{ lat: this.props.VRpureData.dropoff.coordinates[1], lng: this.props.VRpureData.dropoff.coordinates[0] }}
          //   mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}

        >
          <div style={{ width: '50px', height: "50px", marginLeft: "-25px", marginTop: "-50px" }}>
            <PinDropOff  />
          </div>

          // </OverlayView>
        }

        {destinationShow
          ? this.props.VRpureData.dropoffs.map((dropoff, i) => {
            return (<OverlayView
              position={{ lat: dropoff.coordinates[1], lng: dropoff.coordinates[0] }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
  
            >
              <div style={{ width: '50px', height: "50px", marginLeft: "-25px", marginTop: "-50px" }}>
                <PinDropOff  />
              </div>
  
            </OverlayView>)


          })
          : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  OpenVRDialoge: state.ViewTravel.OpenDialoge,
  VRpureData: state.ViewTravel.pureData,
  orderType: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.type
      ? state.ViewTravel.pureData.type
      : null
    : null,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
