export const PortugueseLabel = {
    "Cutomerpanel": "Painel de clientes",
    "login": "Login",
    "Register": "Cadastre-se",
    "en": "Inglês",
    "fa": "Persa",
    "individual": "Pessoal",
    "Business": "Corporativo",
    "Phonenumber": "Número de telefone",
    "Continue": "Continuar",
    "phonenumberhasnotregisterd": "O número de telefone não foi registrado",
    "Username": "Nome de usuário",
    "title": "Título",
    "Password": "Senha",
    "ExOnro": "Ex. empresa",
    "Exstart": "Ex. ******",
    "googleConnected": "Google conectado",
    "facebookConnected": "Facebook conectado",
    "appleConnected": "Apple ID conectado",
    "plsenterphonnumber": "Por favor digite o seu número de telefone",
    "firstnnameloginplaceholder": "Ex. Nome",
    "businessNamePlaceholder": "Ex. ABC Company",
    "registerBusinessNameLabel":"Business Name",
    "lastnameloginplaceholder": "Ex. Sobrenome",
    "Firstname": "Primeiro nome",
    "Lastname": "Sobrenome",
    "email": "E-mail",
    "emailPlaceholder": "(opcional) Ex. example@example.com.br",
    "comingsoon": "Em breve:",
    comingsoondesc: "Para registrar sua empresa, clique aqui",
    "comingsoondesc2": "Para criar uma conta corporativa, por favor fale conosco",

    contactUs: "Contate-nos",
    
    "CustomerAlreadyRegistered": "Você já possui cadastro, por favor acesse a plataforma",
    "registerCompleted": "Digite o Código de Verificação",
    "googletokenerror": "Problema com o Google Token",
    "facebooktokenerror": "Problema com o Token do Facebook",
    "appletokenerror": "Problema com o Token da Apple",
    "CustomerNotRegistered": "Cliente Não Registrado",
    "fr": "francês",
    "de": "Alemanha",
    "InvalidVerificationCode": "Código de Verificação Inválido",
    "a": "As suas informações não puderam ser encontradas",
    "welcome": "bem-vindo",
    "SmsLimitationError": "Por favor tente novamente dentro de alguns momentos",
    "resendCodeWithTime": "reenviar código (xxx)",
    "resendCode": "reenviar código",
    "VerificationcodePlaceHolder": "Ex. 1234",
    "Verificationcode": "Código de verificação",
    "VerificationcodeText": "Digite o código enviado para xxx",
    "VerificationCodeExpired": "Código de Verificação Expirado",
    "Pending": "Pendente",
    "Unassigned": "Não atribuído",
    "Assigned": "Em curso",
    "PickedUp": "Em curso",
    "Started": "Em curso",
    "statusInProgress": "Em curso",
    "Done": "Feito",
    "CustomerCanceled": "Cancelado",
    "DriverCanceled": "Cancelado",
    "SupportCanceled": "Cancelado",
    "Arrived": "Em curso",
    "new": "novo",
    "Historyofyourorderswillbehere": "O histórico dos seus pedidos estarão aqui",
    "otherdropoffs": "outras entregas",
    "Orderhistory": "Histórico de pedidos",
    "Noorder": "Nenhum pedido",
    "loadingList": "Por favor aguarde enquanto processamos a informação",
    "loadingError": "Problema no processamento da informação",
    "retry": "Tente novamente",
    "Create_your_first_requst": "Crie um novo pedido",
    "List_of_you_active_orders_will_be_here": "A lista dos seus pedidos ativos estarão aqui",
    "Aboutus": "Sobre nós",
    "Readour": "Leia o nosso",
    "privaypolicy": "política de privacidade",
    "Transactions": "Transações",
    "NoTransaction": "Não tem uma transação",
    "Historyofyourransactionswillbehere": "O histórico das suas transações estarão aqui",
    "Settings": "Ajustes",
    "Language": "Idioma",
    "English": "Inglês",
    "Persian": "Persa",
    "French": "Francês",
    "Logout": "Sair",
    "logotTitle": "Sair da conta?",
    "logoutDescription": "É necessário refazer o login novamente",
    "confirmLogout": "Sim, sair",
    "skipLogout": "Cancelar",
    "savesettings": "Salvar definições",
    "emailInvalid": "o e-mail é inválido",
    "Neworder": "Novo pedido",
    "Service": "Serviço",
    "poperLoading": "por favor aguarde",
    "Pickup": "Coleta",
    "SetPickup": "Fixar coleta",
    "ChooseoOnMap": "Escolher no mapa",
    "Fornow": "Para agora",
    "Scheduled": "Agendado",
    "before": "Antes de :",
    "after": "Depois :",
    "confirm": "Confirmar",
    "Settime": "Tempo definido",
    "fo": "Fo",
    "Cancel": "Cancelar",
    "Pleasetryanotherkeyword": "Por favor, tente outra senha",
    "Noinformationfound": "Nenhuma informação encontrada",
    "address": "Endereço",
    "block": "Complemento",
    "Floor": "Piso",
    "Unit": "Unidade",
    "senderfullname": "Nome do solicitante",
    "senderphonenumber": "Número do solicitante",
    "notesfordriver": "Notas ao entregador",
    "DropOff": "Entregar",
    "SetDropOff": "Fixar entrega",
    "fetchingdata": "Recebendo informação...",
    "Recieverfullname": "Nome do destinatário",
    "Recieverphonenumber": "Número do destinatário",
    "undefined": "Tipo de serviço",
    "PromotionCode": "Código promocional",
    "Delete": "Excluir",
    "Save": "Salvar",
    "PromoCodeValid": "Código Promocional salvo",
    "SenderWallet": "Carteira",
    "SenderCash": "Dinheiro",
    "ReceiverCash": "Pagar por entregador",
    "lowBalance": "Saldo baixo",
    "Balance": "carteira",
    "Methods": "Métodos",
    "balanceError": "O seu saldo deve ser pelo menos igual ao preço do pedido",
    "SendOrder": "Enviar pedido",
    "feedback": "Feedback",
    "Feedback": "Feedback",
    "Orderagain": "Pedir novamente",
    "Createdsuccessfully": "Criado com sucesso",
    "chooseAmount": "Escolher valor",
    "orenterthepreferedamount": "Valor preferido:",
    "enteramount": "o seu preço desejado",
    "pay": "Pagar",
    "selectPaymentGateway": "Selecionar a forma de pagamento",
    "Time_MachineText": "Deseja recuperar as suas informações do pedido?",
    "yes": "Sim",
    "ignore": "ignorar",
    "Order": "Pedido",
    "statusPending": "Pendente",
    "statusUnassigned": "Não atribuído",
    "statusAssigned": "Alocado",
    "statusStarted": "Iniciado em",
    "statusPickedUp": "Coletou",
    "statusDone": "OK",
    "statusCustomerCanceled": "Cliente Cancelado",
    "statusDriverCanceled": "Entregador Cancelado",
    "statusArrived": "Chegou a",
    "statusSupportCanceled": "Suporte Cancelado",
    "statusCanceled": "Cancelado",
    "Phone": "Telefone",
    "additonalservice": "serviço adicional",
    "CallDriver": "Ligação ao entregador",
    "Message": "Mensagem",
    "orderInfoError": "Erro ao receber informação",
    "support": "Suporte",
    "map": "Mapa",
    "receipt": "Recibo",
    "photo": "foto",
    "ServicePhoto": "Foto de serviço",
    "Addphoto": "Adicionar foto",
    "Estime": "Tempo Es.",
    "Espath": "Rota Es.",
    "pod": "Comprovante de entrega",
    "Notes": "Notas",
    "Photo": "Foto",
    "Signature": "Assinatura",
    "Nonotes": "Sem notas",
    "Nosignature": "Sem assinatura",
    "Nophoto": "Sem foto",
    "Price": "Preço",
    "Receiver": "Por Destinatário",
    "Sender": "Por Solicitante",
    "Cash": "Dinheiro",
    "Canceltravel": "Cancelar requisição",
    "CancellText": "O pedido será cancelado e retirado do entregador",
    "Estimated": "Estimado",
    "Wallet": "Saldo",
    "Copylink": "Link para cópia",
    "repeatOrder": "Repetir o pedido",
    "Edit": "Editar",
    "confirmCancel": "Sim",
    "paymentmethod": "Forma de pagamento",
    "SucceessFul": "feito com sucesso",
    "errorinServer": "Problema do servidor",
    "Comment": "Comentário",
    "Exfeedbackinput": "Ex. Perfeito, muito rápido!",
    "Wallett": "Carteira",
    "TypeHere": "Escreva aqui",
    "Loadingmessages": "Download de mensagens",
    "PoweredBy": "Powered By",
    "newincomemessage": "Você recebeu uma nova mensagem",
    "newincomemessageanother": "Tem uma nova mensagem em outro pedido",
    "youhaveunreadmessage": "Mensagem não lida",
    "Vehicletype": "Tipo de veículo",
    "Pickup2": "Coleta",
    "Dropoff2": "Entrega",
    "Events": "Eventos",
    "allEvents": "Todos os eventos",
    "star": "estrela",
    "Yourrate": "A sua nota",
    "CopyOrderlink": "Link do pedido",
    "CopyPickuplink": "Link da coleta",
    "CopyDeliverylink": "Link da entrega",
    "Nointernetconnection": "Sem conexão",
    "close": "fechar",
    "seen": "Visto",
    "controlThatbeforeLoweEqualAfter": "Tempo \"Antes :\" não pode ser inferior a \"Depois :\"",
    "controlThatbeforeAndAfterMustBiggerthanCurrentDate": "Os tempos \"antes:\" e \"depois:\" não podem ser inferiores do que o tempo atual",
    "errorInPanelSetting": "Erro nas definições de reserva, por favor contate o suporte",
    "Arabic": "árabe",
    "ar": "árabe",
    "veeDeliveryLoginText1": "Enviar material",
    "veeDeliveryLoginText2": "A qualquer pessoa, urgente!",
    "Schedule": "Horário",
    "Date": "Data",
    "Time": "Hora",
    "noDateToShow": "Não há data a mostrar",
    "noTimeToShow": "Não há tempo para exibir",
    "Apply": "Aplicar",
    "Increaseyourbalance": "Aumente o seu saldo",
    "AddBalance": "Adicionar saldo",
    "Addressbook": "Endereços salvos",
    "AddressbookSearchbar": "Pesquisa por endereço, título e código",
    "Removedefault": "Pesquisa por endereço, título e código",
    "Setdefaultpickup": "Definir a coleta por padrão",
    "Remove": "Remover",
    "newAddress": "Novo endereço",
    "NewAddressbookInfoTitle": "Informação",
    "Addressdetails": "Detalhes do endereço",
    "Title": "Título",
    "Code": "Código",
    "editAddress": "Editar Endereço",
    "AddressbookDropOf": "Endereços salvos (entrega)",
    "AddressbookPickup": "Endereços salvos (coleta)",
    "hide": "Ocultar",
    "Nosavedaddress": "Nenhum endereço salvo",
    "NosavedaddressDesc": "Clicando no ícone ao lado do endereço ou nos endereços favoritos, é possível criar um pedido mais rápido!",
    "Romanian": "Romeno",
    "ro": "Romeno",
    "Dutch": "Holandês",
    "Shipmentlabel": "Etiqueta da remessa",
    "Name": "Nome",
    "CashOnDelivery": "Pagar por dinheiro",
    "EnterAmount": "Digite o valor",
    "COD": "Pagar por dinheiro",
    "Surchargexxx": "Taxa adicional xxx",
    "Pricedetails": "Detalhes do preço",
    "minxxx": "xxx min",
    "toPickup": "Para coletar",
    "toDropOff": "Para entregar",
    "AddCreditCard": "Adicionar Cartão de Crédito/Débito",
    "CustomerWallet": "Carteira do cliente",
    "Add": "Acrescentar",
    "Addedsuccessfully": "Adicionado com sucesso",
    "paymentmethods": "Formas de pagamento",
    "NoCards": "Sem Cartões",
    "Listofyourcardswillbehere": "A lista dos seus cartões estarão aqui",
    "CardInfo": "Número do cartão",
    "ExpireDate": "Data de expiração",
    "CVC": "CVC",
    "PostalCode": "CEP",
    "RemoveCardq": "Excluir cartão?",
    "RemoveCardA": "O cartão será removido e não poderá mais ser utilizado",
    "YesRemove": "Sim, remover",
    "Cardisinuse": "O cartão está em uso",
    "ok": "Ok",
    "Pleasetypeyourwordstosearch": "Por favor escreva a sua senha para pesquisar",
    "spanish": "Espanhol",
    "es": "Espanhol",
    "Invoices": "Faturas",
    "noInvoice": "No. xxx",
    "germany": "Alemanha" ,

    Support:"Apoio, suporte",
    SendRequest:"Enviar pedido",
    CallTheSupport:"Chame o suporte",
    Choosethesubject :"Escolha o assunto",
    Seefrequentquestions:"Veja perguntas frequentes",
    Details:"Detalhes",
    Send:"Mandar" ,
    MessageBox:"Caixa de mensagem",
    NoMessages:"Sem mensagens",
    Yourmessageswillbehere:"Suas mensagens estarão aqui",
    Portuguese:"Português",
    pt:"Português",
    Required:"Obrigatória",
    changePassword : "Alterar a senha",
    changePasswordp1 : "Você será eliminado de todas as sessões, exceto esta para proteger sua conta que alguém está tentando obter acesso",
    changePasswordp2 : "Sua senha deve ter pelo menos 6 caracteres",
    currentPassword:"Senha atual",
    newpassword:"Nova Senha",
    Retypenewpassword:"Retype nova senha",
    account: "Conta (carteira)",
    "Greek":"Grega",
    "el":"Grega",
    "registerBussinessUsername":"Nome do usuário (pelo menos 5 caracteres)",
    "registerBussinessPassword":"Senha (pelo menos 6 caracteres)",
    "ReferenceID":"referência ID",
    "EnterId":"Fornecer um documento de identidade",
    "Orderid": "ID do pedido",
    "EnterCode":"Coloque o código",
    "AustrianGerman":"Alemão austríaco",
    "deu":"Alemão austríaco",
    "pop":"Prova de coleta",
    "Options":"Opções",
    "RequiredText":"Obrigatória",
    "PleaseSelect":"Por favor selecione",
    "Optimizedropoffs":"Otimize as quedas",
    "Optimizedropoffs?":"Otimizar as quedas?",
    "OptimizedropoffsDesc":"A sequência de queda será alterada para sequência otimizada e pode mudar o preço",
    "Optimize":"Otimizar"
    ,
    "Bulkimport":"Importação em massa",
    "processing":"Em processamento" ,
     "done":"Finalizada" ,
     "failed":"Fracassada",
     "Draft":"Rascunho" ,
     "Uploading":"Enviando" ,
     "Draganddropyourfile":"Arraste e solte seu arquivo",
     "Onlycsvformatissupported":"Apenas o formato .csv é suportado" ,
     "ORyoucan":"Ou você pode",
     "Openfile":"Abrir arquivo" ,
     "Noimportsyet":"Sem importações ainda" ,
     "NoimportsyetDesc":"Você pode ver a lista de suas importações aqui e verificar o status deles",
     "nosupportdialogeTitle":"Formato de arquivo não suportado",
     "nosupportdialogeText":"O arquivo xxx não é suportado.Você só pode fazer upload do arquivo .csv, incluindo informações do pedido" ,
     "Import":"Importar",
     "previewTitle":"Visualizar e escolher opções",
     "previewDesc":"Este é o primeiro item de seus arquivos para verificar suas informações está na estrutura certa" ,
     "Upload":"Carregar", 
     "Remove":"Remover" ,
     "ServiceandOrdertype":"Tipo de serviço e pedido" ,
     "Fileisproccessing":"O arquivo está processando ...",
     "proccessingDesc":"Você pode atualizar para ver se o resultado está pronto",
     "serverError":"erro de servidor",
     "isRequired":"XXX é necessário" ,
     "Result":"Resultado",
     "ResultText":"Você pode ver os problemas aqui e baixar essas linhas para corrigir separadamente",
     "ErrorDetails":"Pedidos com erro",
     "ErrorDetailsText":"Os pedidos com erros estão listados no arquivo que você pode baixar abaixo.Ele contém detalhes de erro para cada linha.",
     "DownloadErroredRows":"Download linhas erradas" ,
     "importedsuccessfully":"Os pedidos xxx são importados com sucesso" ,
     "ordershaserrors":"XXX Ordens tem erros",
     "refresh":"Atualizar",
     "pleaseSellectParsel":"Selecione o serviço",
     "Ondemand":"Sob demanda",
     "PickupandDelivery":"Cathop e entrega",
     "PickupDelivery":"Cathop e entrega",
     "Delivery":"Entrega",
     "Pickup":"Escolher",
     "tab_Delivery":"Entrega",
     "tab_Ondemand":"Sob demanda",
     "tab_Pickup":"Escolher",
     "tab_PickupDelivery":"P&D",
     "Downloadsamplecsv":"Download de amostra CSV" ,
     "driverPhoto":"Driver Photo",
     "yourPhoto":"Your Photo",
     "UploadPhoto":"Upload Photo",
     "Photos":"Photos",
   
   
   
     "TrackLink":"Track Link",
     "ShipmentLabel":"Shipment Label",
     "Draft":"Draft",
     "Receipt":"Receipt",
     "Confirm":"Confirm",
     "Cancel":"Cancel",
     "BulkEditStatusSummary":"Bulk Edit Status Summary",
     "Successful":"Successful",
     "Failed":"Failed",
     "OrderID":"Order ID",
     "Details":"Details",
     "Result":"Result",
     "New":"New",
     "Import":"Import",
     "Apply":"Apply",
     "Clear":"Clear",
     "Noresultfound":"No result found",
     "Typeyourkeyword":"Type your keyword",
     "Typeintheinputtostartsearch":"Type in the input to start search",
     "OnDemand":"On Demand",
     "P_Hub_D":"P_Hub_D",
     "AddressDetails":"Address Details",
     "Map":"Map",
     "Support":"Support",
     "BulkEditStatusxxx/yyy":"Bulk Edit Status xxx/yyy",
     "xxxSuccessfull,yyyFailed":"xxx Successfull, yyy Failed",
     "xxxfromyyy":"xxx from yyy",
     "xxxrows":"xxx rows",
     "xxxselected":"xxx selected",
     "xxxdropoffs":"+xxx dropoffs",
     "Status Updated at xxx":"Status Updated at xxx",
     'More':'More',
     'Summary':'Summary',
     'Prev':'Prev',
     'Next':'Next',
     'Done':'Done',
     "ClearAll":"Clear All",
     'Errorinloadingdata':'Error in loading data',
     'Therewasaproblemloadingdatapleasetryagainorcontactsupport':'There was a problem loading data. please try again or contact support',
     'Tryagain':'Try again',
     'ConfigTable' :'Config Table' ,
     'ActiveColumns':'Active Columns',
     'Createyourorder':'Create your order',
     'Yourorderswillbeshownhere':'Your orders will be shown here',
     'Pleasecheckyourfiltersorkeyword':'Please check your filters or keyword',
   "Scheduleoverlap":"The Schedule Pickup time must be before the Schedule Delivery time and have no overlap",
     'CustomerPhoto':'Customer Photo',
     "NoAttachments":"No Attachments",
     "AllAttachmentswillbeshownhere":"All Attachments will be shown here",


     "SearchAddress":"Search Address",
     "NoResultfromLocalDatabaseD": "Please check it again or try another keyword",
     "NoResultfromMapService": "No Result from Map Service",
     "Senderinfo": "Sender info",
     "Receiverinfo": "Receiver info",
     "SchedulePickup": "Schedule Pickup ",
     "ScheduleDelivery": "Schedule Delivery",
     "Fullname": "Full name",
     "addressDetail":"Address Detail",
     "Addtoaddressbook":"Add to addressbook",
     "ExHome":"Ex. Home" ,
     "Ex123":"Ex. 123",
     "Savechanges": "Save changes",
     "Removefromaddressbook":"Remove from addressbook?",
     "RemovefromaddressbookDesc":"This address with entered details will be address to your address book.",
     "Clearfield":"Clear field",
     "CreateOrder":"Create Order"
};
