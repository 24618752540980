import React from "react";
import clsx from "clsx";
import styles from './style.module.css';
import colors from '../colors/colors.module.css';
import { TypographyProps } from "./Typography.types";


const Typography: React.FC<TypographyProps> = ({ text, style, weight = "Heading_Small_Bold", className, ref, textColor, backgroundColor , onClick ,...rest }: TypographyProps) => {
    return (
        <span onClick={onClick && onClick} ref={ref} className={clsx(styles[weight], 
        className && className, textColor && colors[`${textColor}Text`], 
        backgroundColor && colors[`${backgroundColor}Bg`])} style={style ? style : {}} {...rest}>
            {text}
        </span>

    );
};

export default Typography;

