import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import Slide from "@material-ui/core/Slide";
import { get_Id, get_LeftRight } from "../../../../helper/UserData/userdate";
import close from "../../../../static/close.svg";
import { EditAddressbookInList, SetDetailsEdit, Set_latlng } from "../../../../Redux/actions/actionNames";
import { Api_Edit_Addressbook_AGW } from "../../../../helper/api/ApiGateway";
import { SucceessFul_i18 } from "../../../../helper/Notification/sendNotification";
import PhoneInput from "react-phone-number-input";
import { getCountryWithDomain } from "../../../../helper/module";
import AddressInputEdit from "./addressInputEdit";


var _ = require("lodash");

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  showPoper = () => {
    this.setState({ showpoper: true });
  };

  closeEditAddressbook = () => {
    this.props.onClose()
  };

  fieldsOnChange = (key, value) => {
    this.props.dispatch(SetDetailsEdit({ [key]: value }));
  }


  OnEdit = () => {
    if (this.props.title.length != 0 && (String(this.props.email).length> 0 ? this.emailValidate(this.props.email) : true)) {
      Api_Edit_Addressbook_AGW(this.props.dispatch, {
        id: this.props.id,
        pure: {
          "title": this.props.title,
          "address": this.props.address,
          "fullName": this.props.fullName,
          "phone": this.props.phone,
          "floor": this.props.floor,
          "room": this.props.room,
          email: this.props.email,
          "buildingBlock": this.props.buildingBlock,
          description: this.props.description,
          coordinates: this.props.coordinates,
        }
      }, () => {

        this.props.dispatch(EditAddressbookInList({
          id: this.props.id,
          pure: {
            "customerId": get_Id(),
            "title": this.props.title,
            "address": this.props.address,
            "fullName": this.props.fullName,
            "phone": this.props.phone,
            "floor": this.props.floor,
            "room": this.props.room,
            email: this.props.email,
            "buildingBlock": this.props.buildingBlock,
            description: this.props.description,
            coordinates: this.props.coordinates,

          }
        }))
        this.props.onClose()
        SucceessFul_i18(
          "SucceessFul",
          "networkError",
          "close",
          () => {
            this.props.dispatch({
              type: "removeNetworkErrorSnack",
              key: "networkError",
            });
          },
          new Date().getTime() + Math.random()
        );
      })
    }
  }



  componentWillReceiveProps(nextProps){
    if(nextProps.coordinates != this.props.coordinates && nextProps.coordinates ){
      this.props.dispatch(
      Set_latlng({
        lng: nextProps.coordinates[0],
        lat: nextProps.coordinates[1],
      })
    );
    }
  }

  emailValidate(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  componentDidMount(){
    // this.props.dispatch(
    //   Set_latlng({
    //     lng: this.props.coordinates[0],
    //     lat: this.props.coordinates[1],
    //   })
    // );
  }


  render() {
    const { t } = this.props;

    return (
      <Slide
        timeout={650}
        direction={get_LeftRight()}
        in={this.props.open}
        mountOnEnter
        unmountOnExit
      >
        <div className="NewAddressbookConatiner">
          <nRHeader style={{ boxShadow: "none" }}>
            <img
              onClick={() => this.closeEditAddressbook()}
              className="close"
              src={close}
            />
            <span className="title">
              <Trans i18nKey={"editAddress"}> </Trans>
            </span>
          </nRHeader>

          <span className="NewAddressbookInfoTitle">
            <Trans i18nKey={"NewAddressbookInfoTitle"}> </Trans>
          </span>

          <input placeholder={t("Title")} className="NewAddressbookInfoTitleField" value={this.props.title} onChange={(e) => { this.fieldsOnChange("title", e.target.value) }} />
          {String(this.props.code).length > 0 && <input
            placeholder={t("Code")}
            className="NewAddressbookInfoCodeField"
            disabled
            style={{ cursor: "not-allowed", color: "#808080" }}
            value={this.props.code} onChange={(e) => { this.fieldsOnChange("code", e.target.value) }}
          />}

          <hr className="NewAddressbookInfohr" />

          <span className="NewAddressbookAddressdetailsTitle">
            <Trans i18nKey={"Addressdetails"}> </Trans>
          </span>

          {/* <input
            onChange={(e) => { this.fieldsOnChange("address", e.target.value) }}
            className="NewAddressbookAddressdetailsAddress"
            placeholder={t("address")}
            value={this.props.address}
          /> */}

          <AddressInputEdit />  

          <div className="NewAddressbookAddressdetailsFieldsContainer pickupcollapsecontainer">
            <input className="blockPickup" placeholder={t("buildingBlock")} value={this.props.buildingBlock} onChange={(e) => { this.fieldsOnChange("buildingBlock", e.target.value) }} />
            <input className="floorPickup" placeholder={t("floor")} value={this.props.floor} onChange={(e) => { this.fieldsOnChange("floor", e.target.value) }} />
            <input className="UnitPickup" placeholder={t("room")} value={this.props.room} onChange={(e) => { this.fieldsOnChange("room", e.target.value) }} />
            <input
              className="senderfullnamePickup"
              placeholder={t("senderfullname")}
              value={this.props.fullName}
              onChange={(e) => { this.fieldsOnChange("fullName", e.target.value) }}
            />

            {/* <PhoneInput
              defaultCountry={global.config.Country}
              placeholder={t("senderphonenumber")}
              value={this.props.phone}
              onChange={(phone) => { this.fieldsOnChange("phone", phone) }}
              id="senderPhoneNumber"
              className="senderphonenumberPickup"
            /> */}

             <input
              className="senderphonenumberPickup"
              placeholder={t("senderphonenumber")}
              value={this.props.phone}
              onChange={(e)=>{this.fieldsOnChange("phone",e.target.value)}}

            />  

          </div>
          <input
            className="NewAddressbookAddressdetailsAddress"
            placeholder={t("email")}
            value={this.props.email}

            style={(String(this.props.email).length> 0 ? this.emailValidate(this.props.email) : true)?{}:{borderColor:"red"}}
            onChange={(e) => { this.fieldsOnChange("email", e.target.value) }}

          />
          <input
            onChange={(e) => { this.fieldsOnChange("description", e.target.value) }}
            className="NewAddressbookAddressdetailsAddress"
            placeholder={t("notesfordriver")}
            value={this.props.description}
          />

          <div className="NewAddressbookFooter">
            <span
              onClick={this.OnEdit}
              className={`SettingFooterButton ${((this.props.title.length == 0) || (String(this.props.email).length> 0 ? !this.emailValidate(this.props.email) : false)) && "DeactiveSettingFooterButton"

                }`}
            >
              <Trans i18nKey={"save"}></Trans>
            </span>
          </div>
        </div>
      </Slide>
    );
  }
}

const mapStateToProps = (state) => ({
  title: state.Addressbook.EditAddressBookInMenuDetails.title,
  id: state.Addressbook.EditAddressBookInMenuDetails.id,
  address: state.Addressbook.EditAddressBookInMenuDetails.address,
  adresss_loc: state.Addressbook.EditAddressBookInMenuDetails.adresss_loc,
  buildingBlock: state.Addressbook.EditAddressBookInMenuDetails.buildingBlock,
  email: state.Addressbook.EditAddressBookInMenuDetails.email,
  floor: state.Addressbook.EditAddressBookInMenuDetails.floor,
  room: state.Addressbook.EditAddressBookInMenuDetails.room,
  fullName: state.Addressbook.EditAddressBookInMenuDetails.fullName,
  phone: state.Addressbook.EditAddressBookInMenuDetails.phone,
  code: state.Addressbook.EditAddressBookInMenuDetails.code,
  description: state.Addressbook.EditAddressBookInMenuDetails.description,
  coordinates: state.Addressbook.EditAddressBookInMenuDetails.coordinates,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
