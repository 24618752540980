import * as React from "react"
const AddressbookItemEmailSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    {...props}
  >
    <mask
      id="aAddressbookItemEmailSvg"
      width={14}
      height={10}
      x={0}
      y={4}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path
        fill="#130F26"
        d="M14 9.632a3.484 3.484 0 0 1-3.472 3.493H3.68a3.484 3.484 0 0 1-3.487-3.48V9.64s.005-3.056.01-4.592c0-.289.332-.45.558-.27A749.818 749.818 0 0 1 5.37 8.48a2.77 2.77 0 0 0 3.493-.008c.036-.024 2.904-2.325 4.57-3.648a.345.345 0 0 1 .559.27C14 6.618 14 9.631 14 9.631"
      />
    </mask>
    <g mask="url(#aAddressbookItemEmailSvg)">
      <path fill="url(#bAddressbookItemEmailSvg)" d="M-1.188 4.841H15.38v9.319H-1.188z" />
    </g>
    <mask
      id="cAddressbookItemEmailSvg"
      width={14}
      height={8}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path
        fill="#130F26"
        d="M13.639 2.545a3.476 3.476 0 0 0-3.07-1.846H3.68c-1.294 0-2.47.719-3.068 1.846a.596.596 0 0 0 .152.744l5.126 4.1c.359.29.794.434 1.229.434h.014c.434 0 .87-.144 1.228-.434l5.126-4.1a.596.596 0 0 0 .153-.744"
      />
    </mask>
    <g mask="url(#cAddressbookItemEmailSvg)">
      <path fill="url(#dAddressbookItemEmailSvg)" d="M-1.186-.337h16.568v9.319H-1.186z" />
    </g>
    <defs>
      <linearGradient
        id="bAddressbookItemEmailSvg"
        x1={-1.188}
        x2={-1.188}
        y1={4.841}
        y2={14.16}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={global.config.colors.iconLightEnd} />
        <stop offset={1} stopColor={global.config.colors.iconLightStart} />
      </linearGradient>
      <linearGradient
        id="dAddressbookItemEmailSvg"
        x1={-1.186}
        x2={-1.186}
        y1={-0.337}
        y2={8.983}
        gradientUnits="userSpaceOnUse"
      >
      <stop stopColor={global.config.colors.iconDarkEnd} />
        <stop offset={1} stopColor={global.config.colors.iconDarkStart} />
      </linearGradient>
    </defs>
  </svg>
)
export default AddressbookItemEmailSvg
