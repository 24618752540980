import * as actionTypes from "../actions/actionTypes";

let sampleDropOff = {
  addressDetails:"",
  address: "",
  adresss_loc: { lng: "", lat: "" },
  block: "",
  floor: "",
  unit: "",
  recieverFullName: "",
  email:"",
  recieverPhoneNumber: "",
  scheduleDateBefore: null,
  scheduleDateAfter: null,
};

const init = {
  OpenDialoge: false,
  list: [],
  newOrder: {
    codAmount:null,
    referenceID:"",
    notes:"",
    price: {
      loading: false,
      price: null,
      done: false,
    },
    paymentMethod: {
      paymentMethod: null,
      paymentSide: null,
      reorder: false
    },
    sendOrder: {
      canSaveOrder: false,
      loading: false,
    },
    suggestListPickUpDropOffLoading: false,
    suggestListPickUpDropOffOrdered: false,
    suggestPickUpDropOffListData: [],
    service: {
      serviceVehicleTypes: [],
      activeVehicleType: null,
      loading: false,
      options: [],
    },
    driver: {
      options: [],
      auto_driver: {
        auto_activeVehicleType: null,
        auto_suggestListLoading: true,
        auto_suggestListData: [],
        auto_suggestListOrdered: false,
      },
    },
    activePickupDropOff: {
      type: null,
      id: "",
    },
    pickup: {
      addressDetails:"",
      address: "",
      placeId: "",
      adresss_loc: { lng: "", lat: "" },
      block: "",
      floor: "",
      unit: "",
      senderFullName: "",
      senderPhoneNumber: "",
      noteForDriver: "",
      email:"",
      list: [],
      scheduleDateBefore: null,
      scheduleDateAfter: null,
      loading: false,
    },
    dropOffs: {
      firstDropOff: {
        id:"d123",
        addressDetails:"",
        address: "",
        adresss_loc: { lng: "", lat: "" },
        block: "",
        floor: "",
        unit: "",
        recieverFullName: "",
        recieverPhoneNumber: "",
        noteForDriver: "",
        email:"",
        scheduleDateBefore: null,
        scheduleDateAfter: null,
      },
      otherDropOffs: [],
      list: [],
      loading: false,
    },
    promotionCode: {
      code: "",
      valid: false,
      data: null,
    },
    map_Data: {
      showMap: false,
      pickupDropOff: {
        type: null,
        id: null,
      },
      currentAddress: "",
    },
    balance: {
      balance: 0,
      currencyCode: null,
      minimumBalance:0
    },
  },
};
export const NewOrder = (state = init, action) => {
  switch (action.type) {
    case actionTypes.setNotesValue:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          notes : action.state
        },
      };
    case actionTypes.setCodAmount:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          codAmount : action.state
        },
      };
      case actionTypes.setReferenceID:
        return {
          ...state,
          newOrder: {
            ...state.newOrder,
            referenceID : action.state
          },
        };
  

    case actionTypes.setCustomerBalance:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          balance: {
            ...state.newOrder.balance,
            ...action.state,
          },
        },
      };
    case actionTypes.setcansavetravel:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          sendOrder: {
            ...state.newOrder.sendOrder,
            ...action.state,
          },
        },
      };

    case actionTypes.OPENCLOSENEWTRAVEL:
      return {
        ...state,
        OpenDialoge: !state.OpenDialoge,
      };

    case actionTypes.chooseoOnMapimg:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          map_Data: {
            ...state.newOrder.map_Data,
            ...action.state,
          },
        },
      };

    case actionTypes.set_promocode:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          promotionCode: {
            ...state.newOrder.promotionCode,
            ...action.state,
          },
        },
      };

    case actionTypes.setpriceneworder:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          price: {
            ...state.newOrder.price,
            ...action.state,
          },
        },
      };

    case actionTypes.set_service_VehicleTypes:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          service: {
            ...state.newOrder.service,
            serviceVehicleTypes: action.state,
            activeVehicleType: state.newOrder.service.activeVehicleType
              ? state.newOrder.service.activeVehicleType
              : action.state[0]
              ? action.state[0]
              : null,
            loading: false,
            options:
              state.newOrder.service.options.length > 0
                ? state.newOrder.service.options
                : [],
          },
          paymentMethod:  state.newOrder.paymentMethod.reorder ?  state.newOrder.paymentMethod : state.newOrder.paymentMethod
          ,
          // paymentMethod:  state.newOrder.paymentMethod.reorder ?  state.newOrder.paymentMethod : action.state[0]
          //   ? action.state[0].setting.isCashBySenderActive
          //     ? { paymentSide: "Sender", paymentMethod: "Cash" }
          //     : {
          //         paymentMethod: "Wallet",
          //         paymentSide: "Sender",
          //       }
          //   : {
          //       paymentMethod: "Wallet",
          //       paymentSide: "Sender",
          //     },
        },
      };
    case actionTypes.set_service_activeVehicleType:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          service: {
            ...state.newOrder.service,
            activeVehicleType: action.state,
            options: [],
          },
          paymentMethod:state.newOrder.paymentMethod.reorder ?  state.newOrder.paymentMethod : state.newOrder.paymentMethod
          // ? action.state.setting.isCashBySenderActive
          //   ? { paymentSide: "Sender", paymentMethod: "Cash" }
          //   : {
          //       paymentMethod: "Wallet",
          //       paymentSide: "Sender",
          //     }
          // : {
          //     paymentMethod: "Wallet",
          //     paymentSide: "Sender",
          //   },
        },
      };

    case actionTypes.set_service_VehicleTypes_RepeatData:
      let service = null;
      state.newOrder.service.serviceVehicleTypes.map((item) => {
        if (item.id == action.serviceId) {
          service = item;
        }
      });

      if (service) {
        return {
          ...state,
          newOrder: {
            ...state.newOrder,
            service: {
              ...state.newOrder.service,
              activeVehicleType: service,
              options: action.options.map((item) => {
                return {
                  id: item.id,
                  type: item.type,
                  item: {
                    id: item.dataId,
                    title: item.dataTitle,
                  },
                };
              }),
            },
          },
        };
      } else {
        return {
          ...state,
        };
      }

    case actionTypes.add_service_option:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          service: {
            ...state.newOrder.service,
            options: action.state.update ? state.newOrder.service.options.map((item) => {
              if(item.id == action.state.id){
                return {
                  ...item ,
                  inputValue : action.state.inputValue
                };
              }else{
                return item
              }

            }) : [...state.newOrder.service.options, action.state],
          },
        },
      };
    case actionTypes.remove_service_option:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          service: {
            ...state.newOrder.service,
            options: state.newOrder.service.options.filter(
              (item) => item.id !== action.state
            ),
          },
        },
      };

      case actionTypes.set_DropOff_fields_Dnd:
        if (action.state.firstDropOff) {
          return {
            ...state,
            newOrder: {
              ...state.newOrder,
              dropOffs: {
                ...state.newOrder.dropOffs,
                firstDropOff: {
                  ...state.newOrder.dropOffs.firstDropOff,
                  ...action.state.value,
                },
              },
            },
          };
        } else {
          return {
            ...state,
            newOrder: {
              ...state.newOrder,
              dropOffs: {
                ...state.newOrder.dropOffs,
                otherDropOffs: state.newOrder.dropOffs.otherDropOffs.map(
                  (DropOff, i) =>
                    i === action.state.index ? { ...action.state.value }
                      : DropOff
                ),
              },
            },
          }
        }

        
    case actionTypes.add_driver_option:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          driver: {
            ...state.newOrder.driver,
            options: [...state.newOrder.driver.options, action.state],
          },
        },
      };
    case actionTypes.remove_driver_option:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          driver: {
            ...state.newOrder.driver,
            options: state.newOrder.driver.options.filter(
              (item) => item.id !== action.state
            ),
          },
        },
      };
    case actionTypes.set_manual_activeVehicleType:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          driver: {
            ...state.newOrder.driver,
            options: [],
            manual_driver: {
              ...state.newOrder.driver.manual_driver,
              ...action.state,
            },
          },
        },
      };

    case actionTypes.set_pickup_inputs:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          pickup: {
            ...state.newOrder.pickup,
            [action.state.name]: action.state.value,
          },
        },
      };
    case actionTypes.set_pickup:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          pickup: {
            ...state.newOrder.pickup,
            ...action.state,
          },
        },
      };

    case actionTypes.add_dropoff:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          dropOffs: {
            ...state.newOrder.dropOffs,
            otherDropOffs: [
              ...state.newOrder.dropOffs.otherDropOffs,
              { ...sampleDropOff, id: action.state },
            ],
          },
        },
      };
    case actionTypes.remove_dropoff:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          activePickupDropOff:
            state.newOrder.activePickupDropOff.id === action.state
              ? {
                  type: null,
                  id: "",
                }
              : state.newOrder.activePickupDropOff,
          dropOffs: {
            ...state.newOrder.dropOffs,
            otherDropOffs: state.newOrder.dropOffs.otherDropOffs.filter(
              (drop) => drop.id !== action.state
            ),
          },
        },
      };

    case actionTypes.set_activePickupDropOff:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          activePickupDropOff:
            state.newOrder.activePickupDropOff.id === action.state.id
              ? {
                  type: null,
                  id: "",
                }
              : action.state,
        },
      };
    case actionTypes.set_dropOff_inputs:
      if (action.state.firstDropOff) {
        return {
          ...state,
          newOrder: {
            ...state.newOrder,
            dropOffs: {
              ...state.newOrder.dropOffs,
              firstDropOff: {
                ...state.newOrder.dropOffs.firstDropOff,
                [action.state.name]: action.state.value,
              },
            },
          },
        };
      } else {
        return {
          ...state,
          newOrder: {
            ...state.newOrder,
            dropOffs: {
              ...state.newOrder.dropOffs,
              otherDropOffs: state.newOrder.dropOffs.otherDropOffs.map(
                (DropOff) =>
                  DropOff.id === action.state.id
                    ? {
                        ...DropOff,
                        [action.state.name]: action.state.value,
                      }
                    : DropOff
              ),
            },
          },
        };
      }

    case actionTypes.set_SearchAutoComplete:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          suggestListPickUpDropOffLoading: false,
          suggestPickUpDropOffListData: action.state,
        },
      };
    case actionTypes.set_SearchAutoCompleteLoading:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          newOrder: {
            ...state.newOrder,
            suggestListPickUpDropOffLoading: action.state,
            suggestListPickUpDropOffOrdered:
              !state.newOrder.suggestListPickUpDropOffOrdered && true,
          },
        },
      };

    case actionTypes.newTravelPickupSetReserve:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          pickup: {
            ...state.newOrder.pickup,
            [action.state.name]: action.state.value,
          },
        },
      };

    case actionTypes.newTravelDropOffSetReserve:
      if (action.state.firstDropOff) {
        return {
          ...state,
          newOrder: {
            ...state.newOrder,
            dropOffs: {
              ...state.newOrder.dropOffs,
              firstDropOff: {
                ...state.newOrder.dropOffs.firstDropOff,
                [action.state.name]: action.state.value,
              },
            },
          },
        };
      } else {
        return {
          ...state,
          newOrder: {
            ...state.newOrder,
            dropOffs: {
              ...state.newOrder.dropOffs,
              otherDropOffs: state.newOrder.dropOffs.otherDropOffs.map(
                (DropOff) =>
                  DropOff.id === action.state.id
                    ? {
                        ...DropOff,
                        [action.state.name]: action.state.value,
                      }
                    : DropOff
              ),
            },
          },
        };
      }
    case actionTypes.resetneworder:
      return init;
    case actionTypes.NewTravelPaymentMethod:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          paymentMethod: { ...state.newOrder.paymentMethod, ...action.state },
        },
      };

    case actionTypes.set_auto_activeVehicleType:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          driver: {
            ...state.newOrder.driver,
            options: [],
            auto_driver: {
              ...state.newOrder.driver.auto_driver,
              auto_activeVehicleType: action.state,
            },
          },
        },
      };

    case actionTypes.set_driver_auto_vehicleTypes:
      // if (action.state.length > 0) {
      //   return {
      //     ...state,

      //     newOrder: {
      //       ...state.newOrder,
      //       driver: {
      //         ...state.newOrder.driver,
      //         options: state.newOrder.driver.auto_driver
      //           ? state.newOrder.driver.options
      //           : [],
      //         auto_driver: {
      //           ...state.newOrder.driver.auto_driver,
      //           auto_activeVehicleType: state.newOrder.driver.auto_driver
      //             .auto_activeVehicleType
      //             ? state.newOrder.driver.auto_driver.auto_activeVehicleType
      //             : action.state[0],
      //           auto_suggestListLoading: false,
      //           auto_suggestListData: action.state,
      //           auto_suggestListOrdered: true,
      //         },
      //       },
      //     },
      //   };
      // } else {
      //   return {
      //     ...state,

      //     newOrder: {
      //       ...state.newOrder,
      //       driver: {
      //         ...state.newOrder.driver,
      //         options: [],
      //         auto_driver: {
      //           ...state.newOrder.driver.auto_driver,
      //           auto_activeVehicleType: null,
      //           auto_suggestListLoading: false,
      //           auto_suggestListData: [],
      //           auto_suggestListOrdered: true,
      //         },
      //       },
      //     },
      //   };
      // }
      return {
        ...state,

        newOrder: {
          ...state.newOrder,
          driver: {
            ...state.newOrder.driver,
            options: state.newOrder.driver.auto_driver
              ? state.newOrder.driver.options
              : [],
            auto_driver: {
              ...state.newOrder.driver.auto_driver,
              auto_activeVehicleType: null,
              auto_suggestListLoading: false,
              auto_suggestListData: action.state,
              auto_suggestListOrdered: true,
            },
          },
        },
      };
    case actionTypes.recoverTimeMachine:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          driver: {
            ...state.newOrder.driver,
            options: action.state.driver.options,

            auto_driver: {
              ...state.newOrder.driver.auto_driver,
              auto_activeVehicleType:
                action.state.driver.auto_activeVehicleType,
            },
          },
          dropOffs: {
            ...state.newOrder.dropOffs,
            firstDropOff: action.state.dropOffs.firstDropOff,
            otherDropOffs: action.state.dropOffs.otherDropOffs,
          },
          service: {
            ...state.newOrder.service,
            activeVehicleType: action.state.service.activeVehicleType,
            options: action.state.service.options,
          },
          pickup: action.state.pickup,
        },
      };
      case actionTypes.reOrder:
        return {
          ...state,
          newOrder: {
            ...state.newOrder,
            service:{
              ...state.newOrder.service,
              ...action.state.service
            },
            driver:{
              ...state.newOrder.driver,
              auto_driver: {
                ...state.newOrder.driver.auto_driver,
                auto_activeVehicleType : action.state.driver.auto_activeVehicleType ? action.state.driver.auto_activeVehicleType : state.newOrder.driver.auto_driver.auto_activeVehicleType
              },
            },
            dropOffs: {
              ...state.newOrder.dropOffs,
              firstDropOff: action.state.dropOffs.firstDropOff,
              otherDropOffs: action.state.dropOffs.otherDropOffs,
            },
            pickup: action.state.pickup,
            paymentMethod :action.state.paymentMethod
          },
        };
  

        case actionTypes.new_Order_Reset_DropOffs:
          return {
            ...state,
            newOrder: {
              ...state.newOrder,
              dropOffs: {
                ...state.newOrder.dropOffs,
                  otherDropOffs: [],
              }
            },
          };
    

      
    default:
      return state;
  }
};
