import * as React from "react"
const AddressbookItemAddressSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    fill="none"
    {...props}
  >
    <circle cx={6} cy={6} r={6} fill="url(#aAddressbookItemAddressSvg)" />
    <mask
      id="bAddressbookItemAddressSvg"
      width={12}
      height={12}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <circle cx={6} cy={6} r={6} fill="#fff" />
    </mask>
    <g mask="url(#bAddressbookItemAddressSvg)">
      <path fill="url(#cAddressbookItemAddressSvg)" d="M0 0h12v12H0z" />
      <path
        fill="url(#aAddressbookItemAddressSvg)"
        fillRule="evenodd"
        d="M5.14 3.24h1.712c.752 0 1.36.297 1.368 1.052v4.212a.29.29 0 0 1-.033.136.287.287 0 0 1-.172.139.277.277 0 0 1-.22-.028l-1.798-.9-1.8.9a.295.295 0 0 1-.136.036.282.282 0 0 1-.28-.283V4.292c0-.755.61-1.052 1.359-1.052zm-.188 2.114h2.09a.217.217 0 0 0 .216-.219.217.217 0 0 0-.217-.219H4.952a.217.217 0 0 0-.216.22c0 .121.097.218.216.218z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <linearGradient
        id="aAddressbookItemAddressSvg"
        x1={0}
        x2={0}
        y1={0}
        y2={12}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={global.config.colors.iconLightEnd} />
        <stop offset={1} stopColor={global.config.colors.iconLightStart}  />
      </linearGradient>
      <linearGradient
        id="cAddressbookItemAddressSvg"
        x1={0}
        x2={0}
        y1={0}
        y2={12}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={global.config.colors.iconDarkEnd}  />
        <stop offset={1} stopColor={global.config.colors.iconDarkStart} />
      </linearGradient>
    </defs>
  </svg>
)
export default AddressbookItemAddressSvg
