import React, { useState } from "react";
import onroLogo from "./../../static/logotype-on-light-customer.svg";
import banner from "./../../static/delivery-5.jpg";
import { withTranslation, Trans } from "react-i18next";
import { getDefaultLanguageWithGlobal, getLogoTypeByUrl, LoginBannerWithDomain, LoginTextWithDomain, ShowCustomerPanelTitle } from "../../helper/module";
import googleBadgefa from "./../../static/google/google-play-badge-fa.png";
import googleBadgear from "./../../static/google/google-play-badge-ar.png";
import googleBadgede from "./../../static/google/google-play-badge-de.png";
import googleBadgeen from "./../../static/google/google-play-badge-en.png";
import googleBadgees from "./../../static/google/google-play-badge-es.png";
import googleBadgefr from "./../../static/google/google-play-badge-fr.png";
import googleBadgenl from "./../../static/google/google-play-badge-nl.png";
import googleBadgero from "./../../static/google/google-play-badge-ro.png";

import appleBadgefa from "./../../static/apple/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import appleBadgear from "./../../static/apple/Download_on_the_App_Store_Badge_AR_RGB_blk_102417.svg";
import appleBadgede from "./../../static/apple/Download_on_the_App_Store_Badge_DE_RGB_blk_092917.svg";
import appleBadgeen from "./../../static/apple/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import appleBadgees from "./../../static/apple/Download_on_the_App_Store_Badge_ES_RGB_blk_100217.svg";
import appleBadgefr from "./../../static/apple/Download_on_the_App_Store_Badge_FR_RGB_blk_100517.svg";
import appleBadgenl from "./../../static/apple/Download_on_the_App_Store_Badge_NL_RGB_blk_100317.svg";
import appleBadgero from "./../../static/apple/Download_on_the_App_Store_Badge_RO_RGB_blk_100317.svg";

const googleBadge = {
  fa: googleBadgefa,
  ar: googleBadgear,
  de: googleBadgede,
  en: googleBadgeen,
  es: googleBadgees,
  fr: googleBadgefr,
  nl: googleBadgenl,
  ro: googleBadgero,
}

const appleBadge = {
  fa: appleBadgefa,
  ar: appleBadgear,
  de: appleBadgede,
  en: appleBadgeen,
  es: appleBadgees,
  fr: appleBadgefr,
  nl: appleBadgenl,
  ro: appleBadgero,
}

class ClientAppsDownloadBadge extends React.Component {

  googleBadgeM = () => {
    let lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : getDefaultLanguageWithGlobal(global.config.defaultLanguage)
    return googleBadge[lang]
  }

  appleBadgeM = () => {
    let lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : getDefaultLanguageWithGlobal(global.config.defaultLanguage)
    return appleBadge[lang]
  }

  render() {
    if(global.config.apkUrl || global.config.iosUrl){
      return (
        <div className="loginDownloadAppContainer">
        {/* <p className="loginDownloadAppText"> {String(this.props.t("downloadClientApplication")).replace("xxx",global.config.ProjectName)}</p> */}
        <div className="loginDownloadAppBadgeContainer">  
          {global.config.apkUrl && <a href="/"><img style={{ height: '48px',borderRadius:"10px" }} src={this.googleBadgeM()} /></a> }
          {global.config.iosUrl && <a  href="/"><img style={{ height: '48px' , borderRadius:"10px"}} src={this.appleBadgeM()} /></a>}  
        </div>
      </div>
      );
    }else{
    return null 
    }
  }
}

export default withTranslation()(ClientAppsDownloadBadge);
