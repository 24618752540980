import * as React from "react"
const AddressbookItemPhoneSvg = (props) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width={14}
  height={14}
  fill="none"
  {...props}
>
  <mask
    id="aAddressbookItemPhoneSvg"
    width={12}
    height={5}
    x={1}
    y={9}
    mask-type="alpha"
    maskUnits="userSpaceOnUse"
  >
    <path
      fill="#fff"
      d="M7.348 9.222c-3.02 0-5.598.476-5.598 2.38C1.75 13.507 4.313 14 7.348 14c3.019 0 5.598-.475 5.598-2.38 0-1.905-2.562-2.398-5.598-2.398z"
    />
  </mask>
  <g mask="url(#aAddressbookItemPhoneSvg)">
    <path fill="url(#bAddressbookItemPhoneSvg)" d="M1.141 8.75h12.25V14H1.141z" />
  </g>
  <mask
    id="cAddressbookItemPhoneSvg"
    width={9}
    height={8}
    x={3}
    y={0}
    mask-type="alpha"
    maskUnits="userSpaceOnUse"
  >
    <path
      fill="#fff"
      d="M7.348 7.409a3.691 3.691 0 0 0 3.704-3.705A3.691 3.691 0 0 0 7.348 0a3.692 3.692 0 0 0-3.704 3.704A3.692 3.692 0 0 0 7.348 7.41z"
    />
  </mask>
  <g mask="url(#cAddressbookItemPhoneSvg)">
    <path fill="url(#dAddressbookItemPhoneSvg)" d="M3.5 0h7.875v7.875H3.5z" />
  </g>
  <defs>
    <linearGradient
      id="bAddressbookItemPhoneSvg"
      x1={1.141}
      x2={1.141}
      y1={8.75}
      y2={14}
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor={global.config.colors.iconLightEnd} />
      <stop offset={1} stopColor={global.config.colors.iconLightStart} />
    </linearGradient>
    <linearGradient
      id="dAddressbookItemPhoneSvg"
      x1={3.5}
      x2={3.5}
      y1={0}
      y2={7.875}
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor={global.config.colors.iconDarkEnd} />
      <stop offset={1} stopColor={global.config.colors.iconDarkStart} />
    </linearGradient>
  </defs>
</svg>
)
export default AddressbookItemPhoneSvg
