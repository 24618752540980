import axios from "axios";
import packageJson from "../../../package.json";
import { get_accessToken, get_lang } from "../../helper/UserData/userdate";

export const httpCustomer = axios.create({
  baseURL: global.config.restServerUrl,
  timeout: 10000,
  headers: {
    "Accept-Language": get_lang(),
    Authorization: "Bearer " + get_accessToken(),
  }
});

httpCustomer.defaults.headers.common["retry"] = 2;
httpCustomer.defaults.headers.common["retryDelay"] = 3000;
httpCustomer.defaults.headers.common["retryCount"] = 0;
httpCustomer.defaults.headers.common["X-Api-Source"] = 'web';
httpCustomer.defaults.headers.common["X-App-Version-Code"] = packageJson.version;
httpCustomer.defaults.headers.common["X-App-Version-Name"] = packageJson.version;