import React from "react";
import { GoogleMap, LoadScript } from '@react-google-maps/api';

import { connect } from "react-redux";
import HOC from "./HOC";
import "./index.css";
import { isMobile } from "react-device-detect";
import NewTravelPinsOnMap from "./New_Travel_Pins_On_Map";
import ViewTravelPinsOnMap from "./ViewTravelPinsOnMap";
import AdddressBookPinOnMap from "./AdddressBookPinOnMap";

import New_Travel_OriginDestinationIconGenerator from "./New_Travel_OriginDestinationIconGenerator";
import AdddressBookPinSetLocation from "./AdddressBookPinSetLocation";
import { Api_GetVehicleTypeByService_AGW, Api_map_geocoding_reverse_AGW } from "../../../../helper/api/ApiGateway";
import { Set_latlng, Set_Map_Ref } from "../../../../Redux/actions/actionNames";
import TrackDriver from "./trackDriver";
import BoundGenerater from "./boundGenerater";
import { getCenterMap } from "../../../../helper/module";
import { isNewOrderOpen } from "../../../ViewOrder/Map/osm";


const Leaflet = window.L;
var _ = require("lodash");


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: 14,
      ref : null ,
      finishedmoving: false,
    };
    this.lastMove = 0;

    this.handleMove = this.handleMove.bind(this);
  }


  userIsOnPinSelectState = () => {
    if (
      (this.props.pickupDropOffType &&
        this.props.pickupDropOffType == "Pickup") ||
      (this.props.pickupDropOffType &&
        this.props.pickupDropOffType == "DropOff" &&
        !this.props.pickupDropOffId) ||
      (this.props.pickupDropOffType &&
        this.props.pickupDropOffType == "DropOff" &&
        this.props.pickupDropOffId)
    ) {
      return true;
    } else {
      return false;
    }
  };


  throttleHandleChange() {
    this.lastMove = Date.now();
    if (this.props.mapRef) {    
    let center = this.props.mapRef.getCenter();


    if(this.props.openAddAddressBookInMenu || this.props.openEditAddressBookInMenu){
      Api_map_geocoding_reverse_AGW(this.props.dispatch, {
        latitude: center.lat(),
        longitude: center.lng(),
      });
    }



    if (isNewOrderOpen() && this.userIsOnPinSelectState()) {
      Api_map_geocoding_reverse_AGW(this.props.dispatch, {
        latitude: center.lat(),
        longitude: center.lng(),
      });
    }

    
  

    if (this.props.NRpickupAdresss_loc.lng == "" && this.props.NROpenDialoge && this.props.activeVehicleType) {
      Api_GetVehicleTypeByService_AGW(this.props.dispatch, {
        latitude: center.lat(),
        longitude:center.lng(),
        serviceId: this.props.activeVehicleType.id,
      });
    }
 
  }
  }

  componentDidMount() {
    let center = getCenterMap(this.props.mapRef , this.props.center);

    Api_map_geocoding_reverse_AGW(this.props.dispatch, {
      latitude:this.props.mapRef.current ? center.lat : this.props.center.lat,
      longitude:this.props.mapRef.current ? center.lng : this.props.center.lng,
    });
  }

  handleMove(e) {
    this.handleCenterChanged()
    this.throttleHandleChange();
    
  }




  handleOnLoad = map => {
    this.props.dispatch(Set_Map_Ref(map))
   this.setState({
     ref:map
   })
 };

 handleCenterChanged = () => {
  if (this.props.mapRef) {
    const newCenter = this.props.mapRef.getCenter();
     this.props.dispatch(
      Set_latlng({
        lng: newCenter.lng(),
        lat: newCenter.lat(),
      })
  );

  }
};

mapwidth = () => {
 if(String(window.location.pathname).includes("neworder")) {
  return 'calc(100vw - 465px)'
 }
};



// componentWillReceiveProps(nextProps) {
//   if (nextProps.openAddAddressBookInMenu != this.props.openAddAddressBookInMenu) {
//     this.handleCenterChanged()
//     this.throttleHandleChange();
//   }
// }

  render() {


    return (
      <HOC>
        <div
          className="MapContainer"
          style={{
            textAlign: "center",
            width:this.mapwidth()
          }}
        >
          <New_Travel_OriginDestinationIconGenerator />
          <AdddressBookPinSetLocation />
          {/* <EstimatedRoute_panel /> */}
          {(this.props.mapRef && (!_.isEmpty(this.props.VRpureData) || isNewOrderOpen()) ) && <BoundGenerater />}

          <LoadScript
            loadingElement={()=>{return null}}
            googleMapsApiKey={global.config.applicationMap.apiKey}
          > 


          <GoogleMap
           id="map"
           mapContainerStyle={{
            zIndex: "5",
            width: "100%",
            height:"100%"

          }}
          style={{
            zIndex: "5",
            width: "100%",
            height:"100%"

          }}
          options = {{
            minZoom: 4,
            maxZoom: 22,
          }}
          onLoad={this.handleOnLoad}
          center={this.props.center}
          zoom={this.state.zoom}
          onDragEnd={this.handleMove}
          onResize={this.handleCenterChanged}
          ref={this.props.mapRef}
          >

           


            <NewTravelPinsOnMap />
            <ViewTravelPinsOnMap />
            <AdddressBookPinOnMap />
            {this.props.OpenVRDialoge && <TrackDriver />}



          </GoogleMap>

          </LoadScript>
        </div>
      </HOC>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  mapRef: state.Map.mapRef,
  OpenNRDialoge: state.NewOrder.OpenDialoge,
  NRactivePickupDropOff: state.NewOrder.newOrder.activePickupDropOff,
  NRpickupAddress: state.NewOrder.newOrder.pickup.address,
  NRpickupAdresss_loc: state.NewOrder.newOrder.pickup.adresss_loc,
  NRdropOffAddress: state.NewOrder.newOrder.dropOffs.firstDropOff.address,
  NRdropOffsAdresss_loc:
    state.NewOrder.newOrder.dropOffs.firstDropOff.adresss_loc,
  NRotherDropOffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,
  center: state.Map.center,
  mapRef: state.Map.mapRef,
  defaultLocation: state.SharedData.WebSettings? state.SharedData.WebSettings.defaultLocation :null,
  activeVehicleType:state.NewOrder.newOrder.service.activeVehicleType,
  NROpenDialoge: state.NewOrder.OpenDialoge,



  OpenVRDialoge: state.ViewTravel.OpenDialoge,
  VRpureData: state.ViewTravel.pureData,
  orderType: state.ViewTravel.pureData
  ? state.ViewTravel.pureData.type
    ? state.ViewTravel.pureData.type
    : null
  : null,

  loading: state.ViewTravel.loading,
  error: state.ViewTravel.error,


  pickupDropOffType: state.NewOrder.newOrder.map_Data.pickupDropOff.type,
  pickupDropOffId: state.NewOrder.newOrder.map_Data.pickupDropOff.id,

  openAddAddressBookInMenu: state.Addressbook.openAddAddressBookInMenu ,
  openEditAddressBookInMenu: state.Addressbook.openEditAddressBookInMenu ,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
