import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import Slide from "@material-ui/core/Slide";
import { get_Id, get_LeftRight } from "../../../../../helper/UserData/userdate";
import close from "../../../../../static/close.svg";
import {
  SetopenNewAddressBookInNewOrder,
  OnChangeNewAddressBookInNewOrderDetails,
  ResetNewAddressBookInNewOrderDetails,
} from "../../../../../Redux/actions/actionNames";
import { Api_Add_Addressbook_AGW, Api_Get_Addressbook_list_AGW, Api_SearchAutoComplete_AGW } from "../../../../../helper/api/ApiGateway";
import { SucceessFul_i18 } from "../../../../../helper/Notification/sendNotification";
import Address from "./ffff";
import PhoneInput from "react-phone-number-input";
import { getCenterMap, getCountryWithDomain } from "../../../../../helper/module";

var _ = require("lodash");

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false
    };
  }

  showPoper = () => {
    this.setState({ showpoper: true });
  };

  closeNewAddressbook = () => {
    this.props.dispatch(SetopenNewAddressBookInNewOrder(false));
    this.props.dispatch(
      ResetNewAddressBookInNewOrderDetails()
    );
  };

  OnChangeDetails = (key, value) => {
    this.props.dispatch(
      OnChangeNewAddressBookInNewOrderDetails({ key, value })
    );
  };

  componentWillReceiveProps(nextProps){

  }

  saveAddressBook = () => {
    if (this.props.Details.title.length > 0) {
      Api_Add_Addressbook_AGW(this.props.dispatch, {
        "title": this.props.Details.title,
        "address": this.props.Details.address,
          "coordinates": [
              this.props.Details.adresss_loc.lng, this.props.Details.adresss_loc.lat
          ], 
          "buildingBlock": this.props.Details.block,
          "description": this.props.Details.description,
          "floor": this.props.Details.floor,
          "fullName": this.props.Details.FullName,
          "phone": this.props.Details.PhoneNumber,
          "placeId": "",
          "room": this.props.Details.unit,
          "code": this.props.Details.code ,
          email:this.props.Details.email ,
          description:this.props.Details.description ,
      },(call)=>{
        if(call.loading){
          this.setState({
            loading : true
          })
        }else {
          this.setState({
            loading : false
          })
          if(call.ok){
            this.props.dispatch(SetopenNewAddressBookInNewOrder(false));
            SucceessFul_i18(
              "Createdsuccessfully",
              "networkError",
              "close",
              () => {
                this.props.dispatch({
                  type: "removeNetworkErrorSnack",
                  key: "networkError",
                });
              },
              new Date().getTime() + Math.random()
            );
            Api_Get_Addressbook_list_AGW(this.props.dispatch, {page:1 , perpage:20 , searchRegex:""}, false);

          }else{
            
          }
        }
      });
    }
  };


  handleInputChange = (value) => {
    this.props.dispatch(
      OnChangeNewAddressBookInNewOrderDetails({ key:"address", value })
    );
    if (value && value != "") {
      
      let center = getCenterMap(this.props.mapRef , this.props.center) ;


      Api_SearchAutoComplete_AGW(
        this.props.dispatch,
        {
          query: value,
          latitude: this.props.mapRef.current
            ? center.lat
            : this.props.center.lat,
          longitude: this.props.mapRef.current
            ? center.lng
            : this.props.center.lng,
        },
        (call) => {
          this.SetOptions(call);
        }
      );
    }
  };

  render() {
    const { t } = this.props;

    return (
      <Slide
        timeout={650}
        direction={get_LeftRight()}
        in={this.props.openNewAddressBookInNewOrder}
        mountOnEnter
        unmountOnExit
      >
        <div className="NewAddressbookConatiner">
          <nRHeader style={{ boxShadow: "none" }}>
            <img
              onClick={() => this.closeNewAddressbook()}
              className="close"
              src={close}
            />
            <span className="title">
              <Trans i18nKey={"newAddress"}> </Trans>
            </span>
          </nRHeader>

          <span className="NewAddressbookInfoTitle">
            <Trans i18nKey={"NewAddressbookInfoTitle"}> </Trans>
          </span>

          <input
            value={this.props.Details.title}
            placeholder={t("Title")}
            className="NewAddressbookInfoTitleField"
            onChange={(e) => {
              this.OnChangeDetails("title", e.target.value);
            }}
          />
          <input
            value={this.props.Details.code}
            placeholder={t("Code")}
            className="NewAddressbookInfoCodeField"
            onChange={(e) => {
              this.OnChangeDetails("code", e.target.value);
            }}
          />

          <hr className="NewAddressbookInfohr" />

          <span className="NewAddressbookAddressdetailsTitle">
            <Trans i18nKey={"Addressdetails"}> </Trans>
          </span>

          {/* <input
            className="NewAddressbookAddressdetailsAddress"
            placeholder={t("address")}
            value={this.props.Details.address}
            onChange={(e) => {
              this.OnChangeDetails("address", e.target.value);
            }}
          /> */}
        <Address />

          <div className="NewAddressbookAddressdetailsFieldsContainer pickupcollapsecontainer">
            <input
              className="blockPickup"
              placeholder={t("block")}
              value={this.props.Details.block}
              onChange={(e) => {
                this.OnChangeDetails("block", e.target.value);
              }}
            />
            <input
              className="floorPickup"
              placeholder={t("Floor")}
              value={this.props.Details.floor}
              onChange={(e) => {
                this.OnChangeDetails("floor", e.target.value);
              }}
            />
            <input
              className="UnitPickup"
              placeholder={t("Unit")}
              value={this.props.Details.unit}
              onChange={(e) => {
                this.OnChangeDetails("unit", e.target.value);
              }}
            />
            <input
              className="senderfullnamePickup"
              placeholder={t(
                `${
                  this.props.sonabino == "pickup"
                    ? "senderfullname"
                    : "Recieverfullname"
                }`
              )}
              value={this.props.Details.FullName}
              onChange={(e) => {
                this.OnChangeDetails("FullName", e.target.value);
              }}
            />
              {/* <PhoneInput
                defaultCountry={global.config.Country}
                placeholder={t("senderphonenumber")}
                value={this.props.Details.PhoneNumber}
                onChange={(PrePhoneInput) => {
                this.OnChangeDetails("PhoneNumber", PrePhoneInput);
              }}
                id="senderPhoneNumber"
                className="senderphonenumberPickup"
              /> */}

           <input
              className="senderphonenumberPickup"
              placeholder={t(
                `${
                  this.props.sonabino == "pickup"
                    ? "senderphonenumber"
                    : "Recieverphonenumber"
                }`
              )}
              value={this.props.Details.PhoneNumber}
              onChange={(PrePhoneInput) => {
              this.OnChangeDetails("PhoneNumber", PrePhoneInput.target.value);
            }}
            />  
          </div>
          <input
            value={this.props.Details.email}
            className="NewAddressbookAddressdetailsAddress"
            placeholder={t("email")}
            onChange={(e) => {
              this.OnChangeDetails("email", e.target.value);
            }}
          />
          <input
            value={this.props.Details.description}
            className="NewAddressbookAddressdetailsAddress"
            placeholder={t("notesfordriver")}
            onChange={(e) => {
              this.OnChangeDetails("description", e.target.value);
            }}
          />


          <div className="NewAddressbookFooter">
            <span
            onClick={(this.props.Details.title.length > 0 &&  !this.state.loading) ? this.saveAddressBook : null}
              className={`SettingFooterButton ${
                ((this.props.Details.title.length == 0 ) && !this.state.loading)&& "DeactiveSettingFooterButton"
             
              }`}
            >
              <Trans i18nKey={"save"}></Trans>
            </span>
          </div>
        </div>
      </Slide>
    );
  }
}

const mapStateToProps = (state) => ({
  openNewAddressBookInNewOrder: state.Addressbook.openNewAddressBookInNewOrder,
  Details: state.Addressbook.NewAddressBookInNewOrderDetails,
  sonabino: state.Addressbook.sourceOfNewAddressBookInNewOrder || "",
  center: state.Map.center,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
