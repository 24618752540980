import * as React from "react"
const Receipt = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <defs>
      <linearGradient id="aReceipt" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconLightStart}/>
        <stop offset="100%" stopColor={global.config.colors.iconLightEnd} />
      </linearGradient>
      <linearGradient id="bReceipt" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconDarkStart} />
        <stop offset="100%" stopColor={global.config.colors.iconDarkEnd} />
      </linearGradient>
    </defs>
    <g fill="none">
      <path
        fill="url(#aReceipt)"
        d="M11.527 5.286c-.34 0-.79-.007-1.351-.007-1.368 0-2.492-1.132-2.492-2.512V.346A.343.343 0 0 0 7.344 0H3.36C1.502 0 0 1.525 0 3.395v8.113c0 1.961 1.574 3.55 3.516 3.55h5.93c1.853 0 3.354-1.515 3.354-3.386V5.625a.341.341 0 0 0-.34-.345c-.319.003-.7.006-.933.006"
        transform="translate(1.333)"
      />
      <path
        fill="url(#bReceipt)"
        d="M9.475.427a.36.36 0 0 0-.618.252v1.985c0 .833.686 1.518 1.519 1.518.524.006 1.253.008 1.872.006.317 0 .478-.38.258-.608-.794-.826-2.216-2.307-3.031-3.153"
        transform="translate(1.333)"
      />
      <path
        fill="#004CC4"
        d="M9.554 9.708a.561.561 0 0 1 0 1.12h-4.1a.56.56 0 1 1 0-1.12h4.1zm-1.55-3.76a.561.561 0 0 1 0 1.12h-2.55a.56.56 0 1 1 0-1.12h2.55z"
      />
    </g>
  </svg>
)
export default Receipt
