import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";


class App extends Component {
  render() {
    return (
        <pickupropoffsContainer>
        <pinsContainer>
          <circleBlue style={{
              border: `2px solid ${global.config.colors.iconLightEnd}` ,
              backgroundColor : global.config.colors.iconDarkEnd
          }}/>
          <squre style={{backgroundColor : global.config.colors.iconDarkEnd}}/>
        </pinsContainer>

        <addresss>
          <pickupAddress>{this.props.pickup?.address}</pickupAddress>
          <dropoffAddress>{this.props.dropoff?.address}</dropoffAddress>
        </addresss>
      </pickupropoffsContainer> 
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
