import "./index.css";

import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import styles from './style.module.css';
import colors from '../colors/colors.module.css';
import { DropDownProps } from "./DropDown.types";
import IconProvider from "../IconProvider";
import ProgressIndicator from "../ProgressIndicator";
import { ARROW_DOWN_BOLD, ic_circle_close } from "../../../static/index";
import {ReactComponent as Arrow_down_bold_white} from "../../../static/IconProvider/arrow_down_bold_white.svg";

import { CSSTransition } from 'react-transition-group';
import Typography from "../Typography";
import IconProviderSvgModifier from "../IconProviderSvgModifier";
import IconProviderSvgCustomColor from "../IconProviderSvgCustomColor";
const { uuid } = require('uuidv4');


const DropDownButton: React.FC<DropDownProps> = ({isDisabled, closeIcon , noArrowIcon = false , ItemsList, checkSelected = "hybrid", titleOnClick, clearOnClick, clearText, data, placement, text, badgeText, title, style, className, ref, onClick, size, icon, isSelected, isRequired, requiredText , textStyle}: DropDownProps) => {
    const [isOpen, setIsOpen] = useState<any>(false);

    const [elmId, setElmId] = useState(`"elmDrop${uuid()}`);
    const [mapZindex, setMapZindex] = useState(0);

    //  useEffect(() => {
    //      setTimeout(() => {
    //         try {
    //               const element = document.getElementById('googlemapUniqeId');
    //               setMapZindex(element.style.zIndex)
    //         } catch (error) {
    //         }
    //      }, 1000);
    //     }, []);
    

    const dropdownNode: any = useClickOutside(() => {
        setIsOpen(false);
    })

    const getParentWidth = React.useCallback(() => {

        let elm = document.getElementById(elmId)
        var box = elm.getBoundingClientRect();

        return box.width
        
      }, [elmId ]);


    const openEditCustomer = React.useCallback(() => {



        if(placement == "topStart"){
            let elm = document.getElementById(elmId)
   
            var body = document.body,
            html = document.documentElement;

            var height = Math.max( body.scrollHeight, body.offsetHeight, 
                                html.clientHeight, html.scrollHeight, html.offsetHeight );


            var box = elm.getBoundingClientRect();
    
            var body = document.body;
            var docEl = document.documentElement;
        
            var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
            var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
        
            var clientTop = docEl.clientTop || body.clientTop || 0;
            var clientLeft = docEl.clientLeft || body.clientLeft || 0;
            var clientRight = ((docEl.clientWidth || body.clientWidth) - box.width - box.left) || 0;
 
 
        
            var bottom  = height -  box.y
            var left = box.left 

            console.log("dwvefewsdxs ",body.clientHeight , box.y)

 
            return {bottom: `${Math.round(bottom)}px`, [clientRight <= 200 ? "right" :"left"] :  (clientRight <= 200 ? `${Math.round(clientRight)}px` : `${Math.round(left)}px`)};

        }else if(placement == "topEnd"){
            let elm = document.getElementById(elmId)
   
            var box = elm.getBoundingClientRect();
    
            var body = document.body;
            html = document.documentElement;

            var docEl = document.documentElement;
    
            var height = Math.max( body.scrollHeight, body.offsetHeight, 
                html.clientHeight, html.scrollHeight, html.offsetHeight );

            var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
            var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
        
            var clientTop = docEl.clientTop || body.clientTop || 0;
            var clientLeft = docEl.clientLeft || body.clientLeft || 0;
            var clientRight = ((docEl.clientWidth || body.clientWidth) - box.width - box.left) || 0;
 
 
            var bottom  = height -  box.y
        
            var top  = box.top 
            var left = box.left + scrollLeft - clientLeft;
 
 
            return  {bottom: `${Math.round(bottom)}px`, right : `${Math.round(clientRight)}px`};

         
        }else if(placement == "bottomStart"){
            let elm = document.getElementById(elmId)
   
            var box = elm.getBoundingClientRect();
    
            var body = document.body;
            var docEl = document.documentElement;
        
            var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
            var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
        
            var clientTop = docEl.clientTop || body.clientTop || 0;
            var clientLeft = docEl.clientLeft || body.clientLeft || 0;
            var clientRight = ((docEl.clientWidth || body.clientWidth) - box.width - box.left) || 0;
 
 
        
            var top  = box.top +  box.height + 10;
            var left = box.left + scrollLeft - clientLeft;
 
 
            return {top: `${Math.round(top)}px`, [clientRight <= 200 ? "right" :"left"] :  (clientRight <= 200 ? `${Math.round(clientRight)}px` : `${Math.round(box.left)}px`)};
       
        }else if(placement == "bottomEnd"){
            let elm = document.getElementById(elmId)
   
            var box = elm.getBoundingClientRect();
    
            var body = document.body;
            var docEl = document.documentElement;
        
            var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
            var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
        
            var clientTop = docEl.clientTop || body.clientTop || 0;
            var clientLeft = docEl.clientLeft || body.clientLeft || 0;
            var clientRight = ((docEl.clientWidth || body.clientWidth) - box.width - box.left) || 0;
 
 
        
            var top  = box.top +  box.height + 10;
            var left = box.left + scrollLeft - clientLeft;
 
 
            return {top: `${Math.round(top)}px`, [clientRight <= 200 ? "right" :"left"] :  (clientRight <= 200 ? `${Math.round(clientRight)}px` : `${Math.round(box.left)}px`)};
        }else{
            let elm = document.getElementById(elmId)
   
            var box = elm.getBoundingClientRect();
    
            var body = document.body;
            var docEl = document.documentElement;
        
            var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
            var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
        
            var clientTop = docEl.clientTop || body.clientTop || 0;
            var clientLeft = docEl.clientLeft || body.clientLeft || 0;
            var clientRight = ((docEl.clientWidth || body.clientWidth) - box.width - box.left) || 0;
 
 
        
            var top  = box.top  
            var left = box.left 
 
            return {top: `${Math.round(top)}px`, [clientRight <= 200 ? "right" :"left"] :  (clientRight <= 200 ? `${Math.round(clientRight)}px` : `${Math.round(left)}px`)};

        }


        
      }, [elmId , placement]);

  
 
    //    useEffect(() => { 
    
        
    //     if(mapZindex != 0){
      
    //  try {
    //         if (isOpen) {
    //             const element = document.getElementById('googlemapUniqeId');
    //            element.style.zIndex = String(Number(mapZindex) - 1);
    //          } else {
    //        const element = document.getElementById('googlemapUniqeId');
    //            element.style.zIndex = mapZindex;
    //          }
    //      } catch (error) {
    //      }
    // }
        
    //      }, [isOpen]);

    // const openEditCustomer = React.useCallback(() => {


    //     if(placement == "topStart"){
    //         let elm = document.getElementById(elmId)
   
    //         var body = document.body,
    //         html = document.documentElement;

    //         var height = Math.max( body.scrollHeight, body.offsetHeight, 
    //                             html.clientHeight, html.scrollHeight, html.offsetHeight );


    //         var box = elm.getBoundingClientRect();
    
    //         var body = document.body;
    //         var docEl = document.documentElement;
        
    //         var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    //         var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
        
    //         var clientTop = docEl.clientTop || body.clientTop || 0;
    //         var clientLeft = docEl.clientLeft || body.clientLeft || 0;
    //         var clientRight = ((docEl.clientWidth || body.clientWidth) - box.width - box.left) || 0;
 
 
        
    //         var bottom  = height -  box.y
    //         var left = box.left 

    //         console.log("dwvefewsdxs ",body.clientHeight , box.y)

 
    //         return {bottom: `${Math.round(bottom)}px`, [clientRight <= 200 ? "right" :"left"] :  (clientRight <= 200 ? `${Math.round(clientRight)}px` : `${Math.round(left)}px`)};

    //     }else if(placement == "topEnd"){
    //         let elm = document.getElementById(elmId)
   
    //         var box = elm.getBoundingClientRect();
    
    //         var body = document.body;
    //         var docEl = document.documentElement;
        
    //         var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    //         var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
        
    //         var clientTop = docEl.clientTop || body.clientTop || 0;
    //         var clientLeft = docEl.clientLeft || body.clientLeft || 0;
    //         var clientRight = ((docEl.clientWidth || body.clientWidth) - box.width - box.left) || 0;
 
 
        
    //         var top  = box.top 
    //         var left = box.left + scrollLeft - clientLeft;
 
 
    //         return  {top: `${Math.round(top)}px`, right : `${Math.round(clientRight)}px`};

         
    //     }else if(placement == "bottomStart"){
    //         let elm = document.getElementById(elmId)
   
    //         var box = elm.getBoundingClientRect();
    
    //         var body = document.body;
    //         var docEl = document.documentElement;
        
    //         var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    //         var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
        
    //         var clientTop = docEl.clientTop || body.clientTop || 0;
    //         var clientLeft = docEl.clientLeft || body.clientLeft || 0;
    //         var clientRight = ((docEl.clientWidth || body.clientWidth) - box.width - box.left) || 0;
 
 
        
    //         var top  = box.top +  box.height + 10;
    //         var left = box.left + scrollLeft - clientLeft;
 
 
    //         return {top: `${Math.round(top)}px`, [clientRight <= 200 ? "right" :"left"] :  (clientRight <= 200 ? `${Math.round(clientRight)}px` : `${Math.round(box.left)}px`)};
       
    //     }else if(placement == "bottomEnd"){
    //         let elm = document.getElementById(elmId)
   
    //         var box = elm.getBoundingClientRect();
    
    //         var body = document.body;
    //         var docEl = document.documentElement;
        
    //         var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    //         var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
        
    //         var clientTop = docEl.clientTop || body.clientTop || 0;
    //         var clientLeft = docEl.clientLeft || body.clientLeft || 0;
    //         var clientRight = ((docEl.clientWidth || body.clientWidth) - box.width - box.left) || 0;
 
 
        
    //         var top  = box.top +  box.height + 10;
    //         var left = box.left + scrollLeft - clientLeft;
 
 
    //         return {top: `${Math.round(top)}px`, [clientRight <= 200 ? "right" :"left"] :  (clientRight <= 200 ? `${Math.round(clientRight)}px` : `${Math.round(box.left)}px`)};
    //     }else{
    //         let elm = document.getElementById(elmId)
   
    //         var box = elm.getBoundingClientRect();
    
    //         var body = document.body;
    //         var docEl = document.documentElement;
        
    //         var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    //         var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
        
    //         var clientTop = docEl.clientTop || body.clientTop || 0;
    //         var clientLeft = docEl.clientLeft || body.clientLeft || 0;
    //         var clientRight = ((docEl.clientWidth || body.clientWidth) - box.width - box.left) || 0;
 
 
        
    //         var top  = box.top  
    //         var left = box.left 
 
    //         return {top: `${Math.round(top)}px`, [clientRight <= 200 ? "right" :"left"] :  (clientRight <= 200 ? `${Math.round(clientRight)}px` : `${Math.round(left)}px`)};

    //     }


        
    //   }, [elmId , placement]);

  
 
    //    useEffect(() => { 
    
        
    //     if(mapZindex != 0){
      
    //  try {
    //         if (isOpen) {
    //             const element = document.getElementById('googlemapUniqeId');
    //            element.style.zIndex = String(Number(mapZindex) - 1);
    //          } else {
    //        const element = document.getElementById('googlemapUniqeId');
    //            element.style.zIndex = mapZindex;
    //          }
    //      } catch (error) {
    //      }
    // }
        
    //      }, [isOpen]);

    return (
        <>
           <div id={elmId} onClick={(e) => { !isDisabled && setIsOpen(!isOpen); (titleOnClick && !isDisabled) && titleOnClick() }} className={clsx(styles[`${size}`], isDisabled && styles.isDisabled , className && className)}
            style={style ? style : {}} >

            {(icon) && <IconProvider Icon={icon} size="16" />}
            {(text) && <span className={clsx(styles.title , textStyle && textStyle )} >
            
                 {text}
            </span>}


            {(data && data.length > 0 && !noArrowIcon ) && <IconProviderSvgCustomColor Icon={Arrow_down_bold_white} size="12" style={{ width: "10px", height: "10px" }} />}

        </div>

          { (isOpen && (data && data.length > 0)) && <CSSTransition
                in={isOpen && (data && data.length > 0)}
                timeout={500}
                classNames="dropdown-animation"
                unmountOnExit
                onEnter={() => setIsOpen(true)}
                onExited={() => setIsOpen(false)}
                style={openEditCustomer() ?  {...openEditCustomer() , minWidth:`${getParentWidth()- 32}px`} :{}}

            >
                <div ref={dropdownNode}  className={clsx(styles.dropdownBox, styles[placement])}  onClick={() => { setIsOpen(!isOpen)  }} >



                   {ItemsList ? ItemsList :  <div className={styles.itemsConatiner} >
                        {data.map((serv: any) => {
                            // return <div onClick={() => { onClick && onClick(serv) }} className={clsx((serv.title ? serv.title : serv) == text && styles.selectedDropItem)}>
                            return <div onClick={() => { serv.onclick() ; setIsOpen(false); }} className={clsx((checkSelected == "hybrid" ? text?.includes((serv.title ? serv.title : serv)) : text == (serv.title ? serv.title : serv)) && styles.selectedDropItem)}>

                                {serv.icon && <img src={serv.icon} />}
                                {serv.title ? serv.title : serv}

                            </div>
                        })}
                    </div>}
                </div>
            </CSSTransition>}
        </>
     

    );
};

export default DropDownButton;



const useClickOutside = (handler: any) => {
    const domNode = useRef<HTMLInputElement>();
    useEffect(() => {
       try {
         const eventHandler = (event: any) => {
             if (!domNode?.current?.contains(event.target)) {
                 handler();
             }
             // uncomment this for dropdown close on menu click
             // handler();
         }
         document.addEventListener("mousedown", eventHandler);
         return () => {
             document.removeEventListener('mousedown', eventHandler);
         }
       } catch (error) {
        
       }
    });
    return domNode;
}