import React from "react";
import { Marker } from '@react-google-maps/api';
import { connect } from "react-redux";
import { OverlayView } from '@react-google-maps/api';
import PinFavoriteSvg from "../../../../components/svg/pinFavoriteSvg";
import {ReactComponent as Pin} from "../../../../static/pin-favorite.svg";


class App extends React.Component {
 
  render() {
    let adresss_loc = this.props.adresss_loc && (this.props.openMenu || this.props.openMenuOrder ) ;

    return (
      <React.Fragment>

           {adresss_loc ? (
                <OverlayView
                position={this.props.adresss_loc}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    
              >
                <div style={{ width: '50px', height: "50px", marginLeft: "-25px", marginTop: "-50px" }}>
                  <PinFavoriteSvg />
                </div>
    
              </OverlayView>
          ) : null}


{this.props.EditAddressbook ? (
                <OverlayView
                position={{lat : this.props.EditAddressbook[1], lng:this.props.EditAddressbook[0]}}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    
              >
                <div style={{ width: '50px', height: "50px", marginLeft: "-25px", marginTop: "-50px" }}>
                  <Pin />
                </div>
    
              </OverlayView>
          ) : null}

        {/* {adresss_loc ? (
            <Marker
              position={this.props.adresss_loc}
              icon={Pin}
            ></Marker>
          ) : null} */}

       </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  openMenu: state.Addressbook.openAddAddressBookInMenu ,
  openMenuOrder: state.Addressbook.openNewAddressBookInNewOrder ,
  EditAddressbook: state.Addressbook.EditAddressBookInMenuDetails.coordinates ,

  adresss_loc: state.Addressbook.NewAddressBookInNewOrderDetails.adresss_loc ,
  mapRef: state.Map.mapRef,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
