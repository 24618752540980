import * as React from "react"
const AddressbookItemNote2Svg = (props) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width={14}
  height={14}
  fill="none"
  {...props}
>
  <mask
    id="aAddressbookItemNote2Svg"
    width={14}
    height={14}
    x={0}
    y={0}
    maskUnits="userSpaceOnUse"
    style={{
      maskType: "alpha",
    }}
  >
    <path
      fill="#fff"
      d="M10.109 0H4.242C2.114 0 .875 1.246.875 3.381v7.231C.875 12.782 2.114 14 4.242 14h5.867c2.162 0 3.366-1.218 3.366-3.388V3.381C13.475 1.246 12.271 0 10.109 0"
    />
  </mask>
  <g mask="url(#aAddressbookItemNote2Svg)">
    <path fill="url(#bAddressbookItemNote2Svg)" d="M0 0h14v14H0z" />
  </g>
  <path
    fill="url(#cAddressbookItemNote2Svg)"
    fillRule="evenodd"
    d="M4.431 3.255v.007a.546.546 0 0 0 0 1.092h2.093a.549.549 0 0 0 0-1.099H4.43zM9.92 7.518H4.431a.546.546 0 0 1 0-1.093H9.92a.547.547 0 0 1 0 1.093zm0 3.199H4.431a.544.544 0 0 1-.525-.252.556.556 0 0 1 .525-.847H9.92a.55.55 0 0 1 .49.553c0 .28-.21.518-.49.546z"
    clipRule="evenodd"
  />
  <mask
    id="dAddressbookItemNote2Svg"
    width={8}
    height={8}
    x={3}
    y={3}
    maskUnits="userSpaceOnUse"
    style={{
      maskType: "alpha",
    }}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M4.431 3.255v.007a.546.546 0 0 0 0 1.092h2.093a.549.549 0 0 0 0-1.099H4.43zM9.92 7.518H4.431a.546.546 0 0 1 0-1.093H9.92a.547.547 0 0 1 0 1.093zm0 3.199H4.431a.544.544 0 0 1-.525-.252.556.556 0 0 1 .525-.847H9.92a.55.55 0 0 1 .49.553c0 .28-.21.518-.49.546z"
      clipRule="evenodd"
    />
  </mask>
  <g mask="url(#dAddressbookItemNote2Svg)">
    <path fill="url(#eAddressbookItemNote2Svg)" d="M.267 0h13.125v14H.267z" />
  </g>
  <defs>
    <linearGradient
      id="bAddressbookItemNote2Svg"
      x1={0}
      x2={0}
      y1={0}
      y2={14}
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor={global.config.colors.iconLightEnd} />
      <stop offset={1} stopColor={global.config.colors.iconLightStart} />
    </linearGradient>
    <linearGradient
      id="cAddressbookItemNote2Svg"
      x1={3.822}
      x2={3.822}
      y1={3.255}
      y2={10.722}
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor={global.config.colors.iconDarkEnd}/>
      <stop offset={1} stopColor={global.config.colors.iconDarkStart} />
    </linearGradient>
    <linearGradient
      id="eAddressbookItemNote2Svg"
      x1={0.267}
      x2={0.267}
      y1={0}
      y2={14}
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor={global.config.colors.iconDarkEnd} />
      <stop offset={1} stopColor={global.config.colors.iconDarkStart} />
    </linearGradient>
  </defs>
</svg>
)
export default AddressbookItemNote2Svg
