import React from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import upload from "../../../../../src/static/upload.svg";
import deletedraft from "../../../../../src/static/deletedraft.svg";
import Service from "./previewComponents/service";
import VehicleType from "./previewComponents/vehicleType";
import DataPreview from "./previewComponents/dataPreview";
import { Remove_draft_bulkImport, SetActiveImport, SetActiveImportData } from "../../../../Redux/actions/actionNames";
import { get_Id, get_id } from "../../../../helper/UserData/userdate";
import { BulkImportCsvUpload } from "../../../../helper/api";


const RequiredFilds = {
    Ondemand: [  'Pickup Address', 'Dropoff Address'],
    Pickup: [  'Pickup Address'],
    Delivery: [ 'Dropoff Address'],
    PickupDelivery: [ "Pickup Address", "Delivery Address"],

}


const init = {
    requireds: [],
    pureObjectCsv: null,
    percentUpload: 0
}


class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requireds: [],
            pureObjectCsv: null,
            percentUpload: 0
        }
    }


    requiredsCheck = (data) => {
        this.setState({
            pureObjectCsv: data
        })
        
        if (this.props.ActiveItem.orderType && (data || this.state.pureObjectCsv)) {
            let errorRequireds = false;
            let requireds = [];
            RequiredFilds[this.props.ActiveItem.orderType].forEach((key) => {
                if (!(key in data)) {
                    errorRequireds = true;
                    requireds.push(key)
                }
            })
            this.setState({
                errorRequireds,
                requireds
            })
            //this.props.ActiveItem.orderType
        }


    }


    removeDraft = () => {
        this.props.dispatch(SetActiveImport(null,null))
        this.props.dispatch(Remove_draft_bulkImport(this.props.ActiveItem.id))
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ActiveItem.id != this.props.ActiveItem.id && this.state.requireds.length > 0) {
            this.setState({
                ...init
            })
        }
    }

    getISREQUIREDtext=(text)=>{
        return String(this.props.t("isRequired")).replace("xxx", text)
    }


    upload = () => {
        if (  this.state.requireds.length == 0 && this.props.ActiveItem.orderType && (this.props.ActiveItem.orderType != "PickupDelivery" ? this.props.ActiveItem.vehicleType.selectedVehicleType : true) && this.props.ActiveItem.service.selectedService ) {
            var bodyFormData = new FormData();
            bodyFormData.append("csvFile", this.props.ActiveItem.File)
            bodyFormData.append("data", JSON.stringify({
                service: {
                    id: this.props.ActiveItem.service.selectedService.id, options: [
                        ...this.props.ActiveItem.service.options.map((op) => {
                            return { id: op.id, dataId: op.item ? op.item.id : null ,  inputValue: op.inputValue ? op.inputValue : null};
                        }),
                    ]
                },
                vehicleType: this.props.ActiveItem.orderType != "PickupDelivery" ? {
                    id: this.props.ActiveItem.vehicleType.selectedVehicleType.id, options: [
                        ...this.props.ActiveItem.vehicleType.options.map((op) => {
                            return { id: op.id, dataId: op.item ? op.item.id : "" };
                        }),
                    ]
                } : null,
                orderType: this.props.ActiveItem.orderType,
                customerId: get_Id()
            }))



            BulkImportCsvUpload(this.props.dispatch, bodyFormData , this.props.ActiveItem.id, (percent) => {
                this.props.setPercentUpload(percent)
                // this.setState({
                //     percentUpload: percent
                // })
            })

            this.props.dispatch(SetActiveImportData({
                id: this.props.ActiveItem.id,
                uploading:true ,
                status:"Uploading"
            }))
        }


    }


    render() {
        let { t } = this.props;
        

        return (

            <div className="filePreviewContainer">
                <div style={{ width: "100%", height: "auto", float: "left" }}>
                    <div className="PreviewtitleDescConatiner"> <span className="Previewtitle"> <Trans i18nKey={"previewTitle"}> </Trans> </span>
                        <span className="previewDesc"> <Trans i18nKey={"previewDesc"}> </Trans> </span>
                    </div>
                    <span className={`Upload ${(this.state.requireds.length == 0 && this.props.ActiveItem.orderType && (this.props.ActiveItem.orderType != "PickupDelivery" ? this.props.ActiveItem.vehicleType.selectedVehicleType : true) && this.props.ActiveItem.service.selectedService) ? "" : "uploadDisable"}`} onClick={this.upload} >  <span> <Trans i18nKey={"Upload"}> </Trans> </span> <img src={upload} /> </span>
                    <span className="Remove" onClick={this.removeDraft}>  <span> <Trans i18nKey={"Remove"}> </Trans> </span> <img src={deletedraft} /> </span>
                    <div style={{ float: 'left', width: "100%", height: "auto", marginBottom: "20px" }} className="filePreviewContainerServiceVehicleType">
                        <Service ActiveItem={this.props.ActiveItem} requiredsCheck={this.requiredsCheck} />
                        {this.props.ActiveItem.orderType != "PickupDelivery" && <VehicleType ActiveItem={this.props.ActiveItem} />}
                    </div>
                </div>

                {this.state.requireds.length > 0 &&
                    <div className="requiredsDataContainer">
                        {this.state.requireds.map((item) => {
                            return <p className="requiredsDataItem">  {this.getISREQUIREDtext(item)}</p>
                        })}
                    </div>
                }

                {/* <div className="requiredsDataContainer">
                     <p className="requiredsDataItem"> Pickup address column is required </p>
                     <p className="requiredsDataItem"> Pickup address column is required </p>

                </div> */}


                <DataPreview ActiveItem={this.props.ActiveItem} requiredsCheck={this.requiredsCheck} requiredsError={this.state.requireds.length} />


            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    vehicleTypes: state.Map.monitoring.vehicleTypes,
    serviceVehicleTypes: state.NewOrder.newOrder.service.serviceVehicleTypes,
    draftList: state.BulkImport.draftList,
    List: state.BulkImport.List,
    selectedItem: state.BulkImport.selectedItem,
});
const mapDispatchToProps = (dispatch) => ({
    dispatch: dispatch,
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(App));
