import * as React from "react"
const IconIcSupport = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <defs>
      <linearGradient id="aIconIcSupport" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconLightEnd} />
        <stop offset="100%" stopColor={global.config.colors.iconLightStart} />
      </linearGradient>
      <linearGradient id="bIconIcSupport" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconLightEnd} />
        <stop offset="100%" stopColor={global.config.colors.iconLightStart}/>
      </linearGradient>
      <linearGradient id="cIconIcSupport" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconDarkEnd} />
        <stop offset="100%" stopColor={global.config.colors.iconDarkStart} />
      </linearGradient>
      <linearGradient id="dIconIcSupport" x1="95.289%" x2="95.289%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconDarkEnd} />
        <stop offset="100%" stopColor={global.config.colors.iconDarkStart} />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M.5 0h19v19H.5z" />
      <g fillRule="nonzero">
        <path
          fill="url(#aIconIcSupport)"
          d="m15.932 12.898.158 1.227c0 .971-.805 1.766-1.788 1.766H9.386c-.74 0-1.341-.593-1.341-1.324 0-.732.6-1.325 1.34-1.325.584 0 1.08.369 1.265.883h3.652l.113-1.227c0-.53.222 0 .759 0 .536 0 .758-.53.758 0z"
          transform="translate(1.292 1.583)"
        />
        <path
          fill="url(#bIconIcSupport)"
          d="M16.09 8.828h-1.788V7.063c0-2.914-2.414-5.297-5.363-5.297-2.95 0-5.364 2.383-5.364 5.297v1.765H1.788V7.063C1.788 3.178 5.006 0 8.938 0c3.934 0 7.152 3.178 7.152 7.063v1.765z"
          transform="translate(1.292 1.583)"
        />
        <path
          fill="url(#cIconIcSupport)"
          stroke="url(#dIconIcSupport)"
          strokeWidth={0.894}
          d="M16.09 7.945h-1.788v5.297h1.788c.983 0 1.787-.794 1.787-1.765V9.71c0-.971-.804-1.766-1.787-1.766zM1.788 13.242h1.787V7.945H1.788C.804 7.945 0 8.74 0 9.711v1.766c0 .97.804 1.765 1.788 1.765z"
          transform="translate(1.292 1.583)"
        />
      </g>
    </g>
  </svg>
)
export default IconIcSupport
