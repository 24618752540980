import React, { useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import { isEmpty } from 'lodash'
import ProgressIndicator from "../../../../design-system/lib/ProgressIndicator";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let stylee ={
      background: global.config.colors.secondaryColor,
      color: global.config.colors.secondaryTextColor
    }
    if (this.props.OrderListLoading && isEmpty(this.props.OrderList)) {
      return (
        <div className="NoOrderContainer" style={{width:"100%",height:"100vh",margin:"0px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",top:"0px"}}>
          {/* <div className="NoOrderInner mrb90">
          <div className="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> 
            <span className="NoOrderTitle">
              {" "}
              <Trans i18nKey={"loadingList"}> </Trans>{" "}
            </span>
          </div> */}
          <ProgressIndicator theme="On_Light" style={{width:"32px",height:"32px"}} />
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  OrderList : state.OrderList.OrderList,
  OrderListLoading : state.OrderList.OrderListLoading ,
  OrderListError : state.OrderList.OrderListError ,
  page:state.OrderList.page

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
