import * as React from 'react'
import styles from './style.module.css';
import clsx from 'clsx';
import PickupNewOrder from '../pickup/index';
import { useDispatch } from 'react-redux';
import { UseCRUDDropoffs } from '../../../../../service/hooks/useCRUD_Dropoffs';
import { Set_ActivePickupDropOff } from '../../../../../Redux/actions/actionNames';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeliveryNewOrder from '../delivery';
import AddDropOff from '../addDropOff';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: 0,
  margin: `0`,
  float: "left",
  width: "100%",
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: "#fbfdff",
  padding: 0,
  width: "100%",
  float: "left",
  position: "relative",
  marginTop: "2px"
});

const queryAttr = "data-rbd-drag-handle-draggable-id";


function DeliveryContainers({ handleCollapse, orderTypeActive, addDropOff, removeDrop }) {
  let { items, sEtItems } = UseCRUDDropoffs()
  const dispatch = useDispatch();
  const [placeholderProps, setPlaceholderProps] = React.useState({});

   const onDragEnd = React.useCallback((result) => {
    //  dispatch(Set_ActivePickupDropOff({
    //    id: "",
    //    type: null
    //  }));

     if (!result.destination) {
       return;
     }

     const itemsx = reorder(
       items,
       result.source.index,
       result.destination.index
     );

     sEtItems(itemsx)
     setPlaceholderProps({})
   }, [items]);

   const onDragUpdate = React.useCallback((update) => {
     if (!update.destination) {
       return;
     }
     const draggableId = update.draggableId;
     const destinationIndex = update.destination.index;

     const domQuery = `[${queryAttr}='${draggableId}']`;
     const draggedDOM = document.querySelector(domQuery);

     if (!draggedDOM) {
       return;
     }

     const { clientHeight, clientWidth } = draggedDOM;
     const clientY = parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) + [...draggedDOM.parentNode.children]
       .slice(0, destinationIndex)
       .reduce((total, curr) => {
         const style = curr.currentStyle || window.getComputedStyle(curr);
         const marginBottom = parseFloat(style.marginBottom);
         return total + curr.clientHeight + marginBottom;
       }, 0);

     setPlaceholderProps({
       clientHeight,
       clientWidth,
       clientY,
       clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft)
     });
   }, [items]);

  return (
    <div className={clsx(styles[orderTypeActive])}>

      <PickupNewOrder
        handleCollapse={handleCollapse} />


      <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
        <Droppable droppableId="droppableNewOrder">
          {(providedx) => (
            <div ref={providedx.innerRef} {...providedx.droppableProps}>
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={items.length == 1}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <DeliveryNewOrder
                        dropOffsCount={items.length}
                        isDraggingOver={snapshot.isDragging}
                        id={item.id}
                        handleCollapse={handleCollapse}
                        last={items.length - 1 == index}
                        {...item}
                        index={index}
                        first={index == 0}
                        addDropOff={addDropOff}
                        removeDrop={removeDrop}
                        dropOffCount={items.length}
                        orderTypeActive={orderTypeActive}
                      />

                    </div>
                  )}
                </Draggable>
              ))}
              {providedx.placeholder}
            </div>
          )}
        </Droppable>

      </DragDropContext>


       <AddDropOff orderTypeActive={orderTypeActive} addDropOff={addDropOff} /> 

    </div>
  )
}

export default DeliveryContainers