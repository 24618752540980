import React, { useState } from "react";
import Slide from "@material-ui/core/Slide";
import { connect } from "react-redux";
import { get_customerPannel_type, get_lang, get_LeftRightMenu } from "../../../helper/UserData/userdate";
import { CanShowSetting, getLogoTypeByUrl } from "../../../helper/module";
import Drawer from "@material-ui/core/Drawer";
import {
  OpenCloseMenu,
  openCloseTransactionList,
  openCloseAboutUs,
  openCloseOrderHistory,
  OpenCloseSetting,
  OpenCloseSupport,
  OpenCloseMessageBox,
} from "../../../Redux/actions/actionNames";
import { ReactComponent as Orderhistory } from "../../../static/icon-ic-clock-menu.svg";
import { ReactComponent as Transactions } from "../../../static/icon-ic-balance-card-menu.svg";
import { ReactComponent as Settings } from "../../../static/icon-ic-settings-menu.svg";
import { ReactComponent as Aboutus } from "../../../static/icon-ic-about-us-menu.svg";
import { ReactComponent as Card } from "../../../static/ic-credit-card-menu.svg";
import { ReactComponent as Invoices } from "../../../static/ic-invoice-menu.svg";
import { ReactComponent as Support } from "../../../static/ic_support.svg";
import { ReactComponent as Box_outlinebox_outline } from "../../../static/box_outlinebox_outline.svg";

import { withTranslation, Trans } from "react-i18next";
import { Api_get_balance_AGW, Api_Get_Transactions_AGW } from "../../../helper/api/ApiGateway";
import Wallet, { MapHoc } from "./wallet";
import PaymentMethods from "./PaymentMethods";
import Addressbook from "./Addressbook";
import NewAddress from "./Addressbook/newAddress";
import { Invoive } from "../../../components/Menu/Invoive";

import { ReactComponent as WalletIco } from "../../../static/mutual-wallet-balance.svg";
import { ReactComponent as AddressbookImg } from "../../../static/icon-ic-address-book-menu.svg";


const styles = theme => ({
  ModalProps: {
    BackdropProps: {
      backgroundColor: "red"
    }
  }
});


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openInvoicesDialoge: false
    };
  }

  TransactionList_get = () => {
    Api_Get_Transactions_AGW(
      this.props.dispatch,
      {
        page: this.props.Transactions_page,
      },
      (call) => {
      }
    );
  };

  handleOpenInvoicesDialoge = () => {
    this.setState({
      openInvoicesDialoge: !this.state.openInvoicesDialoge,
    });
  };

  onclose = () => {
    this.setState({
      OpenAddressbook: false,
    }, () => {
      this.props.dispatch(OpenCloseMenu(false));
    });

  };

  openOrderHistory = () => {
    this.props.dispatch(OpenCloseMenu(false));
    this.props.dispatch(openCloseOrderHistory(true));
  };

  openOrderHistory = () => {
    this.props.dispatch(OpenCloseMenu(false));
    this.props.dispatch(openCloseOrderHistory(true));
  };

  openPaymentMeyhods = () => {
    this.props.dispatch(OpenCloseMenu(false));

    this.setState({
      openPaymentMeyhods: true
    })
  };


  PaymentMethodsonclose = () => {
    this.props.dispatch(OpenCloseMenu(false));

    this.setState({
      openPaymentMeyhods: false
    })
  }

  openTransactions = () => {
    this.props.dispatch(OpenCloseMenu(false));
    this.TransactionList_get();
    this.props.dispatch(openCloseTransactionList(true));
  };

  openSettings = () => {
    this.props.dispatch(OpenCloseMenu(false));
    this.props.dispatch(OpenCloseSetting(true));
  };

  openAboutus = () => {
    this.props.dispatch(OpenCloseMenu(false));
    this.props.dispatch(openCloseAboutUs(true));
  };


  openSupport = () => {
    this.props.dispatch(OpenCloseMenu(false));
    this.props.dispatch(OpenCloseSupport(true));
  };


  openMessageBox = () => {
    this.props.dispatch(OpenCloseMenu(false));
    this.props.dispatch(OpenCloseMessageBox(true));
  };


  componentWillReceiveProps(nextProps) {
    nextProps.open && !this.props.open && Api_get_balance_AGW(this.props.dispatch, {}, (call) => { });

  }

  handleShowIncBalance = (bool) => {
    this.setState({
      OpenWallet: bool,
    }, () => {
      this.props.dispatch(OpenCloseMenu(false));
    });
  };

  handleShowAddressbook = (bool) => {
    this.setState({
      OpenAddressbook: bool,
    }, () => {
      this.props.dispatch(OpenCloseMenu(false));
    });
  };


  render() {

    return (
      <React.Fragment>

        <MapHoc><Wallet ShowComponent={false} OpenDialoge={this.state.OpenWallet} onClose={() => this.handleShowIncBalance(false)} /></MapHoc>
        
        <Addressbook ShowComponentMenu={false} OpenDialoge={this.state.OpenAddressbook} onclose={this.onclose} onClick={this.onclose} />
        {
          global.config.stripePublicApiKey && <PaymentMethods OpenDialoge={this.state.openPaymentMeyhods} onclose={this.PaymentMethodsonclose} />
        }

        <Drawer
          BackdropProps={{ invisible: true }}
          transitionDuration={400}
          anchor={get_LeftRightMenu()}
          open={this.props.open}
          mountOnEnter
          onClose={this.onclose}
        >
          <div className="MenuContainer">
            <div className="MenuHeaderContainer">
              <img onClick={this.openOrderHistory} src={global.config.logoTypeUrl} />
            </div>
            <div className="headerItemsContainer">

              <div
                onClick={() => this.handleShowIncBalance(true)}
                className="headerItemContainer"
              >
                <WalletIco className="img wallet" />
                {/* <img src={wallet} /> */}
                <span className="span">
                  {" "}
                  <Trans i18nKey={"Wallett"}> </Trans>{" "}
                </span>
                <p className="Balance mouyp" >
                  <Trans i18nKey={"Balance"}> </Trans> :{" "}
                  <span>
                    {" "}
                    {this.props.balancecurrencyCode
                      ? Number(this.props.balance).toLocaleString(get_lang(), {
                        style: "currency",
                        currency: this.props.balancecurrencyCode,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                      : this.props.balance}{" "}
                  </span>{" "}
                </p>
              </div>


              {
                global.config.stripePublicApiKey && <div
                  className="headerItemContainer"
                  onClick={this.openPaymentMeyhods}
                >
                  <Card className="img Card" />
                  {/* <img src={card} /> */}
                  <span className="span">
                    {" "}
                    <Trans i18nKey={"paymentmethods"}> </Trans>{" "}
                  </span>
                </div>
              }


              {get_customerPannel_type() == "business" && <div
                className="headerItemContainer"
                onClick={this.handleOpenInvoicesDialoge}
              >
                <Invoices className="img Invoices" />
                <span className="span">
                  {" "}
                  <Trans i18nKey={"Invoices"}> </Trans>{" "}
                </span>
              </div>
              }


              <div
                className="headerItemContainer"
                onClick={this.openOrderHistory}
              >
                <Orderhistory className="img Orderhistory" />
                <span className="span">
                  {" "}
                  <Trans i18nKey={"Orderhistory"}> </Trans>{" "}
                </span>
              </div>

              <div
                className="headerItemContainer"
                onClick={this.openTransactions}
              >
                <Transactions className="img Transactions" />
                <span className="span">
                  {" "}
                  <Trans i18nKey={"Transactions"}> </Trans>{" "}
                </span>
              </div>

              <div
                className="headerItemContainer"
                onClick={() => this.handleShowAddressbook(true)}
              >
                <AddressbookImg className="img AddressbookImg" />
                <span className="span">
                  {" "}
                  <Trans i18nKey={"Addressbook"}> </Trans>{" "}
                </span>
              </div>

{/* 
      <div className="headerItemContainer" onClick={this.openMessageBox}>
                <Box_outlinebox_outline className="img Aboutus" />
                <span className="span">
                  {" "}
                  <Trans i18nKey={"MessageBox"}> </Trans>{" "}
                </span>
              </div> */}

           
              
              <div className="headerItemContainer" onClick={this.openSupport}>
                <Support className="img Aboutus" />
                <span className="span">
                  {" "}
                  <Trans i18nKey={"Support"}> </Trans>{" "}
                </span>
              </div>


        

              <div className="headerItemContainer" onClick={this.openSettings}>
                <Settings className="img Settings" />
                <span className="span">
                  {" "}
                  <Trans i18nKey={"Settings"}> </Trans>{" "}
                </span>
              </div>

              <div className="headerItemContainer" onClick={this.openAboutus}>
                <Aboutus className="img Aboutus" />
                <span className="span">
                  {" "}
                  <Trans i18nKey={"Aboutus"}> </Trans>{" "}
                </span>
              </div>


            </div>
          </div>
        </Drawer>

        <NewAddress />

        {get_customerPannel_type() == "business" && <Invoive open={this.state.openInvoicesDialoge} handleOpen={this.handleOpenInvoicesDialoge} />}

      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  open: state.SharedData.openMenu,
  Transactions_page: state.Transactions.page,
  balancecurrencyCode: state.NewOrder.newOrder.balance.currencyCode,
  currencyCode: state.SharedData.WebSettings
    ? state.SharedData.WebSettings.currencyCode
    : null,
  balance: state.NewOrder.newOrder.balance.balance,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
