import React from "react";
import { Map, Marker, TileLayer, Popup, Polyline } from "react-leaflet";
import L from "leaflet";
import { connect } from "react-redux";
import { renderToStaticMarkup } from 'react-dom/server'
import PinFavoriteSvg from "../../../../components/svg/pinFavoriteSvg";

// const createtIcon = (icon) => {
//   return new L.Icon({
//     iconUrl: icon,
//     iconAnchor: [26, 54],
//     popupAnchor: [0, -35],
//     iconSize: [52, 52],
//     shadowSize: [29, 40],
//     shadowAnchor: [7, 40],
//   });
// };


const createtIcon = () => {
  return new L.divIcon({
    className: "markerIcon ",
    html: `${renderToStaticMarkup( <PinFavoriteSvg />)}`,
    iconAnchor: [20.5, 60],
    popupAnchor: [0, -35],
    iconSize: [65, 65],
    shadowSize: [29, 40],
    shadowAnchor: [7, 40],
  });
};


class App extends React.Component {
 
  render() {
    let adresss_loc = this.props.adresss_loc && (this.props.openMenu || this.props.openMenuOrder ) ;

    return (
      <React.Fragment>
           {adresss_loc ? (
            <Marker
              animate={true}
              position={this.props.adresss_loc}
              icon={createtIcon()}
            ></Marker>
          ) : null}


{this.props.EditAddressbook ? (
            <Marker
              animate={true}
              position={{lat : this.props.EditAddressbook[1], lng:this.props.EditAddressbook[0]}}
              icon={createtIcon()}
            ></Marker>
          ) : null}

       </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  openMenu: state.Addressbook.openAddAddressBookInMenu ,
  openMenuOrder: state.Addressbook.openNewAddressBookInNewOrder ,
  EditAddressbook: state.Addressbook.EditAddressBookInMenuDetails.coordinates ,

  adresss_loc: state.Addressbook.NewAddressBookInNewOrderDetails.adresss_loc ,
  mapRef: state.Map.mapRef,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
