import React, { useCallback, useEffect, useRef } from 'react'
import ProgressIndicator from '../../../design-system/lib/ProgressIndicator'







function TableLoading() {

  return (
    <div className='jojojoj'
      style={{ display: 'flex', position: 'relative', height: `calc(100vh - 104px)`, width: "100%", flexDirection: 'column', transition: "all .3s ease-in-out" ,justifyContent:"center",alignItems:"center"}}>
          <ProgressIndicator theme='On_Light' style={{width:"50px",height:"50px"}} />
    </div>
  )
}
export default TableLoading