import React from "react";

function CardsSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 42 42"
      {...props}
    >
      <path
        fill="url(#lhrmt0xe2a)"
        fillRule="evenodd"
        d="M35.438 12.95H17.061a6.562 6.562 0 00-6.562 6.563v10.5a6.562 6.562 0 006.563 6.562h18.375A6.562 6.562 0 0042 30.013v-10.5a6.562 6.562 0 00-6.563-6.563z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#5kb2cop9eb)"
        fillRule="evenodd"
        d="M8.02 4.2h22.46a8.02 8.02 0 018.02 8.021v12.833a8.02 8.02 0 01-8.02 8.021H8.02A8.02 8.02 0 010 25.055V12.22A8.02 8.02 0 018.02 4.2z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#0k3vqmkasc)"
        fillRule="evenodd"
        d="M10.427 21.846a2.406 2.406 0 110 4.812 2.406 2.406 0 010-4.812z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#8h7djmze9d)"
        fillRule="evenodd"
        d="M4.812 12.22h28.875a1.604 1.604 0 010 3.209H4.812a1.604 1.604 0 110-3.208z"
        clipRule="evenodd"
      ></path>
      <defs>
        <linearGradient
          id="lhrmt0xe2a"
          x1="42"
          x2="42"
          y1="12.95"
          y2="36.575"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={global.config.colors.iconDarkEnd}></stop>
          <stop offset="1" stopColor={global.config.colors.iconDarkStart}></stop>
        </linearGradient>
        <linearGradient
          id="5kb2cop9eb"
          x1="0"
          x2="0"
          y1="4.2"
          y2="33.075"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={global.config.colors.iconLightEnd}></stop>
          <stop offset="1" stopColor={global.config.colors.iconLightStart}></stop>
        </linearGradient>
        <linearGradient
          id="0k3vqmkasc"
          x1="8.021"
          x2="8.021"
          y1="21.846"
          y2="26.658"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={global.config.colors.iconDarkEnd}></stop>
          <stop offset="1" stopColor={global.config.colors.iconDarkStart}></stop>
        </linearGradient>
        <linearGradient
          id="8h7djmze9d"
          x1="3.208"
          x2="3.208"
          y1="12.221"
          y2="15.429"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={global.config.colors.iconDarkEnd}></stop>
          <stop offset="1" stopColor={global.config.colors.iconDarkStart}></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default CardsSvg;
