import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";


class App extends Component {
    render() {
        return (
            <pickupropoffsContainer style={{minHeight :"auto"}}>
                <pinsContainer style={{ borderLeft: "0px",height:"auto" }}>
                    <squre style={{marginTop:"1px" ,
                    backgroundColor : global.config.colors.iconDarkEnd
                }}/>
                </pinsContainer>

                <addresss>
                    <dropoffAddress style={{marginTop:"0px"}}>{this.props.dropoff.address}</dropoffAddress>
                </addresss>
            </pickupropoffsContainer>
        );
    }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
    dispatch: dispatch,
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(App));
