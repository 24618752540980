import * as React from 'react'
import styles from './style.module.css';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import IconProvider from '../../../../../design-system/lib/IconProvider';
import Typography from '../../../../../design-system/lib/Typography';
import { ReactComponent as ADD_SQUARE } from '../../../../../static/IconProvider/add-square.svg';
import IconProviderSvgModifier from '../../../../../design-system/lib/IconProviderSvgModifier';




function AddDropOff({ orderTypeActive, addDropOff }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  if (orderTypeActive == "Ondemand") {
    return (
      <div className={styles.AddDropOff} onClick={addDropOff}>
        <hr className={styles.hr} />
        <IconProviderSvgModifier Icon={ADD_SQUARE} size='16' />
        <Typography weight='Body_Middle_Medium' text={t("Add")} textColor='TextDark' />
{/* 
        <div className={styles.ROUTE_OPTIMIZE}>
        <IconProvider Icon={ROUTE_OPTIMIZE} size='16' />
        <Typography weight='Body_Middle_Bold' text={t("OptimizeDropoffs")} textColor='Primary' />
        </div> */}
      </div>
    )
  } else {
    return null
  }
}

export default AddDropOff