import React from "react";

const DropOffPin = ({ color = "red", style, bigSize }) => {

  return <svg width={bigSize ? "30" : "20"}
    height={bigSize ? "54" : "36"} viewBox="0 0 21 37" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ ...style }}>
    <path d="M8.625 19.526a1 1 0 1 1 2 0v16a1 1 0 1 1-2 0v-16z" fill={global.config.colors.iconDarkStart} />
    <rect x=".625" y=".526" width="20" height="20" rx="4" fill={global.config.colors.iconDarkStart} />
    <rect x="7.625" y="7.526" width="6" height="6" rx="1.5" fill="#fff" />
  </svg>


};

export default DropOffPin



