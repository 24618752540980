import React, { useState } from "react";
import onroLogo from "./../../static/logotype-on-light-customer.svg";
import banner from "./../../static/delivery-5.jpg";
import { withTranslation, Trans } from "react-i18next";
import { getLogoTypeByUrl, LoginBannerWithDomain, LoginTextWithDomain, ShowCustomerPanelTitle } from "../../helper/module";

class App extends React.Component {
  render() {
    return (
      <div className={`FirstSectionContainer `} style={{ backgroundImage: `linear-gradient(to bottom, ${global.config.colors.loginBackgroundColorStart}, ${global.config.colors.loginBackgroundColorEnd})` }}>
        {/* <img src={global.config.showLogotypeInLogin ? (global.config.showLightLogoType ? global.config.lightLogoType  : global.config.logoTypeUrl ) : null} className="LoginlogoType" /> */}
        {/* {global.config.ShowCustomerPanelTitle && <span className="customerPanel">
          {String(global.config.ShowCustomerPanelTitleText).length > 0 ? global.config.ShowCustomerPanelTitleText : <Trans i18nKey={"Cutomerpanel"}> </Trans>}
        </span>} */}
        {LoginTextWithDomain(this.props.t)}
        <img src={global.config.loginBannerUrl} className={`banner `} />
        {/* { global.config.ShowPowerOnro && <a className="PoweredBy" href="http://onro.io/" style={{ color: global.config.colors.LoginTextColor }}>
          {" "}
          <Trans i18nKey={"PoweredBy"}> </Trans> <span style={{ fontWeight: "bold" }}>Onro.io</span>
        </a>} */}
      </div>
    );
  }
}

export default withTranslation()(App);
