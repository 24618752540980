import * as React from "react"
const IcAddCreditCardSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <mask
      id="bIcAddCreditCardSvg"
      width={20}
      height={15}
      x={0}
      y={2}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path
        fill="url(#aIcAddCreditCardSvg)"
        fillRule="evenodd"
        d="M15.352 2.501H4.04C1.81 2.501 0 4.26 0 6.429v6.284c0 2.17 1.809 3.928 4.04 3.928h9.424a4.242 4.242 0 1 1 5.929-6.066V6.429c0-2.17-1.81-3.928-4.04-3.928z"
        clipRule="evenodd"
      />
    </mask>
    <g mask="url(#bIcAddCreditCardSvg)">
      <path fill="url(#cIcAddCreditCardSvg)" d="M0 2.03h19.392v15.083H0z" />
    </g>
    <mask
      id="dIcAddCreditCardSvg"
      width={20}
      height={13}
      x={1}
      y={5}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M1.941 6.743c0-.52.422-.942.943-.942h13.629a.943.943 0 0 1 0 1.885H2.884a.943.943 0 0 1-.943-.943zm2.908 4.714c0-.52.422-.942.943-.942h.053a.943.943 0 1 1 0 1.885h-.053a.943.943 0 0 1-.943-.943zM20 13.847a3.333 3.333 0 1 1-6.666 0 3.333 3.333 0 0 1 6.666 0zm-2.936.375v1.352a.396.396 0 0 1-.398.394.396.396 0 0 1-.397-.394v-1.352h-1.363a.4.4 0 0 1-.36-.396.4.4 0 0 1 .36-.396h1.359v-1.347a.4.4 0 0 1 .4-.357.4.4 0 0 1 .399.357v1.347h1.363a.4.4 0 0 1 .36.396.4.4 0 0 1-.36.396h-1.363z"
        clipRule="evenodd"
      />
    </mask>
    <g mask="url(#dIcAddCreditCardSvg)">
      <path fill="url(#eIcAddCreditCardSvg)" d="M1.25 5.764h18.748v12.499H1.25z" />
    </g>
    <defs>
      <linearGradient
        id="aIcAddCreditCardSvg"
        x1={0}
        x2={0}
        y1={2.501}
        y2={16.641}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={global.config.colors.iconDarkEnd} />
        <stop offset={1} stopColor={global.config.colors.iconDarkStart} />
      </linearGradient>
      <linearGradient
        id="cIcAddCreditCardSvg"
        x1={0}
        x2={0}
        y1={2.03}
        y2={17.113}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={global.config.colors.iconLightEnd} />
        <stop offset={1} stopColor={global.config.colors.iconLightStart} />
      </linearGradient>
      <linearGradient
        id="eIcAddCreditCardSvg"
        x1={1.25}
        x2={1.25}
        y1={5.764}
        y2={18.263}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={global.config.colors.iconDarkEnd} />
        <stop offset={1} stopColor={global.config.colors.iconDarkStart} />
      </linearGradient>
    </defs>
  </svg>
)
export default IcAddCreditCardSvg
