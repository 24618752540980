import React, { useState } from "react";
import PerfectScrollbar from "perfect-scrollbar";
import debounce from "lodash/debounce";
import Order from "./Order";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import Fade from "@material-ui/core/Fade";


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.ps = null;
  }

  loadMore = () => {
    this.props.loadMore()
  };


  componentDidMount(){
    try {
      const container = document.getElementById("OrderHistoyList");
      this.ps = new PerfectScrollbar(container);
      container.addEventListener("ps-y-reach-end", () => {
        this.loadMore();
      });
    } catch (error) {
    }
  }

  

  getList = () => {
    return Object.keys(this.props.OrderHistoryList).map(
      (key) => this.props.OrderHistoryList[key]
    );
  };


  render() {
    let stylee ={
      background: global.config.colors.secondaryColor,
      color: global.config.colors.secondaryTextColor
    }
    return <div className="OrderListMaperContainer">

          <div className="OrderLists OrderHistory" id="OrderHistoyList">

          {this.getList().map((order, i) => {
            return <Order key={i} data={order} {...order}/>;
          })}

           </div> 
           <Fade
          in={this.props.OrderHistoryListLoading && !isEmpty(this.props.OrderHistoryList)}
        >
          <div
            className="NoOrderContainer"
            style={{ position: "absolute", backgroundColor: "#e3eef76b" }}
          >
            <div className="NoOrderInner mrb90">
            <div className="lds-grid"><div style={stylee}></div><div style={stylee}></div><div style={stylee}></div><div style={stylee}></div><div style={stylee}></div><div style={stylee}></div><div style={stylee}></div><div style={stylee}></div><div style={stylee}></div></div> 

            </div>
          </div>
        </Fade>
    </div>;
  }
}

const mapStateToProps = (state) => ({
  open: state.OrderHistory.OrderListOpenDialoge,
  OrderHistoryList: state.OrderHistory.OrderHistoryList,
  OrderHistoryListLoading: state.OrderHistory.OrderHistoryListLoading,
  OrderHistoryListError: state.OrderHistory.OrderHistoryListError,
  page: state.OrderHistory.page,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
