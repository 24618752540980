import { isEmpty } from "lodash";
import { getLangByUrl,getFlagByUrl, getDefaultLanguageWithGlobal, getDefaultDirectionWithGlobal } from "../module";

export function Set_Person_Info(data) {
  set_customerPannel_type(data.type);
  data.accessToken && set_accessToken(data.accessToken);
  data.refreshToken && set_refreshToken(data.refreshToken);
  data.avatarPhoto && set_avatarPhoto(data.avatarPhoto);
  data.customerId && set_Id(data.customerId);
  data.email && set_email(data.email);
  data.familyName && set_familyName(data.familyName);
  data.givenName && set_givenName(data.givenName);
  data.phone && set_phone(data.phone);
}

export function Set_Person_Account_Info(data) {
  set_customerPannel_type(data.type);
  data.email && set_email(data.email);
  data.familyName && set_familyName(data.familyName);
  data.givenName && set_givenName(data.givenName);
  data.phone && set_phone(data.phone);
  data.permissions && Set_Permisssion(data.permissions);
  data.name && set_bussiness_title(data.name);
  data.username && set_username(data.username);
  data.address && set_address(data.address);
  set_minimumBalance(data.minimumBalance);
}

export function Set_Permisssion(data) {
  localStorage.setItem("Permisssion", JSON.stringify(data));
}

export function Get_Permisssion() {
  return JSON.parse(localStorage.getItem("Permisssion"));
}

export function Set_Bussiness_Account_Info(data) {
  data.email && set_email(data.email);
  data.title && set_bussiness_title(data.title);
  data.username && set_username(data.username);
  data.phone && set_phone(data.phone);

}

export function Set_Bussiness_Info(data) {
  set_customerPannel_type("business");
  data.accessToken && set_accessToken(data.accessToken);
  data.refreshToken && set_refreshToken(data.refreshToken);
  data.id && set_Id(data.id);
  data.customerId && set_Id(data.customerId);


}

export function reset() {
  localStorage.clear();
  window.location.reload();
}



export function set_bussiness_title(data) {
  localStorage.setItem("title", data);
}

export function get_bussiness_title() {
  return localStorage.getItem("title");
}



export function set_customerPannel_type(data) {
  localStorage.setItem("customerPannel_type", data);
}

export function get_customerPannel_type() {
  return localStorage.getItem("customerPannel_type") || "individual";
}

export function set_avatarPhoto(data) {
  localStorage.setItem("avatarPhoto", data);
}

export function get_avatarPhoto() {
  return localStorage.getItem("avatarPhoto");
}

export function set_email(data) {
  localStorage.setItem("email", data);
}

export function get_email() {
  return localStorage.getItem("email");
}

export function set_familyName(data) {
  localStorage.setItem("familyName", data);
}

export function get_familyName() {
  return localStorage.getItem("familyName");
}

export function set_givenName(data) {
  localStorage.setItem("givenName", data);
}

export function get_givenName() {
  return localStorage.getItem("givenName");
}

export function set_phone(data) {
  localStorage.setItem("phone", data);
}

export function get_phone() {
  return localStorage.getItem("phone");
}

export function set_username(data) {
  localStorage.setItem("username", data);
}

export function get_username() {
  return localStorage.getItem("username");
}

export function set_address(data) {
  localStorage.setItem("address", data);
}

export function get_address() {
  return localStorage.getItem("address");
}

export function set_name(data) {
  localStorage.setItem("name", data);
}

export function get_name() {
  return localStorage.getItem("name");
}

export function set_minimumBalance(data) {
  localStorage.setItem("minimumBalance", data);
}

export function get_minimumBalance() {
  return localStorage.getItem("minimumBalance");
}

export function set_accessToken(data) {
  localStorage.setItem("accessToken", data);
}

export function get_accessToken() {
  return localStorage.getItem("accessToken") || null;
}

export function set_refreshToken(data) {
  localStorage.setItem("refreshToken", data);
}

export function get_refreshToken() {
  return localStorage.getItem("refreshToken");
}

export function set_defaultLocation(data) {
  localStorage.setItem("defaultLocation", JSON.stringify(data));
}

export function get_defaultLocation() {
  return JSON.parse(localStorage.getItem("defaultLocation"));
}

export function set_currencyCode(data) {
  localStorage.setItem("currencyCode", data);
}

export function get_currencyCode() {
  return localStorage.getItem("currencyCode");
}

export function set_lang(data) {
  localStorage.setItem("lang", data);
}

export function get_lang() {
  return localStorage.getItem("lang") ? localStorage.getItem("lang") : getDefaultLanguageWithGlobal(global.config.defaultLanguage).lang;
}
export function set_direction(data) {
  localStorage.setItem("direction", data);
}

export function get_cssFlag() {
  return localStorage.getItem("cssFlag") || getDefaultLanguageWithGlobal(global.config.defaultLanguage).cssFlag;
}
export function set_cssFlag(data) {
  localStorage.setItem("cssFlag", data);
}

export function get_country() {
  return localStorage.getItem("country") || "US";
}
export function set_country(data) {
  localStorage.setItem("country", data);
}

export function get_direction() {
  return localStorage.getItem("direction") ? localStorage.getItem("direction")  : getDefaultDirectionWithGlobal(global.config.defaultLanguage);
}

export function set_calendarType(data) {
  localStorage.setItem("calendarType", data);
}

export function get_calendarType() {
  return localStorage.getItem("calendarType");
}

export function set_Id(data) {
  localStorage.setItem("id", data);
}

export function get_Id() {
  return localStorage.getItem("id");
}

export function set_project(data) {
  localStorage.setItem("project", JSON.stringify(data));
}

export function get_project() {
  return JSON.parse(localStorage.getItem("project"));
}

export function get_LeftRight() {
  switch (localStorage.getItem("direction")) {
    case "Ltr":
      return "right";
      break;
    case "Rtl":
      return "left";
      break;
    default:
      return "right";
  }
}


export function get_LeftRightMenu() {
  switch (localStorage.getItem("direction")) {
    case "Ltr":
      return "left";
      break;
    case "Rtl":
      return "right";
      break;
    default:
      return "left";
  }
}


export function get_defaultProjectDev() {
  if(!process.env.NODE_ENV || process.env.NODE_ENV === "development" ){
      return localStorage.getItem("defaultProjectDev");
  }else{
    return null
  }
}

export function set_defaultProjectDev(project) {
  localStorage.setItem("defaultProjectDev", project);

}