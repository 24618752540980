import * as React from "react"
const MutualIcAddPhoto = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <defs>
      <linearGradient id="aMutualIcAddPhoto" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconLightEnd} />
        <stop offset="100%" stopColor={global.config.colors.iconLightStart} />
      </linearGradient>
      <linearGradient id="bMutualIcAddPhoto" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconDarkEnd} />
        <stop offset="100%" stopColor={global.config.colors.iconDarkStart} />
      </linearGradient>
      <linearGradient id="cMutualIcAddPhoto" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconDarkEnd} />
        <stop offset="100%" stopColor={global.config.colors.iconDarkStart} />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="nonzero">
      <path
        fill="url(#aMutualIcAddPhoto)"
        d="M8.889 3.333c0 3.062 2.515 5.556 5.555 5.556 1.24 0 2.4-.425 3.334-1.135v4.765c0 3.146-2.024 5.259-5.038 5.259H5.037C2.024 17.778 0 15.665 0 12.519V5.26C0 2.114 2.024 0 5.037 0l4.98-.001A5.52 5.52 0 0 0 8.89 3.333z"
        transform="translate(0 2.222)"
      />
      <path
        fill="url(#bMutualIcAddPhoto)"
        d="M11.39 9.55c.198-.262.935-1.082 1.887-.498.607.367 1.117.865 1.663 1.397.209.204.358.436.456.684.298.75.143 1.65-.175 2.393a3.261 3.261 0 0 1-2.01 1.843c-.403.13-.827.187-1.25.187H4.47c-.746 0-1.405-.175-1.946-.503-.339-.206-.399-.679-.147-.987.42-.513.834-1.028 1.253-1.548.797-.995 1.334-1.283 1.931-1.03.243.104.486.262.736.428.667.445 1.594 1.056 2.815.393.84-.463 1.325-1.258 1.749-1.946.173-.28.338-.56.53-.813zM5.43 3.333c1.224 0 2.22 1.006 2.22 2.24a2.233 2.233 0 0 1-2.22 2.239c-1.226 0-2.222-1.005-2.222-2.239s.996-2.24 2.222-2.24z"
        transform="translate(0 2.222)"
      />
      <path
        fill="url(#cMutualIcAddPhoto)"
        d="M4.444 8.889c2.415 0 4.445-2.012 4.445-4.445C8.889 2.012 6.885 0 4.444 0 2.012 0 0 2.012 0 4.444 0 6.894 2.012 8.89 4.444 8.89z"
        transform="translate(10 1.111)"
      />
      <path
        fill="#FFF"
        d="M14.453 8.333c-.306 0-.576-.202-.576-.513V6.069h-1.669c-.297 0-.541-.236-.541-.514 0-.277.244-.513.541-.513h1.669V3.3c0-.32.27-.522.576-.522.297 0 .568.202.568.522v1.741h1.668c.297 0 .533.236.533.513a.526.526 0 0 1-.533.514h-1.668v1.75c0 .312-.27.514-.568.514z"
      />
    </g>
  </svg>
)
export default MutualIcAddPhoto
