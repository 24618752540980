import * as React from "react"
const Profile_place_holder = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <circle cx={29} cy={29} r={36} fill="url(#a)" opacity={0.3} />
    <path
          style={props.stylepath}

      fill="url(#b)"
      d="M18.547 21.492c-4.745 0-8.797.748-8.797 3.74 0 2.994 4.027 3.768 8.797 3.768 4.744 0 8.796-.747 8.796-3.74 0-2.993-4.026-3.768-8.796-3.768"
    />
    <path
      fill="url(#c)"
      style={props.stylepath}
      d="M18.547 18.642a5.8 5.8 0 0 0 5.82-5.82A5.8 5.8 0 0 0 18.548 7a5.801 5.801 0 0 0-5.822 5.821c0 3.23 2.591 5.821 5.822 5.821"
    />
    <defs>
      <linearGradient
        id="a"
        x1={0}
        x2={0}
        y1={0}
        y2={36}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={global.config.colors.iconLightEnd} />
        <stop offset={1} stopColor={global.config.colors.iconLightStart} />
      </linearGradient>
      <linearGradient
        id="b"
        x1={9.75}
        x2={9.75}
        y1={21.492}
        y2={29}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={global.config.colors.iconDarkEnd} />
        <stop offset={1} stopColor={global.config.colors.iconDarkStart} />
      </linearGradient>
      <linearGradient
        id="c"
        x1={12.726}
        x2={12.726}
        y1={7}
        y2={18.642}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={global.config.colors.iconLightEnd} />
        <stop offset={1} stopColor={global.config.colors.iconLightStart} />
      </linearGradient>
    </defs>
  </svg>
)
export default Profile_place_holder
