import React, { Suspense, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
// import { setGlobalSettings } from "./helper/module/projects";
import ReactDOM from "react-dom";
import "./index.css";
// import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import "./i18/i18n";
import axios from "axios";
// let GlobalSetting = setGlobalSettings()
import Notifier from "./helper/Notification/notifier";


// const GlobalSetting = setGlobalSettings()

const Loader = () => <div className="App">...</div>;


ReactDOM.render(
  <Suspense fallback={<Loader />}>
    <OuterApp />
  </Suspense>,
  document.getElementById("root")
);






// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



function OuterApp() {
  const [canLoad, setCanLoad] = useState(false)

  useEffect(() => {
    // Update the document title using the browser API
    axios.post(
      `${process.env.NODE_ENV === 'production' ? window.location.origin : "https://app.onro.io"}${/*"https://onro.app.onro.app/"*/""}/api/v1/customer/application/web/initial-setting/`,
      // `${window.location.href}${/*"https://onro.app.onro.app/"*/""}api/v1/customer/application/web/initial-setting/`,

      {},
      {
        headers: {
          "Content-type": "application/json",
          "X-Api-Source": "web",
        },
      }
    )
      .then((res) => {

        // global.config = { ...global.config, ...res.data.data }
        global.config = { ...global.config, ...res.data.data }

        setTimeout(() => {
          setCanLoad(true)
        }, 400);
      })
  });


  let CompApp = canLoad ? require('./App').default : <></>;
  let CompNotifier = canLoad ? require('./helper/Notification/notifier').default : <></>;
  let CompStore = canLoad ? require('./Redux/store/index').store : <></>;

  // let comp;
  // if(setGlobalSettings()) { comp = require('./App').default; }
  return (
    <React.Fragment>
      {canLoad ? 
        <Provider store={CompStore}>
        <SnackbarProvider maxSnack={1}>
          <CompNotifier />
          <CompApp />
        </SnackbarProvider>
      </Provider> : null}
    </React.Fragment>
  )
}



