import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { SetReferenceID } from "../../../../Redux/actions/actionNames";
import { HASHTAG } from "../../../../static";
import Input from "../../../../design-system/lib/Input";



class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.inputOnchange = this.inputOnchange.bind(this)
  }


  inputOnchange(e){
    this.props.dispatch(SetReferenceID(e.target.value))
  }
  


  render() {
    const { t } = this.props;

    if(!(this.props.serviceactiveVehicleType && this.props.serviceactiveVehicleType.setting.isReferenceIdActive) ){
      return null
    }

    return (
      <Input  ContainerclassName={this.props.inputContainerclassName} value={this.props.referenceID ? this.props.referenceID :""} IconStyle={{width:"16px",height:"16px"}}  type={"text"} iconStart={HASHTAG} placeHolder="Reference ID" onChange={this.inputOnchange} />

      // <div className="codContainer" style={{maxWidth:"calc(50% - 10px)"}}>
      //     <span> {t("ReferenceID")} </span>
      //     <input min={0} onChange={this.inputOnchange} placeholder={t("EnterId")} style={{maxWidth:"calc(100% - 46px)"}} />
      // </div>
    
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  serviceactiveVehicleType: state.NewOrder.newOrder.service.activeVehicleType,
  referenceID:state.NewOrder.newOrder.referenceID ,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
