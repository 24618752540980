export const FrenchLabel = {
  Cutomerpanel: "Tableau de bord Customer",
  login: "Se connecter",
  Register: "S'inscrire",
  en: "Anglais",
  fa: "Persian",
  Person: "Particulier",
  Business: "Professionnel",
  Phonenumber: "Téléphone",
  Continue: "Continuer",
  phonenumberhasnotregisterd: "Votre numéro de tél n'a pas été enregistré",
  Username: "Nom d'utilisateur",
  title: "Titre",
  Password: "Mot de Passe",
  ExOnro: "Ex. 12345",
  Exstart: "Ex. ******",
  googleConnected: "Connecter avec Google",
  facebookConnected: "Connecter avec Facebook",
  appleConnected: "Connecter avec Apple ID",
  plsenterphonnumber: "Entrez votre numéro de Tél svp",
  firstnnameloginplaceholder: "Ex. Katty",
  "businessNamePlaceholder": "Ex. ABC Company",
  "registerBusinessNameLabel":"Business Name",
  lastnameloginplaceholder: "Ex. Perry",
  Firstname: "Prénom",
  Lastname: "Nom de Famille",
  email: "Email",
  emailPlaceholder: "(facultatif) Ex. example@example.com",
  comingsoon: "Bientôt :",
  comingsoondesc: "Pour enregistrer votre entreprise, cliquez ici",
  comingsoondesc2: "Pour créer un compte professionnel, veuillez nous contacter",
  contactUs: "Contactez-nous",
  CustomerAlreadyRegistered:
    "Le client est déjà inscrit, veuillez vous connecter",
  registerCompleted: "Entrez le code de vérification",
  googletokenerror: "Problème avec le Token Google",
  facebooktokenerror: "Problème avec le Token Facebook",
  appletokenerror: "Problème avec le Token Apple",
  CustomerNotRegistered: "Le client n'a pas été enregistré",
  fr: "Français ",
  InvalidVerificationCode: "Code de vérification non valide",
  Usernotfound: "Vos informations n'ont pas pu être trouvées",
  welcome: "Bienvenue",
  SmsLimitationError: "Veuillez réessayer dans quelques instants",
  resendCodeWithTime: "Renvoyez le code (xxx)",
  resendCode: "Renvoyez le code",
  VerificationcodePlaceHolder: "Ex. 1234",
  Verificationcode: "Code de vérification ",
  VerificationcodeText: "Entrez le code envoyé à xxx",
  VerificationCodeExpired: "Le code de vérification a été expiré",
  Pending: "En attente",
  Unassigned: "Non assignée",
  Assigned: "Assignée",
  PickedUp: "Ramassée",
  Started: "En cours de livraison",
  statusInProgress: "Etat en cours",
  Done: "Finalisée",
  CustomerCanceled: "Annulée par le client",
  DriverCanceled: "Annulée par le livreur",
  SupportCanceled: "Annulée par le support",
  Arrived: "Arrivé",
  new: "Nouveau",
  Historyofyourorderswillbehere: "L'historique de vos demandes sera ici",
  otherdropoffs: "Autres livraisons",
  Orderhistory: "Historique de la demande",
  Noorder: "Aucune demande",
  loadingList: "Un instant svp",
  loadingError: "Problème rencontré pour obtenir des informations",
  retry: "Essayer encore",
  Create_your_first_requst: "Créer une nouvelle commande",
  List_of_you_active_orders_will_be_here:
    "La liste de vos tâches actives sera ici",
  Aboutus: "A propos de nous",
  Readour: "Lire notre",
  privaypolicy: "Politique de confidentialité",
  Transactions: "Transactions",
  NoTransaction: "Vous n'avez pas de transaction",
  Historyofyourransactionswillbehere:
    "L'historique de vos transactions sera ici",
  Settings: "Paramètres",
  Language: "Langue",
  English: "Anglais",
  Persian: "Persian",
  French: "Français",
  Logout: "Se déconnecter",
  logotTitle: "Se déconnecter du compte ? ",
  logoutDescription:
    "Vous serez déconnecté de votre compte et vous devrez vous connecter ultérieurement.",
  confirmLogout: "Oui, se déconnecter",
  skipLogout: "Annuler",
  savesettings: "Enregistrez les Paramètres",
  emailInvalid: "L’e-mail n'est pas valide",
  Neworder: "Nouvelle demande",
  Service: "Colis",
  poperLoading: "Patientez svp",
  Pickup: "Ramassé",
  SetPickup: "Organiser un ramassage",
  ChooseoOnMap: "Carte",
  Fornow: "Maintenant",
  Scheduled: "Programmé",
  before: "Avant :",
  after: "Après :",
  confirm: "Confirmé",
  Settime: "Fixer l'heure",
  Cancel: "Annuler la demande",
  Shipmentlabel:"Étiquette d'expédition",
  Pleasetryanotherkeyword: "Veuillez essayer un autre mot-clé",
  Noinformationfound: "Aucune information trouvée",
  address: "Adresse",
  block: "Bloc / Batiment",
  Floor: "Etage",
  Unit: "Unité",
  senderfullname: "Nom complet de l'expéditeur",
  senderphonenumber: "Numéro de tél de l'expéditeur",
  notesfordriver: "Une note pour le livreur",
  DropOff: "Livraison",
  SetDropOff: "Programmé une livraison",
  fetchingdata: "Information du destinateur",
  Recieverfullname: "Nom complet du destinataire",
  Recieverphonenumber: "Numéro de tél du destinataire",
  undefined: "Type de service",
  PromotionCode: "Code Promo",
  Delete: "Supprimer",
  Save: "Enrégistrer",
  PromoCodeValid: "Code Promo enregistré",
  SenderWallet: "Portefeuille",
  SenderCash: "Espèces",
  ReceiverCash: "Payé par le destinataire",
  lowBalance: "Solde Faible",
  Balance: "Crédit",
  Methods: "Méthodes",
  balanceError: "Votre solde doit être au moins égal au prix de la demande.",
  SendOrder: "Envoyer",
  feedback: "Avis",
  Feedback: "Avis",
  Orderagain: "Une autre demande",
  Createdsuccessfully: "Créé avec succès",
  chooseAmount: "Choisir un montant",
  orenterthepreferedamount: "Montant préféré:",
  enteramount: "Montant désiré",
  pay: "Payé   ",
  selectPaymentGateway: "Sélectionné le mode de paiement",
  Time_MachineText:
    "Voulez-vous récupérer vos informations de demande non enregistrées précédemment ?",
  yes: "Oui",
  ignore: "Ignorer",
  Order: "Demande",
  statusPending: "Etat en attente",
  statusUnassigned: "Non assignée",
  statusAssigned: "Assignée",
  statusStarted: "En cours de livraison",
  statusPickedUp: "Ramassée",
  statusDone: "Finalisée",
  statusCustomerCanceled: "Annulée par le client",
  statusDriverCanceled: "Annulée par le livreur",
  statusArrived: "Arrivé",
  statusSupportCanceled: "Annulée par le support",
  statusCanceled: "Annulée",
  Phone: "Téléphone",
  additonalservice: "Service aditionnel",
  CallDriver: "Appeler le livreur",
  Message: "Message",
  orderInfoError: "Erreur",
  support: "Support",
  map: "Carte",
  receipt: "Reçu",
  photo: "Photo",
  ServicePhoto: "Photo Colis",
  Addphoto: "Ajouter une photo",
  Estime: "Heure estimée",
  Espath: "Parcours estimé",
  pod: "Preuve de livraison",
  Notes: "Notes",
  Photo: "Photo",
  Signature: "Signature",
  Nonotes: "Aucune note",
  Nosignature: "Aucun signature",
  Nophoto: "Aucune photo",
  Price: "Téléphone",
  Receiver: "Par le destinataire",
  Sender: "Par l'expéditeur",
  Cash: "Espèce",
  Canceltravel: "Annuler la demande",
  CancellText: "Après l'annulation, la demande est annulée pour le conducteur.",
  Estimated: "Estimé",
  Wallet: "Crédit",
  Copylink: "Copier le lien",
  repeatOrder: "Répéter la demande",
  Edit: "Editer",
  confirmCancel: "Oui",
  paymentmethod: "Méthode de paiement",
  SucceessFul: "Finalisée avec succès",
  errorinServer: "Problème de serveur",
  Comment: "Avis",
  Exfeedbackinput: "Ex. Livraison rapide",
  Wallett: "Portefeuille",
  TypeHere: "Cliquer ici",
  Loadingmessages: "Chargement des messages",
  PoweredBy: "Powered par",
  newincomemessage: "Vous avez reçu un nouveau message",
  newincomemessageanother:
    "Vous avez un nouveau message pour une autre demande",
  youhaveunreadmessage: "Message non lu",
  Nointernetconnection: "Pas de connexion Internet",
  close: "Fermer",
  seen: "Vu",
  controlThatbeforeLoweEqualAfter:
    'Le temps "Avant :" ne peut être inférieur à "Après :"',
  controlThatbeforeAndAfterMustBiggerthanCurrentDate:
    'Les heures "avant :" et "après :" ne peuvent être inférieures à l\'heure actuelle',
  errorInPanelSetting:
    "Erreur dans les paramètres de réservation, veuillez contacter le support",
  Arabic: "arabe",
  ar: "arabe",
  veeDeliveryLoginText1: "Envoyer tout",
  veeDeliveryLoginText2: "À n'importe qui, MAINTENANT !",
  Schedule: "Programmé",
  Date: "Date",
  Time: "Temps",
  noDateToShow: "Il n'y a pas de Date à afficher",
  noTimeToShow: "Il n'y a pas de temps à afficher",
  Apply: "Appliquer",
  Increaseyourbalance: "Augmentez votre solde",
  AddBalance: "Ajouter une balance",
  Addressbook:"Carnet d'adresses",
  AddressbookSearchbar:"Recherche par adresse, titre et identifiant",
  Removedefault:"supprimerLaValeurParDéfaut",
  Setdefaultpickup:"Définir le ramassage par défaut",
  Edit:"Éditer" ,
  Remove :"Supprimer",
  newAddress :"Nouvelle adresse",
  NewAddressbookInfoTitle:"info",
  Addressdetails:"Détails de l'adresse",
  Title :"Titre",
  Code:"code",
  editAddress :"Modifier l'adresse",
  AddressbookDropOf:"Carnet d'adresses (déposer)",
  AddressbookPickup:"Carnet d'adresses (ramassage)",
  hide:"Cacher",
  Nosavedaddress:"Aucune adresse enregistrée",
  NosavedaddressDesc:"Vous pouvez en créer un, en cliquant sur l'icône Signet en regard de l'adresse ou de la page d'accueil de l'adresse",
  Romanian:"Roumaine",
  ro:"Roumaine",
  Vehicletype:"Type de véhicule" ,
  Pickup2:"Ramasser" ,
  Dropoff2:"Déposer" ,
  senderfullname: "Nom de l'expéditeur",
  block: "Bloquer",
  senderphonenumber: "Numéro de l'expéditeur",
  Recieverfullname: "Nom du récepteur",
  Recieverphonenumber: "Numéro de récepteur",

  
  Events: "Événements",
  allEvents: "Tous les évènements",
  star :"Star" ,
  Yourrate:"Votre taux" ,
  Comment:"Comment",
  CopyOrderlink:"Copier le lien de commande",
  CopyPickuplink:"Copier le lien de ramassage" ,
  CopyDeliverylink:"Copier le lien de livraison",
  Dutch:"Néerlandaise",
  nl:"Néerlandaise",

  paymentmethod: "Mode de paiement",
  Name :"Nom",
  CashOnDelivery:"Paiement à la livraison",
  EnterAmount:"Entrer le montant",
  COD:"Paiement à la livraison",
  Surchargexxx:"Supplément xxx",
  Pricedetails: "Détails du prix",
  minxxx :"xxx min",
  toPickup:"ramasser" ,
  toDropOff: "pour déposer",
  AddCreditCard:"Ajouter une carte de crédit / débit" ,
  CustomerWallet:"Portefeuille client" ,
  Add:"Ajouter",
  Addedsuccessfully: "Ajouté avec succès",
  paymentmethods: "Méthodes de payement",
  NoCards:"Pas de cartes",
  Listofyourcardswillbehere:"La liste de vos cartes sera ici",
  CardInfo:"Informations sur la carte",
  ExpireDate:"Date d'expiration",
  CVC:"cvc",
  PostalCode:"code postal",
  RemoveCardq:"Supprimer la carte?",
  RemoveCardA:"La carte sera supprimée et ne peut plus être utilisée",
  YesRemove:"Oui, supprimer" ,
  Cardisinuse:"La carte est utilisée",
  ok:"D'accord",
  Pleasetypeyourwordstosearch:"Veuillez taper votre mot-clé pour rechercher" ,
  spanish:"espagnole",
  es : "espagnole",
  Invoices:"Facture",
  noInvoice:"Non. XXX",
  germany: 'Allemagne',
  de: 'Allemagne',
  downloadClientApplication:"Télécharger l'application xxx" ,

  AssignedAt: 'Attribuer jusqu à xxx',
  StartedAt: 'Commencez jusqu à xxx',
  ArrivedAt: 'Arriver jusqu à xxx',
  PickedUpAt: 'Ramassage à xxx',
  DoneAt: 'Terminer à xxx',
  Russian:"russe",
  Azerbaijani:"Azerbaïdjanaise",
  rus:"russe",
  aze:"Azerbaïdjanaise",
  Support:"Soutien",
  SendRequest:"Envoyer une demande",
  CallTheSupport:"Appelez le support",
  Choosethesubject :"Choisissez le sujet",
  Seefrequentquestions:"Voir les questions fréquentes",
  Details:"Détails",
  Send:"Envoyer",
    MessageBox:"Messagerie",
    NoMessages:"Pas de messages",
    Yourmessageswillbehere:"Vos messages seront là",
    Portuguese:"Portugais",
    pt:"Portugais",
    Required:"Requis",
    changePassword : "Changer le mot de passe",
    changePasswordp1 : "Vous serez déconnecté de toutes les sessions, sauf celle-ci pour protéger votre compte, quiconque essaie d'accès",
    changePasswordp2 : "Votre mot de passe doit comporter au moins 6 caractères",
    currentPassword:"Mot de passe actuel",
    newpassword:"nouveau mot de passe",
    Retypenewpassword:"Re-taper le nouveau mot de passe",
    account: "Compte (portefeuille)",
    "Greek":"Grecque",
    "el":"Grecque",
    "registerBussinessUsername":"Nom d'utilisateur (au moins 5 caractères)",
    "registerBussinessPassword":"Mot de passe (au moins 6 caractères)",
    "ReferenceID":"Pièce d'identité",
    "EnterId":"Fournir un document d'identité",
    "Orderid": "Numéro de commande",
    "EnterCode":"Entrez le code",
    "AustrianGerman":"Allemand autrichien",
    "deu":"Allemand autrichien",
    "pop":"Preuve de ramassage",
    "Options":"options",
    "RequiredText":"Requis",
    "PleaseSelect":"Veuillez sélectionner",
    "Optimizedropoffs":"Optimiser les départs",
    "Optimizedropoffs?":"Optimiser les Dropoffs?",
    "OptimizedropoffsDesc":"La séquence de départs sera modifiée en séquence optimisée et il peut changer le prix",
    "Optimize":"Optimiser"

    ,
    "Bulkimport":"Importation en vrac",
    "processing":"Traitement" ,
     "done":"Finie" ,
     "failed":"Échoué",
     "Draft":"Brouillon" ,
     "Uploading":"Téléchargement" ,
     "Draganddropyourfile":"Faites glisser et déposez votre fichier",
     "Onlycsvformatissupported":"Seul le format .csv est pris en charge" ,
     "ORyoucan":"Ou tu peux",
     "Openfile":"Fichier ouvert" ,
     "Noimportsyet":"Pas encore d'importations" ,
     "NoimportsyetDesc":"Vous pouvez voir la liste de vos importations ici et vérifier leur statut",
     "nosupportdialogeTitle":"Format de fichier non pris en charge",
     "nosupportdialogeText":"Le fichier xxx n'est pas pris en charge.Vous ne pouvez télécharger que le fichier .csv, y compris les informations de commande" ,
     "Import":"Importer",
     "previewTitle":"Aperçu et choisir les options",
     "previewDesc":"Ceci est le premier élément de vos fichiers pour vérifier que vos informations se trouvent dans la bonne structure" ,
     "Upload":"Télécharger", 
     "Remove":"Retirer" ,
     "ServiceandOrdertype":"Type de service et de commande" ,
     "Fileisproccessing":"Le fichier est le traitement ...",
     "proccessingDesc":"Vous pouvez vous actualiser pour voir si le résultat est prêt",
     "serverError":"erreur du serveur",
     "isRequired":"xxx est requis" ,
     "Result":"Résultat",
     "ResultText":"Vous pouvez voir les problèmes ici et télécharger ces lignes pour réparer séparément",
     "ErrorDetails":"Commandes avec erreur",
     "ErrorDetailsText":"Les commandes avec des erreurs sont répertoriées dans le fichier que vous pouvez télécharger ci-dessous.Il contient des détails d'erreur pour chaque ligne.",
     "DownloadErroredRows":"Télécharger des lignes erronées" ,
     "importedsuccessfully":"Les commandes xxx sont importées avec succès" ,
     "ordershaserrors":"XXX Orders a des erreurs",
     "refresh":"Rafraîchir",
     "pleaseSellectParsel":"Veuillez sélectionner le service",
     "Ondemand":"Sur demande",
     "PickupandDelivery":"Ramassage et livraison",
     "PickupDelivery":"Ramassage et livraison",
     "Delivery":"Livraison",
     "Pickup":"Ramasser",
     "tab_Delivery":"Livraison",
     "tab_Ondemand":"Sur demande",
     "tab_Pickup":"Ramasser",
     "tab_PickupDelivery":"P&D",
     "Downloadsamplecsv":"Télécharger un exemple de CSV",
     "driverPhoto":"Driver Photo",
     "yourPhoto":"Your Photo",
     "UploadPhoto":"Upload Photo",
     "Photos":"Photos",
   
   
   
     "TrackLink":"Track Link",
     "ShipmentLabel":"Shipment Label",
     "Draft":"Draft",
     "Receipt":"Receipt",
     "Confirm":"Confirm",
     "Cancel":"Cancel",
     "BulkEditStatusSummary":"Bulk Edit Status Summary",
     "Successful":"Successful",
     "Failed":"Failed",
     "OrderID":"Order ID",
     "Details":"Details",
     "Result":"Result",
     "New":"New",
     "Import":"Import",
     "Apply":"Apply",
     "Clear":"Clear",
     "Noresultfound":"No result found",
     "Typeyourkeyword":"Type your keyword",
     "Typeintheinputtostartsearch":"Type in the input to start search",
     "OnDemand":"On Demand",
     "P_Hub_D":"P_Hub_D",
     "AddressDetails":"Address Details",
     "Map":"Map",
     "Support":"Support",
     "BulkEditStatusxxx/yyy":"Bulk Edit Status xxx/yyy",
     "xxxSuccessfull,yyyFailed":"xxx Successfull, yyy Failed",
     "xxxfromyyy":"xxx from yyy",
     "xxxrows":"xxx rows",
     "xxxselected":"xxx selected",
     "xxxdropoffs":"+xxx dropoffs",
     "Status Updated at xxx":"Status Updated at xxx",
     'More':'More',
     'Summary':'Summary',
     'Prev':'Prev',
     'Next':'Next',
     'Done':'Done',
     "ClearAll":"Clear All",
     'Errorinloadingdata':'Error in loading data',
     'Therewasaproblemloadingdatapleasetryagainorcontactsupport':'There was a problem loading data. please try again or contact support',
     'Tryagain':'Try again',
     'ConfigTable' :'Config Table' ,
     'ActiveColumns':'Active Columns',
     'Createyourorder':'Create your order',
     'Yourorderswillbeshownhere':'Your orders will be shown here',
     'Pleasecheckyourfiltersorkeyword':'Please check your filters or keyword',
   "Scheduleoverlap":"The Schedule Pickup time must be before the Schedule Delivery time and have no overlap",
     'CustomerPhoto':'Customer Photo',
     "NoAttachments":"No Attachments",
     "AllAttachmentswillbeshownhere":"All Attachments will be shown here",


     "SearchAddress":"Search Address",
     "NoResultfromLocalDatabaseD": "Please check it again or try another keyword",
     "NoResultfromMapService": "No Result from Map Service",
     "Senderinfo": "Sender info",
     "Receiverinfo": "Receiver info",
     "SchedulePickup": "Schedule Pickup ",
     "ScheduleDelivery": "Schedule Delivery",
     "Fullname": "Full name",
     "addressDetail":"Address Detail",
     "Addtoaddressbook":"Add to addressbook",
     "ExHome":"Ex. Home" ,
     "Ex123":"Ex. 123",
     "Savechanges": "Save changes",
     "Removefromaddressbook":"Remove from addressbook?",
     "RemovefromaddressbookDesc":"This address with entered details will be address to your address book.",
     "Clearfield":"Clear field",
     "CreateOrder":"Create Order"

};
