import React, { useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { get_country } from "./../../../../../helper/UserData/userdate";
import CircularProgress from "@material-ui/core/CircularProgress";
import googlelogo from "./../../../../../static/icon-ic-google.svg";
import facebooklogo from "./../../../../../static/icon-ic-faceobook.svg";
import applelogo from "./../../../../../static/icon-ic-apple.svg";
import { LoginBusiness } from "../../../../../Redux/actions/actions";
import { connect } from "react-redux";
import Continue from "../components/continue";
import loadDynamicScript from "../../../../../helper/module/loadDynamicScript";



class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      loading: false,
      usernameError: false,
      passwordError: false,
      Captcha: null,
      ts: null
    };
  }

  usernameOnchange = (e) => {
    this.setState({
      username: e.target.value,
    });
  };

  passwordOnchange = (e) => {
    this.setState({
      password: e.target.value,
    });
  };

  login = () => {
    if (!this.state.loading) {
      this.props.dispatch(LoginBusiness(this.props.dispatch, {
        "username": this.state.username,
        "password": this.state.password
      },{
        "x-captcha-token" : this.state.Captcha,
        "x-request-ts" : this.state.ts
      }, (xx) => {
        this.componentDidMount()
      }))
    }
  }

  componentDidMount() {
    this.setState({
      Captcha: null ,
      ts:null
    },()=>{
       loadDynamicScript(
      `https://www.google.com/recaptcha/enterprise.js?render=${global.config.grsk}`,
      "recaptchaEnterprise",
      () => {
        
        console.log("componentDidMount ",window.grecaptcha)
      try {
        window.grecaptcha.enterprise.ready(() => {
          const ts = Number(String(Number(Date.now())))

          window.grecaptcha.enterprise
            .execute(global.config.grsk, {
              action: `custom_customer_login_${ts}`
            })
            .then((token) => {
              this.setState({
                Captcha: token ,
                ts : ts
              })
            })
            .catch((error) => {
              console.log(error);
            });
        });
      } catch (error) {
        
      }
      }
    );
    })


   
  }

  componentWillReceiveProps(nextProps){
    console.log(nextProps)

    if(nextProps.activeTab == 1){
          this.componentDidMount()
    }
  }
  render() {
    let country = get_country();
    let registerBtnActive =
      this.state.username.length > 2 && this.state.password.length > 2 && this.state.Captcha

    return (
      <div className="loginpersonContainer">
        <p className="LP_PhoneNumberLabel">
          {" "}
          <Trans i18nKey={"Username"}> </Trans>{" "}
        </p>
        <div
          className={`PhoneNumberContainer ${this.state.usernameError && "PhoneNumberContainerError"
            }`}
        >
          <input
            value={this.state.username}
            className="loginregisterinput"
            placeholder={this.props.t("ExOnro")}
            onChange={this.usernameOnchange}
          />
        </div>
        {this.state.usernameError && (
          <span className="pnhnr">
            {" "}
            <Trans i18nKey={"phonenumberhasnotregisterd"}> </Trans>{" "}
          </span>
        )}

        <p className="LP_PhoneNumberLabel mrt8px">
          {" "}
          <Trans i18nKey={"Password"}> </Trans>{" "}
        </p>
        <div
          className={`PhoneNumberContainer ${this.state.passwordError && "PhoneNumberContainerError"
            }`}
        >
          <input
            type={"password"}
            value={this.state.password}
            className="loginregisterinput"
            placeholder={this.props.t("Exstart")}
            onChange={this.passwordOnchange}
          />
        </div>
        {this.state.passwordError && (
          <span className="pnhnr">
            {" "}
            <Trans i18nKey={"phonenumberhasnotregisterd"}> </Trans>{" "}
          </span>
        )}

        <Continue
          onClick={
            registerBtnActive && !this.state.loading
              ? () => {
                this.login();
              }
              : null
          }
          className={`${this.state.loading
              ? "loginregistercontinueloading"
              : !registerBtnActive
                ? "deactivebtnregister loginregistercontinue"
                : "loginregistercontinue"
            }`}
        >
          {this.state.loading ? (
            <CircularProgress size="28px"  color="white"/>
          ) : (
            <Trans i18nKey={"Continue"}> </Trans>
          )}
        </Continue>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
