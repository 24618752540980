import * as React from "react"
const IconlyBulkBookmarkSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill="url(#aa159357)"
      d="m6.995 10.863-3.787 1.891a.595.595 0 0 1-.803-.233.636.636 0 0 1-.072-.29V7.997c0 .42.237.679.86.969l3.802 1.897z"
    />
    <path
      fill="url(#aIconlyBulkBookmarkSvg)"
      d="M8.79 1.167h-3.6c-1.575 0-2.856.622-2.856 2.212v4.618c0 .42.237.679.86.969l3.802 1.897 3.781 1.891a.588.588 0 0 0 .818-.238.615.615 0 0 0 .072-.285V3.379c-.015-1.59-1.296-2.212-2.876-2.212z"
    />
    <path
      fill="url(#bIconlyBulkBookmarkSvg)"
      d="M9.187 5.613h-4.39a.46.46 0 0 1-.457-.461.46.46 0 0 1 .458-.461h4.389a.46.46 0 0 1 .458.46.46.46 0 0 1-.458.462z"
    />
    <defs>
      <linearGradient
        id="aIconlyBulkBookmarkSvg"
        x1={2.333}
        x2={2.333}
        y1={1.167}
        y2={12.833}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={global.config.colors.iconLightEnd} />
        <stop offset={1} stopColor={global.config.colors.iconLightStart} />
      </linearGradient>
      <linearGradient
        id="bIconlyBulkBookmarkSvg"
        x1={4.34}
        x2={4.34}
        y1={4.691}
        y2={5.613}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={global.config.colors.iconDarkEnd} />
        <stop offset={1} stopColor={global.config.colors.iconDarkStart}/>
      </linearGradient>
    </defs>
  </svg>
)
export default IconlyBulkBookmarkSvg
