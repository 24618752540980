import React, { useCallback, useRef } from 'react'
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  PaginationState
} from '@tanstack/react-table'



const staticColumns = ["select"]



export const TableInstance = ({ data,defaultColumns, rowSelection, setRowSelection, sorting, setSorting
  , columnVisibility, setColumnVisibility, columnOrder, setColumnOrder, columnPinning, setColumnPinning }) => useReactTable({
    data,
    columns: defaultColumns,
    state: {
      columnVisibility,
      columnOrder,
      columnPinning,
      rowSelection,
      sorting
    },
    defaultColumn: {
      minSize: 50,
      maxSize: 800,
    },
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onColumnOrderChange: setColumnOrder,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true, //enable row selection for all rows
    // enableRowSelection: row => row.original.age > 18, // or enable row selection conditionally per row
    onRowSelectionChange: setRowSelection,
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),

    columnResizeMode: 'onChange',
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onColumnPinningChange: setColumnPinning,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
  })

