import * as React from 'react'
import styles from './style.module.css';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RemoveAddressBookFromList, Set_Pickup_Inputs, SetNewAddressBookInNewOrderDetails, SetopenAddressBookInNewOrder, SetopenNewAddressBookInNewOrder } from '../../../../../../../Redux/actions/actionNames';
import { CLOSE, PERSON, PICKUP, WARNING } from '../../../../../../../static';
import Typography from '../../../../../../../design-system/lib/Typography';
import Input from '../../../../../../../design-system/lib/Input';
import Button from '../../../../../../../design-system/lib/Button';
import Schedule from '../../../Schedule/Schedule';
import IconProviderSvgModifier from '../../../../../../../design-system/lib/IconProviderSvgModifier';
import { ReactComponent as BookmarkInActive } from '../../../../../../../static/IconProvider/bookmarkInActive.svg'
import { ReactComponent as Bookmarkactive } from '../../../../../../../static/IconProvider/Bookmarkactive.svg'
import Modal from '../../../../../../../design-system/lib/Modal';
import { get_customerPannel_type, Get_Permisssion } from '../../../../../../../helper/UserData/userdate';
import { Api_Add_Addressbook_AGW, Api_Delete_Addressbook_AGW, Api_Get_Addressbook_list_AGW } from '../../../../../../../helper/api/ApiGateway';
import IconProvider from '../../../../../../../design-system/lib/IconProvider';
import { isEmpty } from 'lodash';
import { SucceessFul_i18 } from '../../../../../../../helper/Notification/sendNotification';
import PhoneInput from '@onro/react-phone-input-2'
import '@onro/react-phone-input-2/lib/style.css'





function PickupInfoNewOrder({handleShowInfo}) {
  const addressDetail = useSelector((state) => state.NewOrder.newOrder.pickup.addressDetail)
  const block = useSelector((state) => state.NewOrder.newOrder.pickup.block)
  const floor = useSelector((state) => state.NewOrder.newOrder.pickup.floor)
  const unit = useSelector((state) => state.NewOrder.newOrder.pickup.unit)
  const email = useSelector((state) => state.NewOrder.newOrder.pickup.email)
  const senderFullName = useSelector((state) => state.NewOrder.newOrder.pickup.senderFullName)
  const senderPhoneNumber = useSelector((state) => state.NewOrder.newOrder.pickup.senderPhoneNumber)
  const noteForDriver = useSelector((state) => state.NewOrder.newOrder.pickup.noteForDriver)
  const ActivescheduleSetting = useSelector((state) => state.NewOrder.newOrder.service.activeVehicleType ? state.NewOrder.newOrder.service.activeVehicleType.scheduleSetting.isActive : false)
  const scheduleDateBefore = useSelector((state) => state.NewOrder.newOrder.pickup.scheduleDateBefore)
  const scheduleDateAfter = useSelector((state) => state.NewOrder.newOrder.pickup.scheduleDateAfter)
  const orderTypeActive = useSelector((state) => state.NewOrder.newOrder.orderTypeActive)
  const serviceactiveVehicleType = useSelector((state) => state.NewOrder.newOrder.service.activeVehicleType)
  const Addressbooklist = useSelector((state) => state.Addressbook.list)
  const address = useSelector((state) => state.NewOrder.newOrder.pickup.address)
  const [modalAddActive, setModalAddActive] = React.useState(false);
  const [modalDelActive, setModalDelActive] = React.useState(false);
  const adresss_loc = useSelector((state) => state.NewOrder.newOrder.pickup.adresss_loc)
  const [modalAddData, setModalAddData] = React.useState({title: null , code : null});

  const { t, i18n } = useTranslation();


  const dispatch = useDispatch();


  const closeDialoge = () => {
    handleShowInfo()
  };


  const detectOrderType = React.useCallback(() => {

    if (serviceactiveVehicleType.orderTypes?.indexOf("PickupDelivery") > -1) {
      return "PickupDelivery"
    } else {
      return "Ondemand"
    }

  },[])

  const Emailvalidate = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }


  const handlePickUpInputs = (e, name) => {
    dispatch(
      Set_Pickup_Inputs({
        name: name,
        value: e.target.value ? e.target.value : "",
      })
    );
  };

  const phoneNumberOnChange = (res, id) => {
    dispatch(
      Set_Pickup_Inputs({
        name: id,
        value: res,
      })
    );
  };


  const checkInputFilledByAB = React.useCallback(() => {
    let status = false;
      Addressbooklist.forEach((element) => {
        if (element.address == address) {
          status = true;
        }
      });
      return status;
   
  }, [Addressbooklist,address ]);

  const getAddressbookId = React.useCallback(() => {
    let status = null;
      Addressbooklist.forEach((element) => {
        if (element.address == address) {
          status = element.id;
        }
      });
      return status;
   
  }, [Addressbooklist,address ]);

  const RemoveAddressBook = React.useCallback(() => {
    setModalDelActive(false)
    let xxx = getAddressbookId()
    Api_Delete_Addressbook_AGW(
      dispatch,
      xxx,
      (call) => {
        call &&
          dispatch(
            RemoveAddressBookFromList(xxx)
          );
      }
    );
  }, [ ]);

  const UserHavePermission = React.useCallback(() => {

    if (get_customerPannel_type() == "business") {
      if (Get_Permisssion()?.isDefaultPickupAddressEditable) {
        return true
      } else if (adresss_loc.lat == '') {
        return true
      } else {
        return false
      }


    } else {
      return true
    }

  },[adresss_loc])
  
  const handleNewBookmark = React.useCallback(() => {

        Api_Add_Addressbook_AGW(dispatch, {
          "title": modalAddData.title,
          "address": address,
          // addressDetail : addressDetail ,
            "coordinates": [
                adresss_loc.lng, adresss_loc.lat
            ], 
            "buildingBlock": block,
            "floor": floor,
            "fullName": senderFullName,
            "phone": senderPhoneNumber,
            "placeId": "",
            "room": unit,
            "code": modalAddData.code ? modalAddData.code  : "" ,
            email:email ,
            description:noteForDriver ,
        },(call)=>{
          if(call.loading){
            setModalAddActive(false)
          }else {
            setModalAddActive(false)
            setModalAddData({title: null , code : null})
            if(call.ok){
              SucceessFul_i18(
                "Createdsuccessfully",
                "networkError",
                "close",
                () => {
                  dispatch({
                    type: "removeNetworkErrorSnack",
                    key: "networkError",
                  });
                },
                new Date().getTime() + Math.random()
              );
              Api_Get_Addressbook_list_AGW(dispatch, {page:1 , perpage:20 , searchRegex:""}, false);
  
            }else{
              
            }
          }
        });
  

    //    setModalAddActive(false)


  },[senderPhoneNumber , addressDetail , address, adresss_loc,block ,floor , unit,senderFullName,noteForDriver ,email ,modalAddData ])


  return (
    <div className={styles.NewCustomerRegisterContainer}>
      <div className={styles.largeHeader}>
        <Button icon={CLOSE} size="Medium" type="Secondary" onClick={closeDialoge} />
        <div className={styles.largeHeaderTitles}>
          <Typography text={t("Pickup")} weight="Body_Middle_Bold" className={styles.SmallTitle} style={{ margin: "0", padding: "0" }} />
        </div>

        {checkInputFilledByAB() ?
            <Button disabled={!UserHavePermission()} onClick={()=>{setModalDelActive(true)}} style={{marginInlineStart:"auto"}}  size='Medium' type='Secondary' iconComponentChildren={<IconProviderSvgModifier Icon={Bookmarkactive} size='16' />} >  </Button>
            :  <Button  onClick={()=>{setModalAddActive(true)}} style={{marginInlineStart:"auto"}}  size='Medium' type='Secondary' iconComponentChildren={<IconProviderSvgModifier Icon={BookmarkInActive} size='16' />} >  </Button>
          }

       
        
      </div>
{/* 
      {orderTypeActive == "PickupDelivery" && <div className={styles.body}>
        <PickupDriverNewOrder />
      </div>} */}
      <div className={styles.body}>
        <Typography weight='Body_Middle_Medium' text={t("Senderinfo")} />

        <div className={styles.inputsConatiner}>
          {(serviceactiveVehicleType?.setting?.isPickupAddressRoomFloorBlockActive && detectOrderType() != "PickupDelivery") && <div className={styles.threeCOLUMNS}>
            <Input type={"text"} placeHolder={t("block")} value={block} inputStyle={{ width: "inherit" }} onChange={(e) => { handlePickUpInputs(e, "block") }} />
            <Input type={"text"} placeHolder={t("Floor")} value={floor} inputStyle={{ width: "inherit" }} onChange={(e) => { handlePickUpInputs(e, "floor") }} />
            <Input type={"text"} placeHolder={t("Unit")} value={unit} inputStyle={{ width: "inherit" }} onChange={(e) => { handlePickUpInputs(e, "unit") }} />
          </div>}

          {(serviceactiveVehicleType?.setting?.isAddressDetailsActive && detectOrderType() == "PickupDelivery") &&
            <Input type={"text"} placeHolder={t("addressDetail")} value={addressDetail} inputStyle={{ width: "inherit" }} onChange={(e) => { handlePickUpInputs(e, "addressDetail") }} />
           }

          {serviceactiveVehicleType?.setting?.isPickupAddressNameActive && <Input isRequired={serviceactiveVehicleType?.setting?.isPickupAddressNameRequired} isRequiredText={t("*")} type={"text"} placeHolder={t("Fullname")} value={senderFullName} onChange={(e) => { handlePickUpInputs(e, "senderFullName") }} />}


          {serviceactiveVehicleType?.setting?.isPickupAddressPhoneActive && 
            // <Input isRequired={serviceactiveVehicleType?.setting?.isPickupAddressPhoneRequired} isRequiredText={t("*")}  type={"text"} placeHolder={t("Phonenumber")} inputStyle={{ width: "inherit" }}       value={senderPhoneNumber}
            // onChange={(PrePhoneInput) => {
            //   phoneNumberOnChange((PrePhoneInput.target.value) ? PrePhoneInput.target.value :"", "senderPhoneNumber")
            // }
            // } />

            <div className={styles.PhoneInput}>
                  <PhoneInput
                  countryCodeEditable ={false}
                    country={String(global.config.countryCca2).toLowerCase()}
                    className={clsx(styles.inputContainer, "senderphonenumberPickup")}
                    value={senderPhoneNumber}
                    onChange={(e1 , e2 , e3 , e4) => { phoneNumberOnChange(e4, "senderPhoneNumber")}}
                    enableLongNumbers={true}
                    inputProps={{type:"text"}}
                    copyNumbersOnly={false}
                    autoFormat={false}
                    placeHolder={t("Phonenumber")}

                  />
              {/* <PhoneInput
                defaultCountry={global.config.Country}
                placeholder={t("Phonenumber")}
                value={senderPhoneNumber}
                onChange={(PrePhoneInput) => {
                  phoneNumberOnChange(PrePhoneInput, "senderPhoneNumber")
                }
                }
                className={clsx(styles.inputContainer, "senderphonenumberPickup")}
              /> */}
              {serviceactiveVehicleType &&
                serviceactiveVehicleType.setting &&
                serviceactiveVehicleType.setting.isPickupAddressPhoneRequired && String(senderPhoneNumber).length < 3 && (
                  <span className={`inputRequired ${styles.PhoneInputRequired}`}>
                    {t("*")}
                  </span>
                )}
            </div>
          }

          {(serviceactiveVehicleType?.setting?.isPickupAddressEmailActive ) && <Input isRequired={serviceactiveVehicleType?.setting?.isPickupAddressEmailRequired} isRequiredText={t("*")} error={Emailvalidate(email) ? "" : t("Emailisnotcorrect")} type={"text"} value={email} placeHolder={t("email")} onChange={(e) => { handlePickUpInputs(e, "email") }} />}
         {detectOrderType() != "PickupDelivery" && <Input type={"text"} value={noteForDriver} placeHolder={t("notesfordriver")} onChange={(e) => { handlePickUpInputs(e, "noteForDriver") }} />}

        </div>

      </div>

      {ActivescheduleSetting && <div className={styles.body}>
        <Typography text={t("SchedulePickup")} weight="Body_Middle_Bold" className={styles.SmallTitle} style={{ margin: "0", padding: "0" }} />

        <Schedule orderType={detectOrderType()}
          scheduleDateBefore={scheduleDateBefore}
          scheduleDateAfter={scheduleDateAfter}
        />
      </div>} 

      { modalAddActive && <Modal  onclose={()=>{setModalAddActive(false)}} 
      
      body={<div className={styles.addAddressbookContainer}>
          <div className={styles.addAddressbookinnerContainer} style={{paddingBottom : "16px" ,borderBottom:"1px solid #F4F4F7"}}> 
                <Input title={t("title")} isRequired={true} isRequiredText='*'  type={"text"} placeHolder='Ex. Home' value={modalAddData.title} onChange={(e)=>{setModalAddData({title : e.target.value , code : modalAddData.code})}}/>
                <Input title={t('Code')}  type={"text"} placeHolder='Ex. 123' value={modalAddData.code}  onChange={(e)=>{setModalAddData({title : modalAddData.title , code : e.target.value})}}/>

          </div>
          <div className={styles.addAddressbookinnerContainer} style={{paddingTop:"16px" }}> 
              
              <div className={styles.addAddressnamephoneContainer}>
                <IconProvider Icon={PICKUP} size='16' />
                <div className={styles.addAddressnamephoneInnerContainer}>
                  <Typography weight='Body_Middle_Medium' text={address} style={{lineHeight : "16.71px"}} />
                  <Typography weight='Body_Tiny_Regular' text={isEmpty(addressDetail) ? "-" : addressDetail} />
                </div>
              </div>

      
             {(!isEmpty(senderFullName) || !isEmpty(senderPhoneNumber)) && <div className={styles.addAddressnamephoneContainer} style={{borderTop:"1px solid rgb(244, 244, 247)",paddingTop:"12px"}}>
                <IconProvider Icon={PERSON} size='16' style={{alignSelf :"center"}} />
                <div className={styles.addAddressnamephoneInnerContainer}>
                  <Typography weight='Body_Middle_Medium' text={isEmpty(senderFullName) ? "--" : senderFullName} style={{lineHeight : "17.3px"}} />
                  <Typography weight='Body_Tiny_Regular' text={isEmpty(senderPhoneNumber) ? "-" : senderPhoneNumber}  />
                </div>
               {!isEmpty(email) && <Typography style={{alignSelf :"end" , marginInlineStart :"auto"}} weight='Subtitle_Tiny_Regular' text={email}  />}

              </div>}

          </div>
      </div>} ConfirmationDisabled={isEmpty(modalAddData.title)} size='Large' CancelText={t("Cancel")} CancelOnClick={()=>{setModalAddActive(false)}} ConfirmationOnClick={handleNewBookmark} ConfirmationText={t("Add")} icon={WARNING} title={t('Addtoaddressbook')} description='This address with entered details will be address to your address book.'  />  }
      { modalDelActive && <Modal size='Small' ConfirmationStyle={{color:"white",backgroundColor:"red"}} CancelText={t("Cancel")} CancelOnClick={()=>{setModalDelActive(false)}} ConfirmationOnClick={RemoveAddressBook} ConfirmationText={t("YesRemove")} icon={WARNING} title={t('Remove from addressbook?')} description={t("RemovefromaddressbookDesc")}  />}

    </div>
  )
}

export default PickupInfoNewOrder