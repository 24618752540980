import * as React from "react"
const MutualIcPhoto = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} {...props}>
    <defs>
      <linearGradient id="aMutualIcPhoto" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconLightEnd} />
        <stop offset="100%" stopColor={global.config.colors.iconLightStart} />
      </linearGradient>
      <linearGradient id="bMutualIcPhoto" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconDarkEnd}/>
        <stop offset="100%" stopColor={global.config.colors.iconDarkStart} />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="nonzero">
      <path
        fill="url(#aMutualIcPhoto)"
        d="M5.1 18C2.05 18 0 15.86 0 12.676V5.325C0 2.14 2.05 0 5.1 0h7.8C15.95 0 18 2.14 18 5.325v7.35C18 15.862 15.95 18 12.9 18H5.1z"
      />
      <path
        fill="url(#bMutualIcPhoto)"
        d="M11.532 9.67c.201-.266.947-1.096 1.91-.505.615.372 1.132.876 1.685 1.415.211.206.362.441.462.692.301.76.145 1.671-.178 2.423a3.303 3.303 0 0 1-2.034 1.866c-.41.132-.838.189-1.266.189H4.526c-.755 0-1.423-.177-1.97-.509-.343-.208-.404-.687-.15-.999.426-.52.846-1.042 1.27-1.568.806-1.007 1.35-1.299 1.955-1.042.245.105.491.265.745.433.675.45 1.613 1.069 2.85.398.85-.47 1.342-1.274 1.77-1.97.176-.284.343-.567.536-.824zM5.497 3.374c1.24 0 2.25 1.018 2.25 2.268 0 1.25-1.01 2.266-2.25 2.266S3.248 6.892 3.248 5.643a2.262 2.262 0 0 1 2.25-2.268z"
      />
    </g>
  </svg>
)
export default MutualIcPhoto
