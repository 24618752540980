import * as React from "react"
const NewAddressBookSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="url(#aNewAddressBookSvg)"
      d="M12.5 1.5h-7c-2.553 0-4 1.447-4 4v7c0 2.547 1.44 4 4 4h7c2.553 0 4-1.453 4-4v-7c0-2.553-1.447-4-4-4z"
    />
    <path
      fill="#fff"
      d="M9.562 9.531v1.912A.56.56 0 0 1 9 12a.56.56 0 0 1-.562-.557V9.53H6.51A.565.565 0 0 1 6 8.97c0-.289.22-.53.51-.56h1.922V6.505A.566.566 0 0 1 8.997 6c.291 0 .535.218.565.505v1.906h1.928c.29.03.51.271.51.56 0 .289-.22.53-.51.56H9.562z"
    />
    <defs>
      <linearGradient
        id="aNewAddressBookSvg"
        x1={1.5}
        x2={1.5}
        y1={1.5}
        y2={16.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={global.config.colors.secondaryColor} />
        <stop offset={1} stopColor={global.config.colors.secondaryColor} />
      </linearGradient>
    </defs>
  </svg>
)
export default NewAddressBookSvg
