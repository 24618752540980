import * as actionTypes from "../actions/actionTypes";
import React from "react";

var _ = require("lodash");

const getMapCenterByUrl = () => {
  switch (window.location.hostname) {
    case "restaurant.catrin-go.com":
      return {
        lat: "33.680788",
        lng: "-117.821799",
      };
    case "deliver.loco.direct":
      return {
        lat: "3.146436",
        lng: "101.687713",
      };
    case "dispatcher.pickup.global":
      return {
        lat: "3.1475",
        lng: "101.693333",
      };
      break;
    case "dispatcher.onro.io":
      return {
        lat: "3.1475",
        lng: "101.693333",
      };
      break;
    case "dispatcher.onro.org":
      return {
        lat: "35.694654",
        lng: "51.404343",
      };
      break;
    case "dispach.acistant.xyz":
      return {
        lat: "10.001389",
        lng: "53.565278",
      };
      break;
    case "dispatcher.asankar.co":
      return {
        lat: "36.1128",
        lng: "44.033",
      };
      break;

    case "dispatcher.deliro.ir":
      return {
        lat: "35.694654",
        lng: "51.404343",
      };
      break;

    case "dispatcher.doorpost.com.au":
      return {
        lat: "46.13531111753044",
        lng: "-60.184021911779666",
      };
      break;

    case "app.yoni-app.sn":
      return {
        lat: 14.694153,
        lng: -17.450331,
      };
      break;

    case "app.onro.io":
      return {
        lat: 27.709345,
        lng: 85.327736,
      };
      break;

    case "app.emrazgo.com":
      return {
        lat: "2.944190",
        lng: "101.698162",
      };
      break;
    case "order.hellocourier.co.ke":
      return {
        lat: "-1.2864",
        lng: "36.8172",
      };
      break;
    case "app.otokh.com":
      return {
        lat: "11.605189",
        lng: "104.911424",
      };
      break;
    case "now.veedelivery.com":
      return {
        lat: "31.922914",
        lng: "35.911963",
      };

    case "app.hio.ro":
      return {
        lat: "47.163279",
        lng: "27.586531",
      };
    case "business.trcs.pk":
      return {
        lat: "33.712284",
        lng: "73.091881",
      };


    case "deliver.pickngosg.com":
      return {
        lat: "1.364196",
        lng: "103.868759",
      }
      case "my.wadelivery.com":
          return {
            lat: "18.533517",
            lng: "73.864845",
          }

    case "dash.droppx.com":
      return {
        lat: "60.169765", lng: "24.938556",
      }

      
    case "app.saniclogistics.com":
      return {
        lat: "-1.2831410178486997",
        lng: "36.820336478719554"
      }

    default:
      return {
        lat: 27.709345,
        lng: 85.327736,
      };
  }
};

const init = {
  realtimePlaceName: {
    address: "",
    coordinates: [],
    placeId: null,
    loading: true,
  },
  zoom: 14,
  center: { lng: "0", lat: "0" } /* global.config.MapCenter*/ /*{ lng: "51.404343", lat: "35.694654" }*/,
  mapRef: React.createRef(),
  monitoring: {
    active: false,
    haveorder: true,
    activeVehicleType: [],
    vehicleTypes: [],
    drivers: [],
  },
  geocodingReverse: {
    loading: false,
    placeId: null,
    address: "",
    coordinates: null,
  },
};
export const Map = (state = init, action) => {
  switch (action.type) {
    case actionTypes.set_REF:
      return {
        ...state,
        mapRef: action.state,
      };
    // case actionTypes.setMonitoringHaveOrder:
    //   return {
    //     ...state,
    //     monitoring: {
    //       ...state.monitoring,
    //       haveorder: action.state,
    //     },
    //   }
    // case actionTypes.set_Zoom:
    //   return {
    //     ...state,
    //     zoom: action.state,
    //   };
    case actionTypes.set_latlng:
      return {
        ...state,
        center: action.state,
      };
    // case actionTypes.set_realtimePlaceName:
    //   return {
    //     ...state,
    //     realtimePlaceName: {
    //       ...state.realtimePlaceName,
    //       ...action.state,
    //     },
    //   };
    // case actionTypes.set_realtimePlaceNameLoading:
    //   return {
    //     ...state,
    //     realtimePlaceName: {
    //       ...state.realtimePlaceName,
    //       loading: action.state,
    //     },
    //   };
    // case actionTypes.monitoringOnOff:
    //   return {
    //     ...state,
    //     monitoring: {
    //       ...state.monitoring,
    //       active: !state.monitoring.active,
    //       activeVehicleType: !state.monitoring.active
    //         ? state.monitoring.vehicleTypes.map((service) => {
    //             return service.id;
    //           })
    //         : [],
    //     },
    //   };

    // case actionTypes.set_driver_auto_vehicleTypes:
    //   return {
    //     ...state,
    //     monitoring: {
    //       ...state.monitoring,
    //       vehicleTypes: action.state,
    //     },
    //   };

    // case actionTypes.monitoringAddVehicleType:
    //   return {
    //     ...state,
    //     monitoring: {
    //       ...state.monitoring,
    //       activeVehicleType: [
    //         ...state.monitoring.activeVehicleType,
    //         action.state,
    //       ],
    //     },
    //   };

    // case actionTypes.monitoringRemoveVehicleType:
    //   return {
    //     ...state,
    //     monitoring: {
    //       ...state.monitoring,
    //       activeVehicleType: state.monitoring.activeVehicleType.filter(
    //         (serv) => serv !== action.state
    //       ),
    //     },
    //   };
    // case actionTypes.setMonitoringDrivers:
    //   return {
    //     ...state,
    //     monitoring: {
    //       ...state.monitoring,
    //       drivers: _.keyBy(action.state, "id"),
    //     },
    //   };

    case actionTypes.set_Map_Geocoding:
      return {
        ...state,
        geocodingReverse: {
          ...state.geocodingReverse,
          ...action.state,
        },
      };

    default:
      return state;
  }
};

export default Map;
