import { CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import { UnSucceessFul_i18 } from "../../../../../helper/Notification/sendNotification";
import { connect } from "react-redux";
import { RegisterBussinessPureApi } from "../../../../../helper/api";
import Continue from "../../Login/components/continue";
import loadDynamicScript from "../../../../../helper/module/loadDynamicScript";

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      Captcha: null,
      username: "",
      password: "",
      email:"",
      phone :"",
      address:"",
      loading: false
    };

    this.recaptchaRef = React.createRef();
    this.CaptchaOnChange = this.CaptchaOnChange.bind(this);

  }

  CaptchaOnChange(value) {
    this.setState({
      Captcha: value
    })
  }

  usernameOnchange = (e) => {
    this.setState({
      username: e.target.value,
    });
  };

  passwordOnchange = (e) => {
    this.setState({
      password: e.target.value,
    });
  };


  emailOnChange = (e) => {
    this.setState({
      email: e.target.value,
    });
  };


  phoneNumberOnChange = (res) => {
    res && this.setState({
        phone: res,
      });
  };

  addressNumberOnChange = (e) => {
   this.setState({
      address: e.target.value,
      });
  };

  nameOnChange = (e) => {
    this.setState({
       name: e.target.value,
       });
   };
 

  emailValidate(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  register(){
    if(this.emailValidate(this.state.email)){
      if (!this.state.loading) {
        RegisterBussinessPureApi(this.props.dispatch, {header : {Captcha : this.state.Captcha , ts : this.state.ts} , state : {
          "username": this.state.username,
          "password": this.state.password,
          "phone": this.state.phone,
          "name": this.state.name,
          "email": this.state.email,
          "address": this.state.address
        }}, (res) => {
          this.setState({
            ...res,
            Captcha : null ,
            ts : null
          },()=>{
            setTimeout(() => {
              this.componentDidMount()
            }, 400);
          });
        })
      }
    }else{
      UnSucceessFul_i18(
        "emailInvalid",
        "networkError",
        "close",
        () => {
          this.props.dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  }



  componentDidMount(){

    
    if(global.config.grsk){

    this.setState({
      Captcha: null ,
      ts:null,
    },()=>{
        loadDynamicScript(
      `https://www.google.com/recaptcha/enterprise.js?render=${global.config.grsk}`,
      "recaptchaEnterprise",
      async ()  => {
        window.grecaptcha.enterprise.ready( () => {
          const ts = Number(String(Number(Date.now())))

          window.grecaptcha.enterprise
            .execute(global.config.grsk, {
              action: `custom_customer_register_${ts}`
            })
            .then( (token)=> {

              this.setState({
                Captcha: token ,
                ts
              })
            })
            .catch( (error)=> {
              console.log(error);
            });
        });
      }
    );
    })
  
  }
  
  }


  render() {
    let registerBtnActive = (this.state.phone.length > 6 && String(this.state.username).trim().length > 4 && this.state.password.length > 5 &&  (global.config.grsk ? this.state.Captcha : true) && this.state.address.length > 0 && this.state.email.length > 6)
    return <div className="loginpersonContainer">


      <p className="LP_PhoneNumberLabel">
        {" "}
        <Trans i18nKey={"Phonenumber"}> </Trans>{" "}
      </p>
      <div
        className={`PhoneNumberContainer `}
      >
        <PhoneInput
          defaultCountry={global.config.countryCca2}
          placeholder="123456789"
          value={this.state.phone}
          onChange={(PrePhoneInput) =>
            this.phoneNumberOnChange(PrePhoneInput)
          }
          onKeyUp={this.enetroninput}
          className="loginpersonPhoneInput"
        />
      </div>

      <p className="LP_PhoneNumberLabel mrt8px">
        {" "}
        <Trans i18nKey={"Username"}> </Trans>{" "}
      </p>
      <div
        className={`PhoneNumberContainer ${this.state.usernameError && "PhoneNumberContainerError"
          }`}
      >
        <input
          value={this.state.username}
          className="loginregisterinput"
          placeholder={this.props.t("registerBussinessUsername")}
          onChange={this.usernameOnchange}
        />
      </div>


      <p className="LP_PhoneNumberLabel mrt8px">
        {" "}
        <Trans i18nKey={"Password"}> </Trans>{" "}
      </p>
      <div
        className={`PhoneNumberContainer ${this.state.passwordError && "PhoneNumberContainerError"
          }`}
      >
        <input
          type={"password"}
          value={this.state.password}
          className="loginregisterinput"
          placeholder={this.props.t("registerBussinessPassword")}
          onChange={this.passwordOnchange}
        />
      </div>

      <p className="LP_PhoneNumberLabel mrt8px">
        <Trans i18nKey={"registerBusinessNameLabel"}> </Trans>
      </p>
      <div className={`PhoneNumberContainer`}>
        <input
          placeholder={this.props.t("businessNamePlaceholder")}
          value={this.props.name}
          onChange={this.nameOnChange}
          className="loginregisterinput"

        />
      </div>

      <p className="LP_PhoneNumberLabel mrt8px">
        <Trans i18nKey={"email"}> </Trans>{" "}
      </p>
      <div className={`PhoneNumberContainer `}>
        <input
          placeholder={this.props.t("email")}
          className="emailinputregister"
          value={this.props.email}
          onChange={this.emailOnChange}
        />
      </div>

      <p className="LP_PhoneNumberLabel mrt8px">
        <Trans i18nKey={"address"}> </Trans>{" "}
      </p>
      <div className={`PhoneNumberContainer `}>
        <input
          placeholder={this.props.t("address")}
          className="emailinputregister"
          value={this.state.address}
          onChange={this.addressNumberOnChange}
        />
      </div>

      <Continue
          onClick={
            ( registerBtnActive &&   (global.config.grsk ? this.state.Captcha : true)) && !this.state.loading
              ? () => {
                this.register();
              }
              : null
          }
          className={`${
            this.state.loading
              ? "loginregistercontinueloading"
              :  ( !registerBtnActive ||  ! (global.config.grsk ? this.state.Captcha : true))
                ? "deactivebtnregister loginregistercontinue"
                : "loginregistercontinue"
            }`}
        >
          {this.state.loading ? (
            <CircularProgress size="28px" color="white"/>
          ) : (
              <Trans i18nKey={"Continue"}> </Trans>
            )}
        </Continue>

      <div style={{float:"left" , marginLeft:"10px",marginTop:"19px"}}>
          {/* <ReCAPTCHA
            sitekey={global.config.grsk}
            onChange={this.CaptchaOnChange}
            ref={this.recaptchaRef}
          /> */}
        </div>

    </div>;
  }
}

const mapStateToProps = (state) => ({

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(App));