import * as React from "react"
const AddressbookSetDefaultSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill="url(#aAddressbookSetDefaultSvg)"
      d="M10.038 0H3.969C1.596 0 0 1.666 0 4.144v5.72C0 12.334 1.596 14 3.969 14h6.069C12.411 14 14 12.334 14 9.864v-5.72C14 1.666 12.411 0 10.038 0z"
    />
    <path
      fill="url(#bAddressbookSetDefaultSvg)"
      d="M10.77 5.381 6.486 9.77a.767.767 0 0 1-.56.231.761.761 0 0 1-.55-.23L3.23 7.58a.828.828 0 0 1 0-1.146.781.781 0 0 1 1.118 0l1.579 1.617 3.725-3.815a.781.781 0 0 1 1.118 0 .828.828 0 0 1 0 1.145z"
    />
    <defs>
      <linearGradient
        id="aAddressbookSetDefaultSvg"
        x1={0}
        x2={0}
        y1={0}
        y2={14}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={global.config.colors.iconLightEnd} />
        <stop offset={1} stopColor={global.config.colors.iconLightStart} />
      </linearGradient>
      <linearGradient
        id="bAddressbookSetDefaultSvg"
        x1={3}
        x2={3}
        y1={4}
        y2={10}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={global.config.colors.iconDarkEnd} />
        <stop offset={1} stopColor={global.config.colors.iconDarkStart} />
      </linearGradient>
    </defs>
  </svg>
)
export default AddressbookSetDefaultSvg
