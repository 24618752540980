import * as React from "react"
const PinDropOff = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} {...props}>
    
    <defs>
      <linearGradient id="aPinDropOff" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.secondaryLightColor} />
        <stop offset="100%" stopColor={global.config.colors.secondaryColor}  />
      </linearGradient>
    </defs>

    <g fill="none" fillRule="evenodd">
      <path
        fill="url(#aPinDropOff)"
        d="M19.027 40.878c3.227 0 6.115-1.351 6.455-.675.227.45-1.925 3.716-6.455 9.797-4.53-6.081-6.682-9.347-6.456-9.797.34-.676 3.228.675 6.456.675ZM18.687 0c10.32 0 18.687 8.319 18.687 18.581 0 10.262-8.367 18.581-18.687 18.581S0 28.843 0 18.582C0 8.318 8.366 0 18.687 0Z"
        transform="translate(6.566)"
      />
      <path
        fill="#FFCE66"
        fillRule="nonzero"
        d="M18.787 26.263a.599.599 0 0 0 .605-.599v-4.437c.2-.087.948-.375 2.118-.375 2.946 0 4.794 1.445 7.596 1.445 1.243 0 1.704-.136 2.31-.407.549-.248.907-.663.907-1.397v-8.1c0-.43-.366-.678-.828-.678-.39 0-1.123.335-2.492.335-2.803 0-4.642-1.444-7.596-1.444-1.243 0-1.712.136-2.317.407-.55.247-.908.662-.908 1.389v13.263a.61.61 0 0 0 .605.598Z"
      />
    </g>
  </svg>
)
export default PinDropOff
