import styled from "styled-components";

let Continue = styled.span` 

  color: ${global.config.colors.secondaryTextColor} !important;

  background-image: ${`linear-gradient(to top, ${global.config.colors.secondaryColor}, ${global.config.colors.secondaryLightColor}) !important`} };

`;

export default Continue