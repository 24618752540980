import * as React from "react"
const AddressbookItemNoteSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill="url(#aAddressbookItemNoteSvg)"
      fillRule="evenodd"
      d="M1.393 5.874a5.375 5.375 0 1 1 10.75.036v.061c-.037 1.93-1.115 3.713-2.436 5.107a14.127 14.127 0 0 1-2.514 2.07.651.651 0 0 1-.852 0 13.872 13.872 0 0 1-3.536-3.312 6.878 6.878 0 0 1-1.412-3.944v-.018z"
      clipRule="evenodd"
    />
    <circle cx={6.7} cy={5.977} r={1.723} fill="url(#bAddressbookItemNoteSvg)" />
    <defs>
      <linearGradient
        id="aAddressbookItemNoteSvg"
        x1={1.393}
        x2={1.393}
        y1={0.517}
        y2={13.306}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={global.config.colors.iconLightEnd} />
        <stop offset={1} stopColor={global.config.colors.iconLightStart} />
      </linearGradient>
      <linearGradient
        id="bAddressbookItemNoteSvg"
        x1={5.044}
        x2={5.044}
        y1={4.255}
        y2={7.7}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={global.config.colors.iconDarkEnd} />
        <stop offset={1} stopColor={global.config.colors.iconDarkStart} />
      </linearGradient>
    </defs>
  </svg>
)
export default AddressbookItemNoteSvg
