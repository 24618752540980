import React from "react";
import { Marker } from '@react-google-maps/api';
import { OverlayView } from '@react-google-maps/api';
import ReactDOMServer from "react-dom/server";

import { connect } from "react-redux";
import { ReactComponent as OriginIconSelected } from "../../../../static/pin_pickup.svg";
import { ReactComponent as DestinationIcon } from "../../../../static/pin_dropoff.svg";
import { ReactComponent as DestinationIconSelected } from "../../../../static/pin_dropoff.svg";
import destinationIconSelected from "../../../../static/pin_dropoff.svg";
import DropOffPin from "../../../../components/shared/map-dropoff-pin/Pin";
import PickupPin from "../../../../components/shared/map-pickup-pin/Pin";
import DropOffPinWithNumber from "../../../../components/shared/map-dropoff-pin-with-number/Pin";


class App extends React.Component {

  render() {
    let originShow = this.props.pickupAdresss_loc.lng !== "" && this.props.pickupDropOffType != "Pickup";
    let destinationShow = this.props.dropOffsAdresss_loc.lng !== "" && (this.props.pickupDropOffType == "DropOff" ? this.props.pickupDropOffId == null ? false : true : true);
    let OthersdestinationShow = this.props.pickupDropOffType == "DropOff" ? this.props.pickupDropOffId == null ? this.props.otherDropOffs : this.props.otherDropOffs.filter((drop) => { return drop.id != this.props.pickupDropOffId }) : this.props.otherDropOffs;

    return (
      <React.Fragment>
        {/* {originShow ? (
            <Marker
              position={this.props.pickupAdresss_loc}
              options
              icon={{
                path: require("../../../../static/pin_pickup.svg") ,
                fillColor: "yellow",
                fillOpacity: 0.9,
                scale: 2,
                strokeColor: "gold",
                strokeWeight: 2
              }}
            ></Marker>
          ) : null} */}



        {originShow ? (
          <Marker
            position={this.props.pickupAdresss_loc}
            icon={{
              anchor: { x: 15, y: 50 },
              url: 'data:image/svg+xml;charset=utf-8,' +
                encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<PickupPin bigSize={true} color={global.config.colors.secondaryColor} />))
            }}
          ></Marker>
          // <OverlayView
          //   position={this.props.pickupAdresss_loc}
          //   mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}

          // >
          //   <div style={{ width: '50px', height: "50px", marginLeft: "-25px", marginTop: "-50px" }}>
          //     <OriginIconSelected />
          //   </div>

          // </OverlayView>
        ) : null}


        {destinationShow ?
          (this.props.otherDropOffs.length == 0 ? <Marker
            position={this.props.dropOffsAdresss_loc}
            icon={{
              anchor: { x: 15, y: 50 },
              url: 'data:image/svg+xml;charset=utf-8,' +
                encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<DropOffPin bigSize={true} color={"#0064d2"} />))
            }}
          ></Marker> : <OverlayView
          position={this.props.dropOffsAdresss_loc}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
      
            <DropOffPinWithNumber color={"#0064d2"} bigSize={true}
                style={{ marginLeft: "-15px", marginTop: "-51px" ,marginBottom:"10px"}} number={1} numberStyle={{ position: "absolute", width: "20px", height: "20px", left: "5px", top: "7px", fontSize: "14px", fontWeight: "bold", fontFamily: 'sf_pro', textAlign: "center" }} />
      
        </OverlayView>)
          // (<OverlayView
          //     position={this.props.dropOffsAdresss_loc}
          //     mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}

          //   >
          //     <div style={{ width: '50px', height: "50px", marginLeft: "-25px", marginTop: "-50px" }}>
          //       <DestinationIconSelected  />
          //     </div>

          //   </OverlayView>)

          : null}



        {OthersdestinationShow.map((dropoff  ,i) => {
          return (this.props.otherDropOffs.length == 0 ? <Marker
            position={dropoff.adresss_loc}
            icon={{
              anchor: { x: 15, y: 50 },
              url: 'data:image/svg+xml;charset=utf-8,' +
                encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<DropOffPin bigSize={true} color={"#0064d2"} />))
            }}
          ></Marker> : <OverlayView
          position={dropoff.adresss_loc}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
      
            <DropOffPinWithNumber color={"#0064d2"} bigSize={true}
                style={{ marginLeft: "-15px", marginTop: "-51px" ,marginBottom:"10px"}} number={i + 2} numberStyle={{ position: "absolute", width: "20px", height: "20px", left: "5px", top: "7px", fontSize: "14px", fontWeight: "bold", fontFamily: 'sf_pro', textAlign: "center" }} />
      
        </OverlayView>)
          
          // (<OverlayView
          //   position={dropoff.adresss_loc}
          //   mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}

          // >
          //   <div style={{ width: '50px', height: "50px", marginLeft: "-25px", marginTop: "-50px" }}>
          //     <DestinationIcon className="destinationShow" />
          //   </div>

          // </OverlayView>)
        })}




      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  pickupDropOffType: state.NewOrder.newOrder.map_Data.pickupDropOff.type,
  pickupDropOffId: state.NewOrder.newOrder.map_Data.pickupDropOff.id,

  pickupAdresss_loc: state.NewOrder.newOrder.pickup.adresss_loc,
  dropOffsAdresss_loc: state.NewOrder.newOrder.dropOffs.firstDropOff.adresss_loc,
  otherDropOffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,


});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
