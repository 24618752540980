import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Add_Service_Option,
  ChangeNewTravelDialoge,
  New_Order_Reset_DropOffs,
  New_Travel_PaymentMethod,
  Remove_Service_Option,
  ResetNewOrder,
  Set_Auto_ActiveVehicleType,
  Set_Driver_auto_VehicleTypes,
  Set_Service_ActiveVehicleType,
  SetopenAddressBookInNewOrder,
} from "../../../../../Redux/actions/actionNames";
import { withTranslation, Trans } from "react-i18next";
//import { ServicesVehicletypeAction } from "../../../../Redux/actions/actions";
import momentJalaali from "moment-jalaali";
import { DetectOndemandOrReserve } from "../../../../../helper/worker/DetectOndemandOrReserve";
import { Api_Create_Ondemand_Order_AGW, Api_Create_PickupDelivery_Order_AGW, Api_GetVehicleTypeByService_AGW } from "../../../../../helper/api/ApiGateway";
import { get_customerPannel_type, get_Id } from "../../../../../helper/UserData/userdate";
import { v4 as uuidv4 } from 'uuid';
import history from "../../../../../service/history";
import styles from './header.module.css';
import Button from "../../../../../design-system/lib/Button";
import { CLOSE, ic_circle_close } from "../../../../../static";
import DropDown from "../../../../../design-system/lib/DropDown";
import Typography from "../../../../../design-system/lib/Typography";
import { DetectOndemandOrPickupDelivery } from "../../../../../helper/worker/DetectOndemandOrReserve copy";
import { getCenterMap } from "../../../../../helper/module";
import Modal from "../../../../../design-system/lib/Modal";
import Input from "../../../../../design-system/lib/Input";
import InputFilter from "../../../../../design-system/lib/InputFilter";
import IconProvider from "../../../../../design-system/lib/IconProvider";

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dialoge:null
    }


}
  NewTravelHandler = () => {
    this.props.dispatch(ChangeNewTravelDialoge());
    this.props.dispatch(ResetNewOrder());
    this.props.dispatch(SetopenAddressBookInNewOrder(false, "", null, ""));

    history.goBack()
    // this.props.dispatch(ResetNewOrder());
    // this.props.dispatch(
    //   ServicesVehicletypeAction(this.props.dispatch, this.props.center)
    // );
  };

  SendOrder = () => {
    if (
      this.props.sendOrder.canSaveOrder &&
      !this.props.sendOrder.loading
    ) {
      DetectOndemandOrReserve(
        {
          pickup: this.props.pickup,
          dropOffs: [this.props.firstDropOff, this.props.otherdropOffs],
        },
        (call) => {
          if (call === "ondeman") {
            Api_Create_Ondemand_Order_AGW(
              this.props.dispatch,
              this.removeEmpty({
                customerId: get_Id(),
                uid: uuidv4(),
                pickup: {
                  address: this.props.pickup.address,
                  coordinates: [
                    this.props.pickup.adresss_loc.lng,
                    this.props.pickup.adresss_loc.lat,
                  ],
                  buildingBlock: this.props.pickup.block,
                  description: this.props.pickup.noteForDriver,
                  floor: this.props.pickup.floor,
                  fullName: this.props.pickup.senderFullName,
                  phone: this.props.pickup.senderPhoneNumber,
                  placeId: "",
                  room: this.props.pickup.unit,
                },
                dropoffs: [
                  {
                    address: this.props.firstDropOff.address,
                    coordinates: [
                      this.props.firstDropOff.adresss_loc.lng,
                      this.props.firstDropOff.adresss_loc.lat,
                    ],
                    buildingBlock: this.props.firstDropOff.block,
                    floor: this.props.firstDropOff.floor,
                    fullName: this.props.firstDropOff.recieverFullName,
                    phone: this.props.firstDropOff.recieverPhoneNumber,
                    placeId: "",
                    room: this.props.firstDropOff.unit,
                    customerDescription: this.props.firstDropOff.noteForDriver,
                  },
                  ...this.props.otherdropOffs.map((drop) => {
                    return {
                      address: drop.address,
                      coordinates: [
                        drop.adresss_loc.lng,
                        drop.adresss_loc.lat,
                      ],
                      buildingBlock: drop.block,
                      floor: drop.floor,
                      fullName: drop.recieverFullName,
                      phone: drop.recieverPhoneNumber,
                      placeId: "",
                      room: drop.unit,
                      customerDescription: drop.noteForDriver,
                    };
                  }),
                ],
                options: [
                  ...this.props.driveroptions.map((op) => {
                    return { id: op.id, dataId: op.item ? op.item.id : "" };
                  }),
                ],
                service: {
                  id: this.props.serviceactiveVehicleType.id,
                  options: [
                    ...this.props.serviceoptions.map((op) => {
                      return { id: op.id, dataId: op.item ? op.item.id : "" };
                    }),
                  ],
                },
                paymentMethod: this.props.paymentMethod,
                paymentSide: this.props.paymentSide,
                vehicletypeId: this.props.auto_activeVehicleType &&
                  this.props.auto_activeVehicleType.id,
              }),
              {
                service: { icon: this.props.serviceactiveVehicleType.icon, title: this.props.serviceactiveVehicleType.title },
                vehicletype: { icon: this.props.auto_activeVehicleType.icon, title: this.props.auto_activeVehicleType.title }
              }
            );
          } else {
            Api_Create_PickupDelivery_Order_AGW(
              this.props.dispatch,
              {
                customerId: get_Id(),
                uid: uuidv4(),
                pickup: {
                  address: this.props.pickup.address,
                  coordinates: [
                    this.props.pickup.adresss_loc.lng,
                    this.props.pickup.adresss_loc.lat,
                  ],
                  buildingBlock: this.props.pickup.block,
                  description: this.props.pickup.noteForDriver,
                  floor: this.props.pickup.floor,
                  fullName: this.props.pickup.senderFullName,
                  phone: this.props.pickup.senderPhoneNumber,
                  placeId: "",
                  room: this.props.pickup.unit,
                  schedulePickupNow: this.props.pickup.scheduleDateAfter || this.props.pickup.scheduleDateBefore ? false : true,
                  scheduleDateAfter: momentJalaali(
                    this.props.pickup.scheduleDateAfter
                  ).isValid()
                    ? momentJalaali(
                      Number(this.props.pickup.scheduleDateAfter + "000")
                    ).unix()
                    : 0,
                  scheduleDateBefore: momentJalaali(
                    this.props.pickup.scheduleDateBefore
                  ).isValid()
                    ? momentJalaali(
                      Number(this.props.pickup.scheduleDateBefore + "000")
                    ).unix()
                    : 0,
                },
                dropoffs: [
                  {
                    address: this.props.firstDropOff.address,
                    coordinates: [
                      this.props.firstDropOff.adresss_loc.lng,
                      this.props.firstDropOff.adresss_loc.lat,
                    ],
                    buildingBlock: this.props.firstDropOff.block,
                    floor: this.props.firstDropOff.floor,
                    fullName: this.props.firstDropOff.recieverFullName,
                    phone: this.props.firstDropOff.recieverPhoneNumber,
                    placeId: "",
                    room: this.props.firstDropOff.unit,
                    customerDescription: this.props.firstDropOff.noteForDriver,
                    scheduleDateAfter: momentJalaali(
                      this.props.firstDropOff.scheduleDateAfter
                    ).isValid()
                      ? momentJalaali(
                        Number(
                          this.props.firstDropOff.scheduleDateAfter + "000"
                        )
                      ).unix()
                      : 0,
                    scheduleDateBefore: momentJalaali(
                      this.props.firstDropOff.scheduleDateBefore
                    ).isValid()
                      ? momentJalaali(
                        Number(
                          this.props.firstDropOff.scheduleDateBefore + "000"
                        )
                      ).unix()
                      : 0,
                  },
                  ...this.props.otherdropOffs.map((drop) => {
                    return {
                      address: drop.address,
                      coordinates: [
                        drop.adresss_loc.lng,
                        drop.adresss_loc.lat,
                      ],
                      buildingBlock: drop.block,
                      floor: drop.floor,
                      fullName: drop.recieverFullName,
                      phone: drop.recieverPhoneNumber,
                      placeId: "",
                      room: drop.unit,
                      customerDescription: drop.noteForDriver,
                      scheduleDateAfter: momentJalaali(
                        drop.scheduleDateAfter
                      ).isValid()
                        ? momentJalaali(
                          Number(drop.scheduleDateAfter + "000")
                        ).unix()
                        : 0,
                      scheduleDateBefore: momentJalaali(
                        drop.scheduleDateBefore
                      ).isValid()
                        ? momentJalaali(
                          Number(drop.scheduleDateBefore + "000")
                        ).unix()
                        : 0,
                    };
                  }),
                ],
                options: [
                  ...this.props.driveroptions.map((op) => {
                    return { id: op.id, dataId: op.item ? op.item.id : "" };
                  }),
                ],
                service: {
                  id: this.props.serviceactiveVehicleType.id,
                  options: [
                    ...this.props.serviceoptions.map((op) => {
                      return { id: op.id, dataId: op.item ? op.item.id : "" };
                    }),
                  ],
                },
                paymentMethod: this.props.paymentMethod,
                paymentSide: this.props.paymentSide,
                vehicletypeId: this.props.auto_activeVehicleType &&
                  this.props.auto_activeVehicleType.id,
              },
              {
                service: { icon: this.props.serviceactiveVehicleType.icon, title: this.props.serviceactiveVehicleType.title },
                vehicletype: { icon: this.props.auto_activeVehicleType.icon, title: this.props.auto_activeVehicleType.title }
              }

            )
          }
        }
      );
      localStorage.removeItem("draft");

    }
  };
  removeEmpty = (obj) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
  }


  ItemsList(data, currentState, setCurrentState) {
    console.log("ItemsList ", data, currentState)
    return (
      <div className={styles.itemsConatiner}>
        {data.map((item) => {
          return <div onClick={() => { setCurrentState(item) }}>
            <img src={item.icon} />
            <Typography weight={currentState == item.id ? 'Body_Middle_Bold' : 'Body_Middle_Regular'} textColor={'TextNormal'} text={item.title} className={styles.dropItem} />

          </div>
        })}
      </div>
    )
  }

  handleServiceService = (service) => {
    this.props.dispatch(Set_Driver_auto_VehicleTypes([]));
    this.props.dispatch(Set_Auto_ActiveVehicleType(null));

    this.props.dispatch(Set_Service_ActiveVehicleType(service));
    this.checkSelectedPaymnetMethod(service)

    let center = getCenterMap(this.props.mapRef, this.props.center);

    Api_GetVehicleTypeByService_AGW(this.props.dispatch, {
      latitude: this.props.mapRef.current ? center.lat : this.props.center.lat,
      longitude: this.props.mapRef.current ? center.lng : this.props.center.lng,
      serviceId: service.id,
    });


    DetectOndemandOrPickupDelivery(
      service.orderTypes,

      (callx) => {
        if (callx !== "ondeman") {
          this.props.dispatch(New_Order_Reset_DropOffs())
        }
      })


  }


  checkSelectedPaymnetMethod(service) {
    if (this.props.paymentSide + this.props.paymentMethod == "SenderCash") {
      if (!service.setting.isCashBySenderActive) {
        this.props.dispatch(
          New_Travel_PaymentMethod({
            paymentSide: "Sender",
            paymentMethod: "Wallet",
          })
        );
      }
    } else if (this.props.paymentSide + this.props.paymentMethod == "ReceiverCash") {
      if (!service.setting.enableReceiverCash) {
        this.props.dispatch(
          New_Travel_PaymentMethod({
            paymentSide: "Sender",
            paymentMethod: "Wallet",
          })
        );
      }
    }

  }


   ListoptionisSaved=(OP)=> {
    let booll = false;
    booll = this.props.serviceoptions.find((item) => {
      return item.id === OP.id;
    });
    if (booll) {
      return true
    } else {
      return false
    }
  }

  ListoptionisSaved=(OP)=> {
    let booll = false;
    booll = this.props.serviceoptions.find((item) => {
      return item.id === OP.id;
    });
    if (booll) {
      return true
    } else {
      return false
    }
  }

 ListoptionText=(option)=> {
    let booll = false;
    booll = this.props.serviceoptions.find((item) => {
      return item.id === option.id;
    });
    if (booll) {
      return `${option.title} | ${booll.item.title}`
    } else {
      return false
    }
  }

  ListoptionisSaved=(OP)=> {
    let booll = false;
    booll = this.props.serviceoptions.find((item) => {
      return item.id === OP.id;
    });
    if (booll) {
      return true
    } else {
      return false
    }
  }

  ListhandleSelectedItem = (OP , item) => {

    if (this.ListoptionisSaved(item)) {
        this.props.dispatch(Remove_Service_Option(item.id))
        this.props.dispatch(Add_Service_Option({
            name: item.name,
            id: item.id,
            item:OP,
            type: "list"
          }))
      } else {
        this.props.dispatch(Add_Service_Option({
            name: item.name,
            id: item.id,
            item:OP,
            type: "list"
          }))
      }


  };



  getInputoptionValue=(option)=> {
    let booll = false;
    booll = this.props.serviceoptions.find((item) => {
      return item.id === option.id;
    });
    if (booll) {
      return booll.inputValue
    } else {
      return null
    }
  }


  inputOptionisSaved=(OP) =>{
    let booll = false;
     booll = this.props.serviceoptions.find((item) => {
      return item.id === OP.id;
    });
    if(booll){
      return true
    }else{
      return false
    }
  }


  inputoptionText=(option)=> {
    let booll = false;
    booll = this.props.serviceoptions.find((item) => {
      return item.id === option.id;
    });
    if (booll) {
      return `${option.title} | ${booll.inputValue}`
    } else {
      return false
    }
  }



  booleanHandleactive = (OP) => {
    if (this.booleanOptionisSaved(OP)) {
      this.props.dispatch(Remove_Service_Option(OP.id))
    } else {
      this.props.dispatch(Add_Service_Option({
        id:OP.id,
        type:"boolean"
      }))
    }

  };


  booleanOptionisSaved=(OP)=> {
    let booll = false;
    booll = this.props.serviceoptions.find((item) => {
      return item.id === OP.id;
    });
    if(booll){
      return true
    }else{
      return false
    }
  }



  InputOptionHandle = (OP, text) => {

    this.inputHandleactive(OP, text)

  }

  inputHandleactive = (OP, text) => {
    // if (inputOptionisSaved(OP) && String(e.target.value).trim().length == 0) {
    //   dispatch(Remove_Service_Option(OP.id))
    // } else {
    //   dispatch(Add_Service_Option({
    //     id:OP.id,
    //     inputValue:OP.inputValue,
    //     type:OP.type,
    //     update:inputOptionisSaved(OP)
    //   }))
    // }
    if (text) {
      this.props.dispatch(Add_Service_Option({
        id:OP.id,
        inputValue: text,
        type:OP.type,
        update:this.inputOptionisSaved(OP)
      }))
    } else {
      this.props.dispatch(Remove_Service_Option(OP.id))
    }
  };



  render() {
    return (
      // <img onClick={this.NewTravelHandler} className="close" src={close} />
      <div className={styles.ServiceNewOrderContainer} ref={this.props.ref}>

        <div className={styles.ServiceNewOrderFirstRow}>
          <Button icon={CLOSE} size={'Medium'} type='Secondary' onClick={this.NewTravelHandler} />
          <DropDown size='Medium' ItemsList={this.ItemsList(this.props.serviceVehicleTypes, this.props.serviceactiveVehicleType?.id, this.handleServiceService)} text={this.props.serviceactiveVehicleType?.title} icon={this.props.serviceactiveVehicleType?.icon} className={styles.service} placement='bottomEnd' data={this.props.serviceVehicleTypes} onClick={this.handleServiceService} checkSelected="full" />
        </div>

        {(this.props.serviceactiveVehicleType && this.props.serviceactiveVehicleType.options?.length > 0) && <div className={styles.ServiceNewOrderSecondRow}>
          {this.props.serviceactiveVehicleType.options.map((item, i) => {
            return item.type === "list" ? (
              <DropDown closeIcon={true} textStyle={styles.DropDownTextStyle} size='Small' style={{ padding: "6px 8px 6px 8px" }} text={this.ListoptionisSaved(item) ? this.ListoptionText(item) : item.title} placement={'bottomStart'} data={item.data} isRequired={this.ListoptionisSaved(item) ? false : item.isRequired} requiredText={this.props.t("Required")} onClick={(JOJO) => this.ListhandleSelectedItem(JOJO, item)} isSelected={this.ListoptionisSaved(item)} clearText={this.props.t("clear")} clearOnClick={() => { this.ListoptionisSaved(item) && this.props.dispatch(Remove_Service_Option(item.id)) }} />
            ) : item.type === "input" ? (

                <InputFilter applyText={this.props.t("Apply")} clearText={this.props.t("Clear")} isRequired={this.inputOptionisSaved(item) ? false : item.isRequired} showIcon={false} showValue={false} type={"text"} states={['Contains']} title={this.inputOptionisSaved(item) ? `${item.title} | ${this.getInputoptionValue(item)}` : item.title} placement='bottomEnd' value={this.getInputoptionValue(item)}
                  onChange={(e) => {
                    this.InputOptionHandle(item, e)
                  }} state={"Contains"} stateOnChange={() => { }} />

                // <Button style={{ maxWidth: "412px" }} isActive={this.inputOptionisSaved(item)} size={'Small'} type='Secondary' text={this.inputOptionisSaved(item) ? this.inputoptionText(item) : item.title} isRequired={this.inputOptionisSaved(item) ? false : item.isRequired} requiredText={this.props.t("Required")} onClick={() => {

                //   if (this.inputOptionisSaved(item)) {
                //     this.setState({
                //       dialoge:{ ...item, inputValue: this.getInputoptionValue(item) }
                //     })
                //   } else {
                //     this.setState({
                //       dialoge:item
                //     })
                //   }
                // }} />

              ) : <Button style={{ maxWidth: "412px" }} size={'Small'} type='Secondary' isRequired={item.isRequired} requiredText={this.props.t("Required")} text={<span style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
                marginInlineStart: '2px',
              }}>
                {this.booleanOptionisSaved(item) && <IconProvider Icon={ic_circle_close} size="14" onClick={(e) => {
                  e.stopPropagation();
                  this.booleanHandleactive(item)
                }} />}
                {item.title}</span>} isActive={this.booleanOptionisSaved(item)} onClick={() => { this.booleanHandleactive(item) }} />
          })}
          {/* <Button size={'Small'} type='Secondary' text='Need Helper' />
                <DropDown size='Small' text='Weight' placement='bottom' data={["10 kg", "20 kg", "30 kg"]} />
                <Button size={'Small'} type='Secondary' text='Width' isRequired requiredText='Required' /> */}

        </div>}

        {this.state.dialoge && <Modal ConfirmationOnClick={this.InputOptionHandle} onclose={()=>{this.setState({dialoge:null})}} size='large'  icon={CLOSE}   title={this.state.dialoge.title}  body={<div> <Input type={this.state.dialoge.inputType} placeHolder={this.state.dialoge.hint} value={this.state.dialoge.inputValue} onChange={(e)=>{

            this.setState({
              dialoge:{
                ...this.state.dialoge,
                inputValue:e.target.value
            }
            })
           }} /> </div>}  ConfirmationText={this.props.t("Savechanges")} footer={<><Button size={'Medium'} style={{marginInlineEnd:"16px"}} type='Secondary'  text={this.props.t("clear")} onClick={()=>{
            this.props.dispatch(Remove_Service_Option(this.state.dialoge.id))
            this.setState({
              dialoge:null
            })
           }} /></>} />}


      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  center: state.Map.center,
  price: state.NewOrder.newOrder.price,
  sendOrder: state.NewOrder.newOrder.sendOrder,
  promoCodeValid: state.NewOrder.newOrder.promotionCode.valid,
  promoCode: state.NewOrder.newOrder.promotionCode.code,
  customer: state.NewOrder.newOrder.customer,
  paymentMethod: state.NewOrder.newOrder.paymentMethod.paymentMethod,
  paymentSide: state.NewOrder.newOrder.paymentMethod.paymentSide,
  serviceactiveVehicleType: state.NewOrder.newOrder.service.activeVehicleType,
  serviceoptions: state.NewOrder.newOrder.service.options,
  auto: state.NewOrder.newOrder.driver.auto,
  driveroptions: state.NewOrder.newOrder.driver.options,
  auto_activeVehicleType:
    state.NewOrder.newOrder.driver.auto_driver.auto_activeVehicleType,

  mapRef: state.Map.mapRef,
  serviceVehicleTypes: state.NewOrder.newOrder.service.serviceVehicleTypes,

  pickup: state.NewOrder.newOrder.pickup,
  pickupadresss_loc: state.NewOrder.newOrder.pickup.adresss_loc,
  firstDropOff: state.NewOrder.newOrder.dropOffs.firstDropOff,
  firstDropOffadresss_loc:
    state.NewOrder.newOrder.dropOffs.firstDropOff.adresss_loc,
  otherdropOffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
