import * as React from 'react'
import styles from './style.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import _, { isEmpty } from "lodash";
import { ReactComponent as LOCATION } from '../../../../../static/IconProvider/location.svg'
import { ReactComponent as ADDRESS_BOOK } from '../../../../../static/IconProvider/address_book.svg'
import { ReactComponent as INFO_CIRCLE } from '../../../../../static/IconProvider/info-circle.svg'
import search_not_found_gray from '../../../../../static/search_not_found_gray.svg'

import { CLOSE_DARK, HISTORY, PICKUP } from '../../../../../static';
import { ChooseoOnMapimg, Set_latlng, Set_Map_Geocoding, Set_Pickup_address, Set_Pickup_Inputs, Set_SearchAutoCompleteLoading, SetopenAddressBookInNewOrder } from '../../../../../Redux/actions/actionNames';
import { getCenterMap, setCenterMap } from '../../../../../helper/module';
import { Api_map_geocoding_reverse_AGW, Api_PlaceIdDetails_AGW, Api_SearchAutoComplete_AGW } from '../../../../../helper/api/ApiGateway';
import SearchList from '../../../../../design-system/lib/SearchList';
import Typography from '../../../../../design-system/lib/Typography';
import IconProvider from '../../../../../design-system/lib/IconProvider';
import { get_customerPannel_type, Get_Permisssion } from '../../../../../helper/UserData/userdate';
import RecentAddressItem from './components/recentAddressItem';
import AddressItem from './components/addressItem';
import Button from '../../../../../design-system/lib/Button';
import PickupInfoNewOrder from './components/info';
import IconProviderSvgModifier from '../../../../../design-system/lib/IconProviderSvgModifier';


function PickupNewOrder({handleCollapse}) {
  const [id, setid] = React.useState("pickup");
  const [sessionToken, setSessionToken] = React.useState(null);

  const dispatch = useDispatch();
   const { t } = useTranslation();
  // const auto = useSelector((state) => state.NewOrder.newOrder.pickup.driver.auto)
  // const customerId = useSelector((state) => state.NewOrder.newOrder.customer.id)
  // const BusinessId = useSelector((state) => state.NewOrder.newOrder.customer.BusinessId)
   const mapRef = useSelector((state) => state.Map.mapRef)
  const centerMap = useSelector((state) => state.Map.center)
   const address = useSelector((state) => state.NewOrder.newOrder.pickup.address)
    const adresss_loc = useSelector((state) => state.NewOrder.newOrder.pickup.adresss_loc)
   const emailg = useSelector((state) => state.NewOrder.newOrder.pickup.email)
  const senderFullName = useSelector((state) => state.NewOrder.newOrder.pickup.senderFullName)
   const senderPhoneNumber = useSelector((state) => state.NewOrder.newOrder.pickup.senderPhoneNumber)
   const activePickupDropOff = useSelector((state) => state.NewOrder.newOrder.activePickupDropOff)
   const suggestListPickUpDropOffLoading = useSelector((state) => state.NewOrder.newOrder.suggestListPickUpDropOffLoading)
   const suggestPickUpDropOffListData = useSelector((state) => state.NewOrder.newOrder.suggestPickUpDropOffListData)
   const pickupDropOffType = useSelector((state) => state.NewOrder.newOrder.map_Data.pickupDropOff.type)


   const activeVehicleType = useSelector((state) => state.NewOrder.newOrder.service.activeVehicleType)
  const openAddressBook = useSelector((state) => state.Addressbook.openAddressBookInNewOrder)
  // const orderTypeActive = useSelector((state) => state.NewOrder.newOrder.orderTypeActive)
//  const recentAddress = useSelector((state) => state.NewOrder.newOrder.customer.recentAddreess)
  const addressBookSource = useSelector((state) => state.Addressbook.openAddressBookInNewOrder
  ? state.Addressbook.sourceOfAddressBookInNewOrder
    ? state.Addressbook.sourceOfAddressBookInNewOrder.type == "pickup"
    : false
  : false)
  //   ? state.Addressbook.sourceOfAddressBookInNewOrder
  //     ? state.Addressbook.sourceOfAddressBookInNewOrder.type == "pickup"
  //     : false
  //   : false)
  //   const auto_activeVehicleType = useSelector((state) => state.NewOrder.newOrder.pickup.driver.auto_driver.auto_activeVehicleType)
  //   const manual_activeVehicleType = useSelector((state) => state.NewOrder.newOrder.pickup.driver.manual_driver.manual_activeVehicleType)
  //   const driveroptions = useSelector((state) => state.NewOrder.newOrder.pickup.driver.options)

  // const delayedQuery = React.useCallback(_.debounce((...pr) => HandleChange(...pr), 500), []);
  // const auto_suggestListData = useSelector((state) => state.NewOrder.newOrder.driver.auto_driver.auto_suggestListData)
  // const pickup_manual_id = useSelector((state) => state.NewOrder.newOrder.pickup.driver.manual_driver.manual_id)


 

  // React.useEffect(() => {
  //   notThrottleHandleChange(String(address), sessionToken)
  // }, [localSearchIsActive])


  // const serachListData = React.useCallback(() => {

  //   if (_.isEmpty(address)) {
  //     if (_.isEmpty(customerId)) {
  //       return null //error customer
  //     } else {
  //       if (_.isEmpty(recentAddress)) {
  //         return null //error recentAddress
  //       } else {
  //         return recentAddress
  //       }
  //     }
  //   } else {
  //     if (_.isEmpty(suggestPickUpDropOffListData)) {
  //       return null //error suggestPickUpDropOffListData
  //     } else {
  //       return suggestPickUpDropOffListData
  //     }
  //   }


  // }, [recentAddress, customerId, address, suggestPickUpDropOffListData]);


  const emailValidate=(email)=> {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

    const handlePickUpInputs = React.useCallback((e, name) => {

      if(!e?.target){
        return
      }

    let center = getCenterMap(mapRef, centerMap);
    if (UserHavePermission()) {
      if (name === "address" || e.target.id === "address") {

        setSessionToken(sessionToken ? sessionToken : uuidv4())
        dispatch(
          Set_Pickup_address({
            adresss_loc: { lng: "", lat: "" },
            placeId: "",
          })
        );
        dispatch(Set_SearchAutoCompleteLoading(true));
        if (e.target.value != "") {
          e.target.value.length > 2 && Api_SearchAutoComplete_AGW(dispatch, {
            query: e.target.value,
            latitude: mapRef.current
              ? center.lat
              : center.lat,
            longitude: mapRef.current
              ? center.lng
              : center.lng,
            sessionToken: sessionToken

          });
        }
      }
      dispatch(
        Set_Pickup_Inputs({
          name: name || e.target.id,
          value: e.target.value,
        })
      );
    }
  },[mapRef, centerMap , sessionToken ,])
 
  const handleList = React.useCallback((item) => {
    if(!item){
      return 
    }

    if (item.coordinates) {
      dispatch(
        Set_Map_Geocoding({
          address: item.secondaryAddress
            ? item.address + " , " + item.secondaryAddress
            : item.address,
        })
      );

      dispatch(
        Set_Pickup_address({
          ...item,
          address: item.secondaryAddress
            ? item.address + " , " + item.secondaryAddress
            : item.address,
          adresss_loc: { lng: item.coordinates[0], lat: item.coordinates[1] },
        })
      );
      mapSetView({
        lng: item.coordinates[0],
        lat: item.coordinates[1],
      });

   
      setSessionToken(null)


    } else {

      setSessionToken(sessionToken ? sessionToken : uuidv4())

      Api_PlaceIdDetails_AGW(
        dispatch,
        { placeId: item.placeId, sessionToken: sessionToken },
        (res) => {
          mapSetView({ lng: res.coordinates[0], lat: res.coordinates[1] });
          dispatch(
            Set_Map_Geocoding({
              address: res.address,
            })
          );

          dispatch(
            Set_Pickup_address({
              ...item,
              address: res.address,
              adresss_loc: { lng: res.coordinates[0], lat: res.coordinates[1] },
            })
          );
          dispatch(
            Set_latlng({
              lng: res.coordinates[0],
              lat: res.coordinates[1],
            })
          );
        }
      );


    }

    if (activePickupDropOff.type != "pickup") {
      handleCollapse({
        type: "pickup",
        id: "pickup",
      });
    }
  },[])
 

  const mapSetView = React.useCallback((e) => {

    try {
      setCenterMap(mapRef, e)
      // mapRef.current.leafletElement.setView(e, 17);
    } catch (error) {
      dispatch(
        Set_latlng({
          lng: e.lng,
          lat: e.lat,
        })
      );
    }
  },[])
 

  const UserHavePermission = React.useCallback(() => {

    if (get_customerPannel_type() == "business") {
      if (Get_Permisssion()?.isDefaultPickupAddressEditable) {
        return true
      } else if (adresss_loc.lat == '') {
        return true
      } else {
        return false
      }


    } else {
      return true
    }

  },[adresss_loc])
 

   const GetSearchListError = React.useCallback(() => {

   
    if ((!suggestListPickUpDropOffLoading &&
      suggestPickUpDropOffListData.length === 0 && !_.isEmpty(address)) || (!_.isEmpty(address) && !suggestListPickUpDropOffLoading && !suggestPickUpDropOffListData)) {
      return {
        errorDescription: t("NoResultfromLocalDatabaseD"),
        errorTitle: t("Noresultfound"),
        errorIcon: search_not_found_gray,
        errorActionTitle:t("Clearfield")
      }
    }

  }, [ address, suggestPickUpDropOffListData, suggestListPickUpDropOffLoading]);


  const GetserachListItem = React.useCallback(() => {

    return AddressItem

  }, [ address, suggestPickUpDropOffListData]);

  const serachListData = React.useCallback(() => {

   
      if (_.isEmpty(suggestPickUpDropOffListData)) {
        return null //error suggestPickUpDropOffListData
      } else {
        return suggestPickUpDropOffListData
      }


  }, [ address, suggestPickUpDropOffListData]);



  const ChooseoOnMap = React.useCallback(() => {

    if (UserHavePermission()) {
      if (
        pickupDropOffType &&
        pickupDropOffType == "Pickup"
      ) {
        dispatch(
          ChooseoOnMapimg({
            showMap: false,
            pickupDropOff: {
              type: null,
              id: null,
            },
          })
        );
      } else {
        let center = getCenterMap(mapRef, centerMap);
        Api_map_geocoding_reverse_AGW(dispatch, {
          latitude: mapRef.current
            ? center.lat
            : center.lat,
          longitude: mapRef.current
            ? center.lng
            : center.lng,
        });

         dispatch(
          ChooseoOnMapimg({
            showMap: true,
            pickupDropOff: {
              type: "Pickup",
              id: null,
            },
          })
        );
      }
    }

}, [ pickupDropOffType]);


const pickupRequiredCheck = React.useCallback(() => {
  if (activeVehicleType) {
    let name = false, email = false, phone = false;

    if (activeVehicleType.setting.isPickupAddressNameRequired) {
      if (senderFullName && String(senderFullName).trim().length > 0) {
        name = true
      } else {
        name = false
      }
    } else {
      name = true
    }

    if (activeVehicleType.setting.isPickupAddressPhoneRequired) {
      if (senderPhoneNumber && String(senderPhoneNumber).trim().length > 0) {
        phone = true
      } else {
        phone = false
      }
    } else {
      phone = true
    }


    if (activeVehicleType.setting.isPickupAddressEmailRequired) {
      if (emailg && String(emailg).length > 0 && emailValidate(String(emailg))) {
        email = true
      } else {
        email = false
      }
    } else {
      email = true
    }



    return (name && phone && email);

  } else {
    return false
  }

}, [activeVehicleType , emailg , senderPhoneNumber , senderFullName]);


const resetPickup = React.useCallback(() => {
  dispatch(
    Set_Pickup_address({
      address: "",
      adresss_loc: { lng: "", lat: "" },
      placeId: "",
    })
  );

}, [ ]);


const openAddressbook = React.useCallback(() => {
  if (UserHavePermission()) {
    if (openAddressBook && addressBookSource) {
      dispatch(SetopenAddressBookInNewOrder(false, "", null, ""));
    } else {
      dispatch(
        SetopenAddressBookInNewOrder(true, "pickup", null, "AddressbookPickup")
      );
    }
  }

}, [ openAddressBook , addressBookSource]);


const handleShowInfo = React.useCallback(() => {

  const isOpen = activePickupDropOff.type === "pickup"
  
  const myEvent = new CustomEvent("closeSliders",{ detail:null});
  document.dispatchEvent(myEvent);


  if(!isOpen){
    handleCollapse({
      type: "pickup",
      id: "pickup",
    });
  }else{
       setTimeout(() => {
   handleCollapse({
    type: null,
    id: ""
   });
 }, 100);
  }

}, [activePickupDropOff]);



  React.useEffect(() => {
    const isOpen = activePickupDropOff.type === "pickup"

    const myEvent = new CustomEvent("closeSliders", { detail: null });
    document.dispatchEvent(myEvent);

    if (adresss_loc.lat != '') {
      if (!isOpen) {
        handleCollapse({
          type: "pickup",
          id: "pickup",
        });
      }
    }
  }, [adresss_loc])


  if (isEmpty(String(adresss_loc.lat))) {
    return <div className={styles.PickupOnlyNewOrderContainer}>

      <div className={styles.PickupOnlyNewOrderContainerFirstRow}>
        <IconProvider Icon={PICKUP} size='16' />
        <Typography text={t("Pickup")} weight='Body_Middle_Bold' />
       {address.length == 0 && <span className={styles.starRequired}>*</span>}
      </div>

      <div className={styles.PickupOnlyNewOrderContainerSecondRow}>
        <SearchList  onChange={(e) => handlePickUpInputs(e, "address")} style={{ zIndex: "2" }} value={address} placeHolder={t("SearchAddress")} isloading={suggestListPickUpDropOffLoading} listItemOnclick={handleList} error={GetSearchListError()} errorDescription={GetSearchListError()?.errorDescription} errorTitle={GetSearchListError()?.errorTitle} errorIcon={GetSearchListError()?.errorIcon} errorActionOnClick={(e) => handlePickUpInputs({target:{value:""}}, "address")} errorActionTitle={GetSearchListError()?.errorActionTitle}
          ListItemChildren={GetserachListItem()} list={serachListData()} inputInnerChildren={null}
        />
        <Button iconComponentChildren={<IconProviderSvgModifier Icon={LOCATION} size='16' />} size='Medium' type='Secondary' style={{ marginInlineEnd: "7px" }} onClick={ChooseoOnMap} isActive={pickupDropOffType == "Pickup"} />
        <Button iconComponentChildren={<IconProviderSvgModifier Icon={ADDRESS_BOOK} size='16' />}  size='Medium' type='Secondary'  onClick={openAddressbook} isActive={addressBookSource} />
      </div>

      {address.length > 0  && <Typography text={t("Please select address from search results")} weight='Body_Small_Regular' style={{
          width: "345px", overflow: 'hidden',
          textOverflow: 'ellipsis', whiteSpace: 'nowrap', flex: "1"  ,marginTop:"-6px"  
        }} textColor='Red' />}
    </div>
  }

  return (
    <>

      <div className={styles.PickupOnlyNewOrderSelectedContainer}>

        <div className={styles.PickupOnlyNewOrderSelectedContainerFrow}>
          <IconProvider Icon={PICKUP} size='16' />
          <Typography text={address} weight='Body_Small_Regular' style={{
            width: "345px", overflow: 'hidden',
            textOverflow: 'ellipsis', whiteSpace: 'nowrap', flex: "1"
          }} />

          <Button  iconComponentChildren={<IconProviderSvgModifier Icon={INFO_CIRCLE} size='16' />}  size='Medium' type='Secondary' badge={( !pickupRequiredCheck() ) && true} onClick={handleShowInfo} isActive={activePickupDropOff.type === "pickup"} />
          <Button size={'Small'} icon={CLOSE_DARK} type='Secondary' style={{ marginInlineStart: "4px", backgroundColor: "transparent", padding: "8px 8px" }} onClick={resetPickup} /> 

        </div>


       {(  !pickupRequiredCheck() ) && <Typography text={t("Complete * Feileds")} weight='Body_Small_Regular' style={{
          width: "345px", overflow: 'hidden',
          textOverflow: 'ellipsis', whiteSpace: 'nowrap', flex: "1"
        }} textColor='Red' />}

      </div> 

      {activePickupDropOff.type === "pickup" && <PickupInfoNewOrder handleShowInfo={handleShowInfo} />}
    </>

  )
}

export default PickupNewOrder