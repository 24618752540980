import React, { useState } from "react";
import close from "../../../../static/ic-close.svg";
import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import { Api_Edit_AcountInformation_AGW } from "../../../../helper/api/ApiGateway";
import { UnSucceessFul_i18 } from "../../../../helper/Notification/sendNotification";
import { OpenCloseSetting } from "../../../../Redux/actions/actionNames";

class App extends React.Component {
  SaveInformation = () => {
    if (this.props.canSaveInformation) {
      if (!this.props.data.email) {
       this.sendOrder()
      }else if(this.props.data.email && this.validateEmail(this.props.data.email)){

        this.sendOrder()
      }else{
        UnSucceessFul_i18(
          "emailInvalid",
          "networkError",
          "close",
          () => {
            this.props.dispatch({
              type: "removeNetworkErrorSnack",
              key: "networkError",
            });
          },
          new Date().getTime() + Math.random()
        );
      }
    }
  };

  sendOrder=()=>{

     Api_Edit_AcountInformation_AGW(
          this.props.dispatch,
          this.props.data,
          (call) => {
            this.props.dispatch(OpenCloseSetting(false))
          }
        );
  }

  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  render() {
    return (
      <div className="SettingFooter">
        <span
         style={{
          backgroundImage: `linear-gradient(to top,${global.config.colors.secondaryColor}, ${global.config.colors.secondaryLightColor})`,
          color: `${global.config.colors.secondaryTextColor}`
      }}
          className={`SettingFooterButton ${
            !this.props.canSaveInformation && "DeactiveSettingFooterButton"
          }`}
          onClick={this.SaveInformation}
        >
          <Trans i18nKey={"Save"}></Trans>
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
