//export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";
export const REGISTERSETKEYVALUE = "REGISTERSETKEYVALUE";
export const LOGININDIVISUALSETKEYVALUE = "LOGININDIVISUALSETKEYVALUE";
export const LOGINBUSINESSSETKEYVALUE = "LOGINBUSINESSSETKEYVALUE";
export const VERIFICATIONCODESETKEYVALUE = "VERIFICATIONCODESETKEYVALUE";
export const set_access_token = "set_access_token";
export const openCloseOrderHistory = "openCloseOrderHistory";
export const openCloseAboutUs = "openCloseAboutUs";
export const openCloseTransactionList = "openCloseTransactionList";
export const openCloseMenu = "openCloseMenu";
export const openCloseSetting = "openCloseSetting";
export const setOrderListData = "setOrderListData";
export const setOrderHistoryListData = "setOrderHistoryListData";
export const setAboutusData = "setAboutusData";
export const setTransactions = "setTransactions";
export const openCloseSupport = "openCloseSupport";
export const setSupportData = "setSupportData";
export const setSelectedSupportData = "setSelectedSupportData";
export const setcansavetravel = "setcansavetravel";
export const OPENCLOSENEWTRAVEL = "OPENCLOSENEWTRAVEL";
export const setpriceneworder = "setpriceneworder";
export const set_service_VehicleTypes = "set_service_VehicleTypes";
export const set_service_activeVehicleType = "set_service_activeVehicleType";
export const set_service_VehicleTypes_RepeatData = "set_service_VehicleTypes_RepeatData";
export const add_service_option = "add_service_option";
export const remove_service_option = "remove_service_option";
export const add_driver_option = "add_driver_option";
export const remove_driver_option = "remove_driver_option";
export const set_manual_activeVehicleType = "set_manual_activeVehicleType";
export const set_pickup_inputs = "set_pickup_inputs";
export const set_pickup = "set_pickup";
export const add_dropoff = "add_dropoff";
export const remove_dropoff = "remove_dropoff";
export const set_activePickupDropOff = "set_activePickupDropOff";
export const set_dropOff_inputs = "set_dropOff_inputs";
export const set_SearchAutoComplete = "set_SearchAutoComplete";
export const set_SearchAutoCompleteLoading = "set_SearchAutoCompleteLoading";
export const newTravelPickupSetReserve = "newTravelPickupSetReserve";
export const newTravelDropOffSetReserve = "newTravelDropOffSetReserve";
export const resetneworder = "resetneworder";
export const NewTravelPaymentMethod = "NewTravelPaymentMethod";
export const set_latlng = "set_latlng";
export const set_REF = "set_REF";
export const set_driver_auto_vehicleTypes = "set_driver_auto_vehicleTypes";
export const set_auto_activeVehicleType = "set_auto_activeVehicleType";
export const set_promocode = "set_promocode";
export const chooseoOnMapimg = "chooseoOnMapimg";
export const set_Map_Geocoding = "set_Map_Geocoding";
export const setCustomerBalance = "setCustomerBalance";
export const addnewordertoList = "addnewordertoList";
export const SETSOCKET = "SETSOCKET";
export const updateStateorderInList = "updateStateorderInList";
export const updateStateViewOrder = "updateStateViewOrder";
export const setWebSettings = "setWebSettings";
export const recoverTimeMachine = "recoverTimeMachine";
export const reOrder = "reOrder";
export const LOADINGORDERINFO = "LOADINGORDERINFO";
export const OPENCLOSEVIEWTRAVEL = "OPENCLOSEVIEWTRAVEL";
export const SETORDERINFO = "SETORDERINFO";
export const SETORDERINFOERROR = "SETORDERINFOERROR";
export const changeViewTravelHistoryMode = "changeViewTravelHistoryMode";
export const resetViewTravel = "resetViewTravel";
export const setHistoryData = "setHistoryData";
export const setViewTravelEstimated = "setViewTravelEstimated";
export const resetNewFav = "resetNewFav";
export const setfeedback = "setfeedback";
export const openclosechatdriver = "OPENCLOSECHATDRIVER";
export const addmessagetochat = "addmessagetochat";
export const DELETETRAVELINLIST = "DELETETRAVELINLIST";
export const SETQUICKESSAGE = "SETQUICKESSAGE";
export const setmessagetochat = "setmessagetochat";
export const setmessageErrorLoading = "setmessageErrorLoading";
export const addnewmessageflagtoorder = "addnewmessageflagtoorder";
export const setSeenMessageInChat = "setSeenMessageInChat";
export const setopenAddressBookInNewOrder = "setopenAddressBookInNewOrder";
export const setsourceOfAddressBookInNewOrder = "setsourceOfAddressBookInNewOrder";
export const openNewAddressBookInNewOrder = "openNewAddressBookInNewOrder";
export const NewAddressBookInNewOrderDetails = "NewAddressBookInNewOrderDetails";
export const OnChangeNewAddressBookInNewOrderDetails = "OnChangeNewAddressBookInNewOrderDetails";
export const openAddAddressBookInMenu = "openAddAddressBookInMenu";
export const openEditAddressBookInMenu = "openEditAddressBookInMenu";

export const openAddressBookInMenu = "openAddressBookInMenu";
export const SetAddressBookListData = "SetAddressBookListData";
export const SetAddressBookSearchRegex = "SetAddressBookSearchRegex";
export const removeAddressBookFromList = "removeAddressBookFromList";
export const removeDefaultAddressBookFromList = "removeDefaultAddressBookFromList";
export const setDefaultAddressBookFromList = "semoveAddressBookFromList";
export const setDetailsEdit = "setDetailsEdit";
export const editAddressbookInList = "editAddressbookInList";
export const resetNewAddressBookInNewOrderDetails = "resetNewAddressBookInNewOrderDetails";
export const setSelectedAB = "setSelectedAB";


export const show_event_TimeLine = "show_event_TimeLine";
export const set_viewOrder_events = "set_viewOrder_events";
export const set_New_Event = "set_New_Event";
export const new_Order_Reset_DropOffs = "new_Order_Reset_DropOffs";


export const setNotesValue = "setNotesValue";

export const setCodAmount = "setCodAmount";
export const setReferenceID = "setReferenceID";


export const openCloseMessageBox = "openCloseMessageBox";
export const setMessageBoxData = "setMessageBoxData";



export const createNewDraft = "createNewDraft";
export const setActiveImport = "setActiveImport";
export const setBulkImportOpen = "setBulkImportOpen";
export const setActiveServiceImport = "setActiveServiceImport";
export const setActiveServiceImportList = "setActiveServiceImportList";
export const create_new_BulkImport_List = "create_new_BulkImport_List";


export const add_Service_Option_bulkImport = "add_Service_Option_bulkImport";
export const remove_Service_Option_bulkImport = "remove_Service_Option_bulkImport";
export const add_VehicleType_Option_bulkImport = "add_VehicleType_Option_bulkImport";
export const remove_VehicleType_Option_bulkImport = "remove_VehicleType_Option_bulkImport";
export const remove_draft_bulkImport = "remove_draft_bulkImport";


export const setBulkImportList = "setBulkImportList";



export const Table_Init = "Table_Init";

export const Table_SelectOrder = "Table_SelectOrder";
export const Table_DeSelectOrder = "Table_DeSelectOrder";

export const Table_ClearSelectedOrders = "Table_ClearSelectedOrders";

export const Table_SelecteMultiOrders = "Table_SelecteMultiOrders";
export const Table_setData = "Table_setData";
export const Table_reset = "Table_reset";

export const Table_setStatus = "Table_setStatus";
export const Table_setSelectedBulkEditStatusSummary = "Table_setSelectedBulkEditStatusSummary";
export const table_setRouteList = "table_setRouteList";



export const TableInputContainFilter_Add = "TableInputContainFilter_Add";
export const TableInputContainFilter_Delete = "TableInputContainFilter_Delete";
export const TableInputContainFilter_Update = "TableInputContainFilter_Update";

export const TableCheckboxFilter_Update = "TableCheckboxFilter_Update";

export const TableRadioButtonFilter_Update = "TableRadioButtonFilter_Update";
export const TableRangeFilter_Update = "TableRangeFilter_Update";
export const Add_ActiveFilter = "Add_ActiveFilter";
export const clearAll_ActiveFilter = "clearAll_ActiveFilter";

export const TableSubRangeFilter_Update = "TableSubRangeFilter_Update";


export const Route_reset = "Route_reset";
export const handleEditRouteFilter_Name = "handleEditRouteFilter_Name";

export const clearAll_Route_ActiveFilter = "clearAll_Route_ActiveFilter";

export const set_Table_ActiveOrderType = "set_Table_ActiveOrderType";

export const set_Order_Info_Api_PD_Update = "set_Order_Info_Api_PD_Update";
export const set_DropOff_fields_Dnd = "set_DropOff_fields_Dnd";
