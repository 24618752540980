import * as React from "react"
const AboutusSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <defs>
      <linearGradient id="aAboutusSvg" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconLightEnd} />
        <stop offset="100%" stopColor={global.config.colors.iconLightStart}  />
      </linearGradient>
      <linearGradient id="bAboutusSvg" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconDarkEnd}  />
        <stop offset="100%" stopColor={global.config.colors.iconDarkStart}  />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="nonzero">
      <path
        fill="url(#aAboutusSvg)"
        d="M20 10c0 5.524-4.477 10-10 10S0 15.524 0 10C0 4.478 4.477 0 10 0s10 4.478 10 10"
      />
      <path
        fill="url(#bAboutusSvg)"
        d="M9.995 12.929c.493 0 .885.393.885.875s-.392.875-.875.875a.874.874 0 1 1-.01-1.75zm0-7.593c.482 0 .875.393.875.875v4.42c0 .482-.393.875-.875.875s-.875-.393-.875-.875V6.21c0-.482.393-.875.875-.875z"
      />
    </g>
  </svg>
)
export default AboutusSvg
