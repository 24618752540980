import React, { useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { get_country } from "./../../../../../helper/UserData/userdate";
import CircularProgress from "@material-ui/core/CircularProgress";
import googlelogo from "./../../../../../static/icon-google.svg";
import facebooklogo from "./../../../../../static/icon-fb.svg";
import applelogo from "./../../../../../static/icon-ic-apple.svg";
// import GoogleLogin from "react-google-login";
import FacebookLogin from 'react-login-facebook'
// import FacebookLogin from "../../../../../innerLibrary/facebookLogin/facebook";
import AppleLogin from "react-apple-login";
import { RegisterSetKeyValue } from "../../../../../Redux/actions/actionNames";
import { connect } from "react-redux";
import { AppleTokenInfo, FacebookTokenInfo, GoogleTokenInfo, RegisterPure } from "../../../../../Redux/actions/actions";
import { AppleLoginCustomerId, AppleLoginredirectURI, RegisterGateWay, FacebookLoginappId, GoogleLogincustomerId, getCountryWithDomain, ShowThirdPartyLogin } from "../../../../../helper/module";
import GoogleLogin from "../../../../../helper/module/GoogleLogin";
import { decodeToken } from "react-jwt";
import loadDynamicScript from "../../../../../helper/module/loadDynamicScript";
// import { GoogleLoginBtn } from "../../googleLoginBtn";
// import ReCAPTCHA from "react-google-recaptcha";
import Continue from "../../Login/components/continue";
// import ReCAPTCHA from "react-google-recaptcha-enterprise";


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Captcha: null,
      PrePhoneInput: 0,
      numberNotRegistered: false,
      loading: false,
      googleClientId : global.config.googleLoginClientId
    };
    this.recaptchaRef = React.createRef();
    this.CaptchaOnChange = this.CaptchaOnChange.bind(this);
  }

  
  CaptchaOnChange(value) {
    this.setState({
      Captcha: value
    })
  }



  ErrresponseGoogle = (res) => {
    console.log("App -> ErrresponseGoogle -> res", res)
  
    
    }

    componentDidMount(){

      if(global.config.grsk){
     this.setState({
        Captcha: null ,
        ts:null,
        googleClientId : global.config.googleLoginClientId 
      },()=>{
          loadDynamicScript(
        `https://www.google.com/recaptcha/enterprise.js?render=${global.config.grsk}`,
        "recaptchaEnterprise",
        async ()  => {
          const ts = Number(String(Number(Date.now())))

          window.grecaptcha.enterprise.ready( () => {
            const ts = Number(String(Number(Date.now())))

            window.grecaptcha.enterprise
              .execute(global.config.grsk, {
                action: `custom_customer_register_${ts}`
              })
              .then( (token)=> {
  
                this.setState({
                  Captcha: token ,
                  ts : ts
                })
              })
              .catch( (error)=> {
                console.log(error);
              });
          });
        }
      );
      })
      }
 
    

    
    }
  
    componentWillReceiveProps(){
      this.setState({
        googleClientId : global.config.googleLoginClientId
      })
    }
  

  responseGoogle = async (res) => {
    console.log(res)

    console.log("responseGoogle ",res)
    try {
      const myDecodedToken =  await decodeToken(res.credential);
      console.log(myDecodedToken)

      this.RegisterSetKeyValue({
        googleIsActive: true,
        facebookIsActive: false,
        appleIsActive: false,
        googleToken: res.credential,
        givenName: myDecodedToken.given_name,
        familyName: myDecodedToken.family_name,
        email: myDecodedToken.email,
        thirdPartyLogin: "googleConnected",
      });
    } catch (error) {
      
    }
    
    // try {
    //   this.props.dispatch(GoogleTokenInfo(this.props.dispatch, res.credential , (x) => {

    //   }))
    // } catch (error) {
      
    // }

  };

  responseFacebook = (res) => {

    if (res.status == "connected" || !res.status) {
      let name = res.name.split(" ");
      this.RegisterSetKeyValue({
        googleIsActive: false,
        facebookIsActive: true,
        appleIsActive: false,
        facebookToken: res.accessToken,
        givenName: name.length > 0 ? name[0] : "",
        familyName: name.length > 1 ? name[1] : "",
        email: res.email,
        thirdPartyLogin: "facebookConnected",
      });
      this.props.dispatch(FacebookTokenInfo(this.props.dispatch, res.accessToken, (x) => {

      }))

    }
  };

  FacebookLoginClick = () => {
    document.getElementsByClassName("kep-login-facebook")[1].click();
  };

  applecallback = (res) => {

    if (!res.error) {
      this.RegisterSetKeyValue({
        googleIsActive: false,
        facebookIsActive: false,
        appleIsActive: true,
        appleToken: res.authorization.id_token,
        givenName: res.user ? res.user.name.firstName : "",
        familyName: res.user ? res.user.name.lastName : "",
        email: res.user ? res.user.email : "",
        thirdPartyLogin: "appleConnected",
      });

      this.props.dispatch(AppleTokenInfo(this.props.dispatch, res.authorization.id_token, (x) => {

      }))
    }
  };

  RegisterSetKeyValue = (value) => {
    this.props.dispatch(RegisterSetKeyValue(value));
  };

  phoneNumberOnChange = (res) => {
    res &&
      this.RegisterSetKeyValue({
        phone: res,
      });


  };

  givenNameOnChange = (event) => {
    this.RegisterSetKeyValue({
      givenName: event.target.value,
    });
  };

  familyNameOnChange = (event) => {
    this.RegisterSetKeyValue({
      familyName: event.target.value,
    });
  };

  emailOnChange = (event) => {
    this.RegisterSetKeyValue({
      email: event.target.value,
    });
  };

  register = () => {
    if (!this.state.loading) {
      RegisterGateWay(this.props.dispatch, {...this.props , ...this.state}, (res) => {
        this.setState({
          ...res,
          Captcha : null ,
          ts : null
        },()=>{
          setTimeout(() => {
            this.componentDidMount()
          }, 400);
        });
      });
    }
  };

  render() {
    let country = get_country();
    let registerBtnActive =
      this.props.givenName.length > 0 &&
      this.props.familyName.length > 0 &&
      this.props.phone.length > 6;
    return (
      <div className="loginpersonContainer">
        {this.props.thirdPartyLogin ? (
          <div className="thirdpartyLoginSuccessContainer">
            <span className="header">
              {" "}
              <Trans i18nKey={this.props.thirdPartyLogin}> </Trans>{" "}
            </span>
            <span>
              {" "}
              <Trans i18nKey={"plsenterphonnumber"}> </Trans>{" "}
            </span>
          </div>
        ) : null}

        <p className="LP_PhoneNumberLabel">
          {" "}
          <Trans i18nKey={"Phonenumber"}> </Trans>{" "}
        </p>
        <div
          className={`PhoneNumberContainer ${
            this.state.numberNotRegistered && "PhoneNumberContainerError"
            }`}
        >
          <PhoneInput
            defaultCountry={global.config.countryCca2}
            placeholder="123456789"
            value={this.props.phone}
            onChange={(PrePhoneInput) => {
              this.phoneNumberOnChange(PrePhoneInput);
            }}
            className="loginpersonPhoneInput"
          />
        </div>
        {this.state.numberNotRegistered && (
          <span className="pnhnr">
            {" "}
            <Trans i18nKey={"phonenumberhasnotregisterd"}> </Trans>{" "}
          </span>
        )}

        <div className="firstnamelastnameContainer">
          <div className="firstnameContainer">
            <p className="LP_PhoneNumberLabel">
              <Trans i18nKey={"Firstname"}> </Trans>
            </p>
            <div className={`PhoneNumberContainer`}>
              <input
                placeholder={this.props.t("firstnnameloginplaceholder")}
                value={this.props.givenName}
                onChange={this.givenNameOnChange}
              />
            </div>
          </div>

          <div className="lastnameContainer">
            <p className="LP_PhoneNumberLabel">
              <Trans i18nKey={"Lastname"}> </Trans>{" "}
            </p>
            <div className={`PhoneNumberContainer`}>
              <input
                placeholder={this.props.t("lastnameloginplaceholder")}
                value={this.props.familyName}
                onChange={this.familyNameOnChange}
              />
            </div>
          </div>
        </div>

        <div className="firstnamelastnameContainer emailcontainer">
          <p className="LP_PhoneNumberLabel">
            <Trans i18nKey={"email"}> </Trans>{" "}
          </p>
          <div className={`PhoneNumberContainer `}>
            <input
              placeholder={this.props.t("emailPlaceholder")}
              className="emailinputregister"
              value={this.props.email}
              onChange={this.emailOnChange}
            />
          </div>
        </div>


        <div style={{float:"left" , marginLeft:"10px",marginTop:"19px"}}>
          {/* <ReCAPTCHA
            sitekey={global.config.grsk}
            onChange={this.CaptchaOnChange}
            ref={this.recaptchaRef}
          /> */}
        </div>



        <Continue
          onClick={
            (registerBtnActive && (global.config.grsk ? this.state.Captcha : true)) && !this.state.loading
              ? () => {
                this.register();
              }
              : null
          }
          className={`${
            this.state.loading
              ? "loginregistercontinueloading"
              :  (!registerBtnActive || !(global.config.grsk ? this.state.Captcha : true))
                ? "deactivebtnregister loginregistercontinue"
                : "loginregistercontinue"
            }`}
        >
          {this.state.loading ? (
            <CircularProgress size="28px" color="white" />
          ) : (
              <Trans i18nKey={"Continue"}> </Trans>
            )}
        </Continue>

        <div className="continuesWay">
        {( this.state.googleClientId && this.state.googleClientId.length > 0 ) &&
          <GoogleLogin onSuccess={(res)=>this.responseGoogle(res)}
                          onFailure={this.ErrresponseGoogle} />}

          {/* {(global.config.thirdPartyLoginSupport && this.state.googleClientId && this.state.googleClientId.length > 0 ) && 
              <GoogleLoginBtn  onSuccess={(res)=>this.responseGoogle(res)}
                          onFailure={this.ErrresponseGoogle}  />
          } */}
       


        {/* {(global.config.thirdPartyLoginSupport && this.state.googleClientId && this.state.googleClientId.length > 0  && this.props.LoginIsActive == 1) && <GoogleLogin
            //pickup   customerId="149556072176-dtvqa0t2ruacr6frraaesg3u5984n82n.apps.googleusercontent.com"
            //onro    customerId="254715138014-f09tqcnr4ks6ovs00i6rkss4bdko2qcd.apps.googleusercontent.com"
            //Deliro   customerId="51088687197-n8okfrg0r9ulb9m4hu8e2tlu4l8sbrt8.apps.googleusercontent.com"
            render={(renderProps) => (
              <div
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                className="continuesWayItem"
              >
                <img src={googlelogo} />
              </div>
            )}
            clientId={this.state.googleClientId}
            buttonText="Login"
            onSuccess={this.responseGoogle}
            onFailure={this.ErrresponseGoogle}
            cookiePolicy={"single_host_origin"}
            autoLoad={false}
          />} */}

          { global.config.facebookLoginClientId &&
            <FacebookLogin
              //OnroIR  id="1194837280918142"
              //Onro  id="666630160807861"
              //Pickup  id="666630160807861"
              appId={global.config.facebookLoginClientId}
              autoLoad={false}
              fields="name,email"
              callback={this.responseFacebook}
              className="FacebookLogin"
              containerStyle={{ opacity: "0", width: "1px", height: "0px" }}
              version="7.0"
              cookie={true}
              xfbml={true}
              render={(renderProps) => (
                <div
                  className="continuesWayItem facebook"
                  onClick={renderProps.onClick}
                >
                  <img src={facebooklogo} />
                </div>
              )}
            />}

          {global.config.appleLoginClientId && this.props.LoginIsActive == 1 ? (
            <AppleLogin
              clientId={global.config.appleLoginClientId}
              redirectURI={window.location.href}
              state="initial"
              responseType="string"
              callback={this.applecallback}
              usePopup={true}
              scope="name email"
              autoLoad={false}
              render={(renderProps) => (
                <div
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  className="continuesWayItem apple"
                >
                  <img src={applelogo} />
                </div>
              )}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  phone: state.LoginRegister.register.phone,
  givenName: state.LoginRegister.register.givenName,
  familyName: state.LoginRegister.register.familyName,
  email: state.LoginRegister.register.email,
  countryCode: state.LoginRegister.register.countryCode,
  googleIsActive: state.LoginRegister.register.googleIsActive,
  facebookIsActive: state.LoginRegister.register.facebookIsActive,
  appleIsActive: state.LoginRegister.register.appleIsActive,
  googleToken: state.LoginRegister.register.googleToken,
  facebookToken: state.LoginRegister.register.facebookToken,
  appleToken: state.LoginRegister.register.appleToken,
  thirdPartyLogin: state.LoginRegister.register.thirdPartyLogin,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
