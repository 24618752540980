import * as React from 'react'
import styles from './style.module.css';
import {isEmpty} from "lodash";
import IconProvider from '../../../../../../../design-system/lib/IconProvider';
import {ReactComponent as  LOCATION } from '../../../../../../../static/IconProvider/location.svg';
import Typography from '../../../../../../../design-system/lib/Typography';
import IconProviderSvgModifier from '../../../../../../../design-system/lib/IconProviderSvgModifier';




function AddressItem({data , onclick}) {

  let stylee ={
    maxWidth: '324px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }
  return (
    <div className={styles.CustomerListItemContainer} onClick={onclick && onclick}>
        <IconProviderSvgModifier Icon={LOCATION} size='16' />

        <div className={styles.namePhoneContainer}>
            <Typography weight='Body_Tiny_Medium' text={data.address}  style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',...stylee
        }}/>
            <Typography weight='Body_Tiny_Regular' text={isEmpty(data.secondaryAddress) ? "----" : data.secondaryAddress} style={stylee} />

        </div>
    </div>
  )
}

export default AddressItem