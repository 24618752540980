import React from "react";
import clsx from "clsx";
import styles from './style.module.css';
import colors from '../colors/colors.module.css';
import { ButtonProps } from "./Button.types";
import IconProvider from "../IconProvider";
import ProgressIndicator from "../ProgressIndicator";


const Button: React.FC<ButtonProps> = ({ text, style, className, ref, isLoading, onClick,iconComponentChildren,
    size, type, badge, disabled, icon, isActive, isRequired , requiredText ,IconComponent}: ButtonProps) => {
    
    return (
        <div onClick={(e)=>{(onClick && !disabled) && onClick(e)}} ref={ref} className={clsx(styles[type], 
        styles[`${type}${size}`], disabled && styles[`${type}disabled`], isLoading && 
        styles[`${type}isLoading`], isActive && styles[`${type}isActive`], isActive && styles[`${type}${size}isActive`], (icon || iconComponentChildren) && styles[`${type}${size}icon`] ,className && className)} 
        style={style ? style : {}} >

            {(icon && !isLoading && typeof icon == "string") && <IconProvider Icon={icon} size="16" />}
            {(IconComponent && !isLoading && typeof IconComponent == "object") && <IconComponent />}
            {iconComponentChildren}

            {(text && !isLoading) && <span> {text} </span>}
            {(badge && typeof badge =="boolean") && <span className={styles.circleBadge} /> }
            {isLoading && <ProgressIndicator theme="On_Light" style={{fontSize:"7px"}} />}
            {(isRequired && !isLoading && !isActive) && <span className={styles.isRequired}>*</span>}

        </div>
    );
};

export default Button;

