import * as actionTypes from "./actionTypes";

export const RegisterSetKeyValue = (state) => ({
  type: actionTypes.REGISTERSETKEYVALUE,
  state,
});

export const LoginIndivsualSetKeyValue = (state) => ({
  type: actionTypes.LOGININDIVISUALSETKEYVALUE,
  state,
});

export const LoginBusinessSetKeyValue = (state) => ({
  type: actionTypes.LOGINBUSINESSSETKEYVALUE,
  state,
});

export const VerificationCodeSetKeyValue = (state) => ({
  type: actionTypes.VERIFICATIONCODESETKEYVALUE,
  state,
});

export const enqueueSnackbar = (notification) => {
  const key = notification.options && notification.options.key;

  return {
    type: actionTypes.ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  };
};

export const closeSnackbar = (key) => ({
  type: actionTypes.CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const removeSnackbar = (key) => ({
  type: actionTypes.REMOVE_SNACKBAR,
  key,
});

export const set_access_token = (state) => ({
  type: actionTypes.set_access_token,
  state,
});

export const openCloseOrderHistory = (state) => ({
  type: actionTypes.openCloseOrderHistory,
  state,
});

export const openCloseAboutUs = (state) => ({
  type: actionTypes.openCloseAboutUs,
  state,
});

export const openCloseTransactionList = (state) => ({
  type: actionTypes.openCloseTransactionList,
  state,
});

export const OpenCloseMenu = (state) => ({
  type: actionTypes.openCloseMenu,
  state,
});

export const OpenCloseSetting = (state) => ({
  type: actionTypes.openCloseSetting,
  state,
});

export const SetOrderListData = (state) => ({
  type: actionTypes.setOrderListData,
  state,
});

export const SetOrderHistoryListData = (state) => ({
  type: actionTypes.setOrderHistoryListData,
  state,
});

export const SetAboutUs = (state) => ({
  type: actionTypes.setAboutusData,
  state,
});

export const SetTransactions = (state) => ({
  type: actionTypes.setTransactions,
  state,
});


export const SetCanSaveTravel = (state) => ({
  type: actionTypes.setcansavetravel,
  state,
});

export const ChangeNewTravelDialoge = (state) => ({
  type: actionTypes.OPENCLOSENEWTRAVEL,
  state,
});

export const SetPriceNewOrder = (state, state2) => ({
  type: actionTypes.setpriceneworder,
  state,
  state2,
});

export const Set_Service_VehicleTypes = (state) => ({
  type: actionTypes.set_service_VehicleTypes,
  state,
});


export const Set_Service_ActiveVehicleType = (state) => ({
  type: actionTypes.set_service_activeVehicleType,
  state,
});


export const SetServiceVehicleTypesRepeatData = (serviceId , options) => ({
  type: actionTypes.set_service_VehicleTypes_RepeatData,
  serviceId , options
});


export const Add_Service_Option = (state) => ({
  type: actionTypes.add_service_option,
  state,
});


export const Remove_Service_Option = (state) => ({
  type: actionTypes.remove_service_option,
  state,
});


export const Add_Driver_Option = (state) => ({
  type: actionTypes.add_driver_option,
  state,
});

export const Remove_Driver_Option = (state) => ({
  type: actionTypes.remove_driver_option,
  state,
});


export const Set_Manual_ActiveVehicleType = (state) => ({
  type: actionTypes.set_manual_activeVehicleType,
  state,
});


export const Set_Pickup_Inputs = (state) => ({
  type: actionTypes.set_pickup_inputs,
  state,
});


export const Set_Pickup_address = (state) => ({
  type: actionTypes.set_pickup,
  state,
});

export const Add_dropoff = (state) => ({
  type: actionTypes.add_dropoff,
  state,
});


export const Remove_dropoff = (state) => ({
  type: actionTypes.remove_dropoff,
  state,
});


export const Set_ActivePickupDropOff = (state) => ({
  type: actionTypes.set_activePickupDropOff,
  state,
});

export const Set_DropOff_Inputs = (state) => ({
  type: actionTypes.set_dropOff_inputs,
  state,
});

export const Set_SearchAutoCompleteLoading = (state) => ({
  type: actionTypes.set_SearchAutoCompleteLoading,
  state,
});

export const Set_SearchAutoComplete = (state) => ({
  type: actionTypes.set_SearchAutoComplete,
  state,
});

export const NewTravelPickupSetReserve = (state) => ({
  type: actionTypes.newTravelPickupSetReserve,
  state,
});

export const NewTravelDropOffSetReserve = (state) => ({
  type: actionTypes.newTravelDropOffSetReserve,
  state,
});


export const ResetNewOrder = (state) => ({
  type: actionTypes.resetneworder,
  state,
});

export const New_Travel_PaymentMethod = (state) => ({
  type: actionTypes.NewTravelPaymentMethod,
  state,
});

export const Set_latlng = (state) => ({
  type: actionTypes.set_latlng,
  state,
});

export const Set_Map_Ref = (state ) => ({
  type: actionTypes.set_REF,
  state
});




export const Set_Driver_auto_VehicleTypes = (state) => ({
  type: actionTypes.set_driver_auto_vehicleTypes,
  state,
});


export const Set_Auto_ActiveVehicleType = (state) => ({
  type: actionTypes.set_auto_activeVehicleType,
  state,
});



export const Set_PromoCode = (state) => ({
  type: actionTypes.set_promocode,
  state,
});


export const ChooseoOnMapimg = (state) => ({
  type: actionTypes.chooseoOnMapimg,
  state,
});


export const Set_Map_Geocoding = (state) => ({
  type: actionTypes.set_Map_Geocoding,
  state,
});
export const Set_Customer_Balance = (state) => ({
  type: actionTypes.setCustomerBalance,
  state,
});




export const AddNewOrderToList = (state) => ({
  type: actionTypes.addnewordertoList,
  state,
});


export const SetSocket = (state) => ({
  type: actionTypes.SETSOCKET,
  state,
});

export const UpdateStateOrderInList = (state) => ({
  type: actionTypes.updateStateorderInList,
  state,
});

export const UpdateStateViewOrder = (state) => ({
  type: actionTypes.updateStateViewOrder,
  state,
});

export const SetWebSettings = (state) => ({
  type: actionTypes.setWebSettings,
  state,
});


export const RecoverTimeMachine = (state) => ({
  type: actionTypes.recoverTimeMachine,
  state,
});


export const ReOrder = (state) => ({
  type: actionTypes.reOrder,
  state,
});



export const ChangeViewTravelDialoge = (state, order, customerId) => ({
  type: actionTypes.OPENCLOSEVIEWTRAVEL,
  state,
  order,
  customerId,
});

export const ResetViewTravel = (state) => ({
  type: actionTypes.resetViewTravel,
  state,
});

export const ResetNewFav = (state) => ({
  type: actionTypes.resetNewFav,
  state,
});

export const LoadingOrderInfo = (state) => ({
  type: actionTypes.LOADINGORDERINFO,
  state,
});

export const SetOrderInfo = (state) => ({
  type: actionTypes.SETORDERINFO,
  state,
});

export const SetOrderInfoError = (state) => ({
  type: actionTypes.SETORDERINFOERROR,
  state,
});
export const Setfeedback = (state) => ({
  type: actionTypes.setfeedback,
  state,
});


export const SetEstimated = (state) => ({
  type: actionTypes.setViewTravelEstimated,
  state,
});

export const OPENCLOSECHATDRIVER = (state) => ({
  type: actionTypes.openclosechatdriver,
  state,
});

export const ADDMESSAGETOCHATS = (state) => ({
  type: actionTypes.addmessagetochat,
  state,
});


export const DeleteTravelInList = (state) => ({
  type: actionTypes.DELETETRAVELINLIST,
  state,
});




export const SETQUICKESSAGE = (state) => ({
  type: actionTypes.SETQUICKESSAGE,
  state,
});


export const SETMESSAGETOCHATS = (state) => ({
  type: actionTypes.setmessagetochat,
  state,
});

export const SETMESSAGEErrorLoading = (state) => ({
  type: actionTypes.setmessageErrorLoading,
  state,
});


export const AddNewMessageFlagToOrder = (state) => ({
  type: actionTypes.addnewmessageflagtoorder,
  state,
});


export const SetSeenMessageInChat = (state) => ({
  type: actionTypes.setSeenMessageInChat,
  state,
});



export const SetopenAddressBookInNewOrder = (state , Stype , id , i18) => ({
  type: actionTypes.setopenAddressBookInNewOrder,
  state, Stype , id, i18
});


export const SetsourceOfAddressBookInNewOrder = (state) => ({
  type: actionTypes.setsourceOfAddressBookInNewOrder,
  state,
});


export const SetopenNewAddressBookInNewOrder = (state ,data) => ({
  type: actionTypes.openNewAddressBookInNewOrder,
  state,data
});


export const SetNewAddressBookInNewOrderDetails = (state ) => ({
  type: actionTypes.NewAddressBookInNewOrderDetails,
  state
});

export const OnChangeNewAddressBookInNewOrderDetails = (state ) => ({
  type: actionTypes.OnChangeNewAddressBookInNewOrderDetails,
  state
});

export const ResetNewAddressBookInNewOrderDetails = (state ) => ({
  type: actionTypes.resetNewAddressBookInNewOrderDetails,
  state
});

export const OnChangeopenAddAddressBookInMenu = (state ) => ({
  type: actionTypes.openAddAddressBookInMenu,
  state
});

export const OnChangeopenEditAddressBookInMenu = (state ) => ({
  type: actionTypes.openEditAddressBookInMenu,
  state
});


export const OnChangeopenAddressBookInMenu = (state ) => ({
  type: actionTypes.openAddressBookInMenu,
  state
});


export const SetAddressBookListData = (state ) => ({
  type: actionTypes.SetAddressBookListData,
  state
});

export const SetSelectedAB = (state ) => ({
  type: actionTypes.setSelectedAB,
  state
});



export const SetAddressBookSearchRegex = (state ) => ({
  type: actionTypes.SetAddressBookSearchRegex,
  state
});




export const RemoveAddressBookFromList = (state ) => ({
  type: actionTypes.removeAddressBookFromList,
  state
});




export const RemoveDefaultAddressBookFromList = (state ) => ({
  type: actionTypes.removeDefaultAddressBookFromList,
  state
});



export const SetDefaultAddressBookFromList = (state ) => ({
  type: actionTypes.setDefaultAddressBookFromList,
  state
});



export const SetDetailsEdit = (state ) => ({
  type: actionTypes.setDetailsEdit,
  state
});



export const EditAddressbookInList = (state ) => ({
  type: actionTypes.editAddressbookInList,
  state
});



export const show_eventTimeLine = (state ) => ({
  type: actionTypes.show_event_TimeLine,
  state
});





export const Set_viewOrder_events = (state ) => ({
  type: actionTypes.set_viewOrder_events,
  state
});



export const New_Order_Reset_DropOffs = (state ) => ({
  type: actionTypes.new_Order_Reset_DropOffs,
  state
});

export const SetCodAmount = (state ) => ({
  type: actionTypes.setCodAmount,
  state 
});
export const SetNotesValue = (state ) => ({
  type: actionTypes.setNotesValue,
  state 
});

export const SetReferenceID = (state ) => ({
  type: actionTypes.setReferenceID,
  state 
});

export const OpenCloseSupport = (state) => ({
  type: actionTypes.openCloseSupport ,
  state,
});

export const SetSupportData = (state) => ({
  type: actionTypes.setSupportData ,
  state,
});
export const SetSelectedSupportData = (state) => ({
  type: actionTypes.setSelectedSupportData ,
  state,
});



export const OpenCloseMessageBox = (state) => ({
  type: actionTypes.openCloseMessageBox ,
  state,
});

export const SetMessageBoxData = (state) => ({
  type: actionTypes.setMessageBoxData ,
  state,
});


export const Create_new_BulkImport_List = (state ) => ({
  type: actionTypes.create_new_BulkImport_List,
  state 
});

export const SetActiveImportDataList = (state ) => ({
  type: actionTypes.setActiveServiceImportList,
  state
});



export const Add_Service_Option_bulkImport = (state , id) => ({
  type: actionTypes.add_Service_Option_bulkImport,
  state,id
});

export const Remove_Service_Option_bulkImport = (state , id) => ({
  type: actionTypes.remove_Service_Option_bulkImport,
  state , id
});

export const Add_VehicleType_Option_bulkImport = (state , id) => ({
  type: actionTypes.add_VehicleType_Option_bulkImport,
  state , id
});

export const Remove_VehicleType_Option_bulkImport = (state , id) => ({
  type: actionTypes.remove_VehicleType_Option_bulkImport,
  state , id
});


export const Remove_draft_bulkImport = (state ) => ({
  type: actionTypes.remove_draft_bulkImport,
  state 
});



export const SetBulkImportList = (state ) => ({
  type: actionTypes.setBulkImportList,
  state 
});




export const CreateNewDraft = (state ) => ({
  type: actionTypes.createNewDraft,
  state
});



export const SetActiveImport = (state ,bType) => ({
  type: actionTypes.setActiveImport,
  state , bType
});



export const SetActiveImportData = (state ,id) => ({
  type: actionTypes.setActiveServiceImport,
  state ,id
});

export const SetBulkImportOpen = (state ) => ({
  type: actionTypes.setBulkImportOpen,
  state
});


export const Table_Init = (state ) => ({
  type: actionTypes.Table_Init ,
  state
});


export const Table_SelectOrder = (state ) => ({
  type: actionTypes.Table_SelectOrder ,
  state
});

export const Table_DeSelectOrder = (state ) => ({
  type: actionTypes.Table_DeSelectOrder ,
  state
});


export const Table_ClearSelectedOrders = ( ) => ({
  type: actionTypes.Table_ClearSelectedOrders 
});


export const Table_SelecteMultiOrders = ( state) => ({
  type: actionTypes.Table_SelecteMultiOrders ,
  state
});

export const Table_setData = ( state) => ({
  type: actionTypes.Table_setData ,
  state
});

export const Table_setStatus = ( state) => ({
  type: actionTypes.Table_setStatus ,
  state
});
export const Table_reset = ( state) => ({
  type: actionTypes.Table_reset ,
  state
});
export const Table_setSelectedBulkEditStatusSummary = ( state) => ({
  type: actionTypes.Table_setSelectedBulkEditStatusSummary ,
  state
});


export const Table_setRouteList = ( state) => ({
  type: actionTypes.table_setRouteList ,
  state
});




export const TableInputContainFilter_Delete = ( state) => ({
  type: actionTypes.TableInputContainFilter_Delete ,
  state
});

export const TableInputContainFilter_Add = ( state) => ({
  type: actionTypes.TableInputContainFilter_Add ,
  state
});

export const TableInputContainFilter_Update = ( state) => ({
  type: actionTypes.TableInputContainFilter_Update ,
  state
});


export const TableCheckboxFilter_Update = ( state) => ({
  type: actionTypes.TableCheckboxFilter_Update ,
  state
});

export const TableRadioButtonFilter_Update = ( state) => ({
  type: actionTypes.TableRadioButtonFilter_Update ,
  state
});
 

export const TableRangeFilter_Update = ( state) => ({
  type: actionTypes.TableRangeFilter_Update ,
  state
});
 
export const TableSubRangeFilter_Update = ( state) => ({
  type: actionTypes.TableSubRangeFilter_Update ,
  state
});
 

export const Add_ActiveFilter = ( state) => ({
  type: actionTypes.Add_ActiveFilter ,
  state
});
export const clearAll_ActiveFilter = ( state) => ({
  type: actionTypes.clearAll_ActiveFilter ,
  state
});
 
export const handleEditRouteFilter_Name = ( state) => ({
  type: actionTypes.handleEditRouteFilter_Name ,
  state
});
 
export const clearAll_Route_ActiveFilter = ( state) => ({
  type: actionTypes.clearAll_Route_ActiveFilter ,
  state
});

export const Set_Table_ActiveOrderType = ( state) => ({
  type: actionTypes.set_Table_ActiveOrderType ,
  state
});

export const Set_Order_Info_Api_PD_Update = ( state) => ({
  type: actionTypes.set_Order_Info_Api_PD_Update ,
  state
});



export const Set_DropOff_fields_Dnd = (state) => ({
  type: actionTypes.set_DropOff_fields_Dnd,
  state,
});