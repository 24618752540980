import React from "react";
import clsx from "clsx";
import styles from './style.module.css';
import { IconProviderProps } from "./IconProvider.types";
// import { ADD_CREDIT_CARD } from "../../static/index.js";


const IconProviderSvgCustomColor: React.FC<IconProviderProps> = ({Icon ,size ,style , onClick}: IconProviderProps) => {

    return <div  className={clsx(styles[`size${size}`], styles.fucking)} style={style && style} onClick={onClick && onClick}>
        <Icon />
    </div>;
};

export default IconProviderSvgCustomColor;

