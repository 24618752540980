import * as React from "react"
const OrderHistorySvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <defs>
      <linearGradient id="a" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconLightEnd} />
        <stop offset="100%" stopColor={global.config.colors.iconLightStart} />
      </linearGradient>
      <linearGradient id="b" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor={global.config.colors.iconDarkEnd} />
        <stop offset="100%" stopColor={global.config.colors.iconDarkStart} />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="nonzero">
      <path
        fill="url(#a)"
        d="M20 10c0 5.524-4.477 10-10 10S0 15.524 0 10C0 4.478 4.477 0 10 0s10 4.478 10 10"
      />
      <path
        fill="url(#b)"
        d="M13.574 13.815a.756.756 0 0 1-.384-.105l-3.926-2.343a.752.752 0 0 1-.365-.645V5.676a.75.75 0 1 1 1.5 0v4.62l3.56 2.123a.753.753 0 0 1-.385 1.396"
      />
    </g>
  </svg>
)
export default OrderHistorySvg
