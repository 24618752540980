import * as React from 'react'
import { useState, useRef } from 'react'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { SucceessFul_i18 } from '../../../../../../helper/Notification/sendNotification'
import Button from '../../../../../../design-system/lib/Button'


const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      padding :"20px 4px",
      height:"39px",
      backgroundColor:"white",
      iconColor: '#8898AA',
      color: 'black',
      fontWeight: 300,
      fontSize: '16px',
      '::placeholder': {
        color: '#a9b7c4'
      }
    },
    invalid: {
      // iconColor: '#e85746',
      color: '#e85746'
    }
  },
  classes: {
    focus: 'is-focused',
    empty: 'is-empty'
  }
}


const EditCreditCardDetails = ({ onToogleVisibility, isStripeFormVisible }) => (
  <a className="editCardButton" onClick={onToogleVisibility}>
    <i className={`fa fa-${isStripeFormVisible ? 'chevron-left' : 'pencil'}`}></i>
    <span>{isStripeFormVisible ? 'cancel' : 'Edit Credit Card Details'}</span>
  </a>
)

const CreditCardErrors = (props) => (
  <div className="cardErrors" role="alert" style={{color:"#fa755a" , fontSize:"16px" , marginTop:'4px', }}>
    {props.children}
  </div>
)




function App({ setupIntentSecret, successUrl, isCreditCardStored , clientSecret , onClose }) {
  const formRef = useRef(null)
  const [cardErrorMessage, setCardErrorMessage] = useState(null)
  const [isStripeFormVisible, setIsStripeFormVisible] = useState(!isCreditCardStored)
  const [stripePaymentMethodId, setStripePaymentMethodId] = useState('')
  const [formComplete, setFormComplete] = useState(false)
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch()
  const [loading, setLoading] = React.useState(false);

  const stripe = useStripe()
  const elements = useElements()

  const toogleVisibility = () => setIsStripeFormVisible(!isStripeFormVisible)

  const handleSubmit = async event => {
    setLoading(true)

    event.preventDefault()
    setFormComplete(false)

    if (!stripe || !elements) {
      return
    }

    const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement)
      }
    })

    if (setupIntent && setupIntent.status === 'succeeded') {
      setLoading(false)

      // setStripePaymentMethodId(setupIntent.payment_method)
      // formRef.current.submit()
      SucceessFul_i18(
        "Addedsuccessfully",
        "networkError",
        "Close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
      onClose()
    } else {
      setLoading(false)
      setCardErrorMessage(error.message)
    }
  }

  const validateCardElement = (event) => {
    setFormComplete(event.complete)
    setCardErrorMessage(event.error ? event.error.message : null)
  }


  return (
    <React.Fragment>
      <div style={{ width: '100%', height: 'auto', margin: '0', backgroundColor:"rgba(244, 244, 247, 1)" , float: "left" }} id="dkdkqkdqiuwh">

   
          <fieldset className={`feohebxte ${cardErrorMessage && 'feohebxteError'}`}>
            <CardElement
              options={CARD_OPTIONS}
              className="col-md-12"
              onChange={validateCardElement}
              onReady={()=>{console.log("CardElement onReady")}}
            />
          </fieldset>
          {cardErrorMessage && <CreditCardErrors sty>{cardErrorMessage}</CreditCardErrors>}

      </div>

      <div className="footer" style={{backgroundColor:"white" ,width :"100%" ,borderRadius :" 0px 8px 8px 0px"}}>
       
       <Button style={{float:"inline-end" , marginTop:"22px"}} size='Medium' disabled={!formComplete} text={t("Add")} type='Primary' onClick={ handleSubmit} />
       
        {/* <sendOrder
          className={`creditcardaddBtn ${ && "grey"}`}
         onClick={ formComplete && handleSubmit}
        >
            {loading ?  <div class="snippet" data-title=".dot-pulse">
          <div class="stage">
            <div class="dot-pulse"></div>
          </div>
        </div> : <Trans i18nKey={"Add"}> </Trans>}
        </sendOrder> */}
      </div>


    </React.Fragment>
  )
}

export default App